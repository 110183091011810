import hasDom from 'has-dom';

const offsetCalc = (pixels, element) => {
    if (hasDom()) {
        const sticky = document.getElementById(element);
        return (sticky ? -(sticky.scrollHeight + pixels) : -(pixels));
    }
    return 0;
};

export default offsetCalc;
