import { fetchJSON } from '@bonnet/next/fetch';

export default (query = {}) => fetchJSON('/cars-for-sale/quote', {
    query,
    circuitBreaker: {
        id: 'quote',
        timeout: 5000,
        resetTimeout: 30000,
        fallback: {
            results: false,
        },
    },
});
