import React, { memo } from 'react';

import PropTypes from 'prop-types';

import { Button, Glyphicon, PositionedOverlay } from 'reaxl';

function MediaWithExpander({
    media,
    onExpand = () => {},
}) {
    return media && (
        <PositionedOverlay className="text-center">
            <PositionedOverlay.Base>
                {media}
            </PositionedOverlay.Base>
            <PositionedOverlay.Anchor
                position="topRight"
                offsetTop={0}
                offsetBottom={0}
            >
                <Button
                    bsStyle="reverse"
                    bsSize="small"
                    className="btn-expand"
                    onClick={onExpand}
                    aria-label="View full screen"
                >
                    <Glyphicon glyph="expand" />
                </Button>
            </PositionedOverlay.Anchor>
        </PositionedOverlay>
    );
}

MediaWithExpander.propTypes = {
    /**
     * Spin, Image or Video to add expander
     */
    media: PropTypes.node,
    /**
     * The callback function to fire when the expander is clicked
     */
    onExpand: PropTypes.func,
};

export default memo(MediaWithExpander);
