import { brands } from 'reaxl-brand';

import { fetchVehicleDetailsPage } from '@/fetchers';

export default function addVehicleDetailsPageData() {
    return async (ctx) => {
        const { req, query = {} } = ctx;

        const headers = req ? req.headers : {};

        // TODO BONNET This should only be on KBB and Client side.
        if (ctx.data.brand === brands.KBB_BRAND) {
            headers['X-fwd-svc'] = 'atc';
        }

        // generate a fetch options object
        const options = {
            headers,
            query,
        };

        const [, { channel, ignorePriority, vhrProviders }] = ctx.useFeature('brand');
        const [isEcommLinkEnabled] = ctx.useFeature('ecomm_link');
        options.query = {
            ...options.query,
            ignoreEcommLink: !isEcommLinkEnabled,
            ...(!!ignorePriority && { ignorePriority }),
            // TODO: Remove the monolith vhrProviders param after 100% LSC migration
            ...(vhrProviders && { vhrProviders, vhrProvider: vhrProviders }),
        };

        if (channel) {
            options.query.channel = channel;
        }

        //  BONNET TODO: should we pass all or part of headers?
        const serverData = await fetchVehicleDetailsPage(options);
        const compositeFetchData = await ctx.fetchCompositeJSON.fetchComposite();

        Object.assign(ctx.data, serverData, compositeFetchData);
    };
}
