import { fetchJSON } from '@bonnet/next/fetch';

export default ({ zip }) => fetchJSON(`/marketplace/api/financing/v1/eligibility/zipCode/${zip}`, {
    circuitBreaker: {
        id: 'psxPrequalEligibilityCheck',
        timeout: 8000,
        resetTimeout: 30000,
        fallback: {
            userLocationIsFinanceEligible: false,
        },
    },
    method: 'POST',
}).catch((error) => {
    // eslint-disable-next-line no-console
    console.log('PSX Pre-qual Eligibilty Check API call failed', error);
});
