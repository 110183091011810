import { useSelector } from 'react-redux';

import { useBrand } from 'reaxl-brand';
import { VehicleDetails as VehicleDetailsSchema } from 'reaxl-schema';

import fordSeoUrl from '@/config/seo-config-url/branded/ford';

import { userDuck } from '@/ducks';

import { vdpResultsDuck } from '@/ducks/vdp';

export default function FordVehicleDetailsSchema() {
    const { isBrand, brands } = useBrand();

    const listing = useSelector(vdpResultsDuck.selectors.getVdpActiveInventory);
    const userLocation = useSelector(userDuck.selectors.getCityState);

    return isBrand(brands.FORD_BRAND) && (
        <VehicleDetailsSchema
            inventory={listing}
            searchLocation={userLocation}
            baseUrl={fordSeoUrl.prod.baseUrl}
        />
    );

}
