import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import { Button, Glyphicon, IconButton } from 'reaxl';

import { vdpActiveInteractionDuck } from '@/ducks/vdp';

import useMediaGalleryAnalytics from './useMediaGalleryAnalytics';

const TOUCHING_DRAGGING_THRESHOLD = 50;

const keyCode = {
    TAB: 9,
    ESC: 27,
    RIGHT_ARROW: 39,
    LEFT_ARROW: 37,
};

const getTouchPosition = (e) => ({
    x: e.touches[0].clientX,
    y: e.touches[0].clientY,
});

function FullscreenViewer({
    isMobile,
    headerCtaContent,
    closeFullScreen,
    fullscreenDataSet,
    defaultIndex,
    onStateUpdateCallback,
    isPsxVdp,
}) {
    const sendAnalyticsEvent = useMediaGalleryAnalytics();
    const isConfirmModalShowing = useSelector(vdpActiveInteractionDuck.selectors.getShowEmailModal);
    const [currentIndex, setCurrentIndex] = useState(defaultIndex);
    const touchTracking = useRef({});
    const fullscreenContainer = useRef(null);

    const getPreviousItem = useCallback(() => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    }, [currentIndex]);

    const getNextItem = useCallback(() => {
        if (currentIndex < fullscreenDataSet.length - 1) {
            setCurrentIndex(currentIndex + 1);
        }
    }, [currentIndex, fullscreenDataSet.length]);

    useEffect(() => {
        onStateUpdateCallback(currentIndex);
    }, [currentIndex, onStateUpdateCallback]);

    useEffect(() => {
        // only enable focus trap if the confirmation availability modal is not open
        if (isConfirmModalShowing) {
            return () => {};
        }
        const handleKeyDown = (e) => {
            if (e.keyCode === keyCode.TAB) {
                const fullscreenViewer = fullscreenContainer.current;
                const focusableItems = Array.from(fullscreenViewer.querySelectorAll('[data-cmp="fullscreen-action-bar"] button, .control-button-container button:enabled'));
                const currentViewFocusableItems = Array.from(fullscreenViewer.querySelector('.image-carousel').children[currentIndex].querySelectorAll('button, [tabindex]'));
                focusableItems.push(...currentViewFocusableItems);
                const trapped = focusableItems.includes(document.activeElement);
                const lastItem = focusableItems[focusableItems.length - 1];
                const firstItem = focusableItems[0];
                const currentTabIndex = focusableItems.indexOf(document.activeElement);
                if (!currentViewFocusableItems.includes(document.activeElement)) {
                    if (e.shiftKey) {
                        if (document.activeElement === firstItem || !trapped) {
                            lastItem.focus();
                        } else {
                            focusableItems[currentTabIndex - 1].focus();
                        }
                    } else if (!e.shiftKey) {
                        if (document.activeElement === lastItem || !trapped) {
                            firstItem.focus();
                        } else {
                            focusableItems[currentTabIndex + 1].focus();
                        }
                    }
                    e.preventDefault();
                }
            } else if ([keyCode.ESC, keyCode.LEFT_ARROW, keyCode.RIGHT_ARROW].includes(e.keyCode)) {
                ({
                    [keyCode.ESC]: () => closeFullScreen(),
                    [keyCode.LEFT_ARROW]: () => getPreviousItem(),
                    [keyCode.RIGHT_ARROW]: () => getNextItem(),
                }[e.keyCode])();
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [closeFullScreen, getPreviousItem, getNextItem, currentIndex, isConfirmModalShowing]);

    const handleTouchStart = (e) => {
        const { x, y } = getTouchPosition(e);
        touchTracking.current = {
            startX: x,
            startY: y,
            endX: x,
            endY: y,
        };
    };

    const handleTouchEnd = (e) => {
        const { startX, endX } = touchTracking.current;
        if (Math.abs(startX - endX) > TOUCHING_DRAGGING_THRESHOLD) {
            if (startX > endX) {
                getNextItem();
            } else {
                getPreviousItem();
            }
        }
    };

    const handleTouchMove = (e) => {
        const { x, y } = getTouchPosition(e);
        touchTracking.current = {
            ...touchTracking.current,
            endX: x,
            endY: y,
        };
    };

    const handleBackButtonClick = useCallback(() => {
        closeFullScreen();
        if (!isPsxVdp) {
            sendAnalyticsEvent('click', 'viewAllMediaClick');
        }
    }, [closeFullScreen, isPsxVdp, sendAnalyticsEvent]);

    return (
        <div
            data-cmp="fullscreenViewer"
            ref={fullscreenContainer}
            className="inverted"
        >
            <div
                className="action-bar display-flex padding-2 w-100"
                data-cmp="fullscreen-action-bar"
            >
                <IconButton
                    label="Back"
                    glyph="chevron-left"
                    monochrome
                    hideLabel={isMobile}
                    onClick={handleBackButtonClick}
                />
                {headerCtaContent}
            </div>
            <div
                className="image-carousel"
                style={{
                    left: `-${currentIndex * 100}vw`,
                }}
            >
                {fullscreenDataSet.map(({ element }, i) => (
                    <div
                        key={`fullscreen-item-${i}`}
                        className="carousel-image-wrapper"
                        onTouchStart={handleTouchStart}
                        onTouchEnd={handleTouchEnd}
                        onTouchMove={handleTouchMove}
                    >
                        {element}
                    </div>
                ))}
            </div>
            {!isMobile && (
                <div
                    className="control-button-container"
                >
                    <Button
                        aria-label="Previous Image"
                        bsStyle="link"
                        className="text-size-300"
                        disabled={currentIndex < 1}
                        onClick={getPreviousItem}
                    >
                        <Glyphicon
                            glyph="chevron-left"
                        />
                    </Button>
                    <Button
                        aria-label="Next Image"
                        bsStyle="link"
                        className="text-size-300"
                        disabled={currentIndex > fullscreenDataSet.length - 2}
                        onClick={getNextItem}
                    >
                        <Glyphicon
                            glyph="chevron-right"
                        />
                    </Button>
                </div>
            )}
            <div
                className="carousel-indicator w-100 padding-bottom-5 text-center text-bold"
            >
                {currentIndex + 1}
                {' '}
                of
                {' '}
                {fullscreenDataSet.length}
            </div>
        </div>
    );
}

FullscreenViewer.propTypes = {
    isMobile: PropTypes.bool,
    headerCtaContent: PropTypes.element,
    show: PropTypes.bool,
    closeFullScreen: PropTypes.func,
    fullscreenDataSet: PropTypes.array,
    defaultIndex: PropTypes.number,
    onStateUpdateCallback: PropTypes.func,
    isPsxVdp: PropTypes.bool,
};

FullscreenViewer.defaultProps = {
    isMobile: false,
    headerCtaContent: null,
    show: false,
    closeFullScreen: () => { },
    fullscreenDataSet: [],
    defaultIndex: 0,
    onStateUpdateCallback: () => { },
    isPsxVdp: false,
};

export default FullscreenViewer;
