import React, { useEffect } from 'react';

import { batch, useDispatch, useSelector } from 'react-redux';

import dynamic from 'next/dynamic';

import qs from 'querystring';

import clsx from 'clsx';
import _uniqBy from 'lodash/uniqBy';

import { fetchJSON } from '@bonnet/next/fetch';

import { translateKeys } from '@atc/bonnet-parameters';

import {
    ColoredBackground,
} from 'reaxl';
import { useBrand } from 'reaxl-brand';

import { isVDPExperienceUTM } from '@/analytics/analyticsUtils';

import { inventoryDuck, ownersDuck, queryDuck, userPreferencesDuck } from '@/ducks';

import { srpResultsDuck, srpSpotlightDuck, srpSupplementalDuck } from '@/ducks/srp';
import { vdpResultsDuck } from '@/ducks/vdp';

import alphaModule from '@/modules/AlphaModule';

import LazyComponent from '@/components/LazyComponent';
import BackToResultsLink from '@/components/vehicledetails/BackToResultsLink';
import VdpPaginationButtons from '@/components/vehicledetails/VdpPaginationButtons';

const CompareListingsDrawer = dynamic(() => import(
    /* webpackChunkName: "CompareListingsDrawer" */
    '@/components/CompareListingsDrawer'
), { ssr: false });

// wrapStyle support super small device, so that the user has to side scroll
const wrapStyle = {
    overflowX: 'auto',
};

export function VdpPaginationContainer() {

    const dispatch = useDispatch();
    const { brand, brands, isBrand } = useBrand();

    const listing = useSelector(vdpResultsDuck.selectors.getVdpActiveInventory);
    const compareToggle = useSelector(userPreferencesDuck.selectors.getCompareToggle);
    const query = useSelector(queryDuck.selectors.getDuckState);
    const resultCount = useSelector(srpResultsDuck.selectors.getResultsCount);

    const utmApplyVDPExperience = isVDPExperienceUTM(query);

    const inventoryListings = useSelector(srpResultsDuck.selectors.getActiveInventory);
    const { listings: alphaListingIds } = useSelector(alphaModule.duck.selectors.getDuckState);
    const spotlightListingIds = useSelector(srpSpotlightDuck.selectors.getActiveResults);
    const supplementalListingIds = useSelector(srpSupplementalDuck.selectors.getActiveResults);

    let alphaListing;
    if (alphaListingIds && alphaListingIds.length > 0) {
        alphaListing = { listingId: alphaListingIds[0], clickType: 'alpha' };
    }
    const paginationListingIds = [
        alphaListing,
        ...spotlightListingIds.map((id) => ({ listingId: id, clickType: 'spotlight' })),
        ...inventoryListings.map(({ id }) => ({ listingId: id, clickType: 'listing' })),
        ...supplementalListingIds.map((id) => ({ listingId: id, clickType: 'supplemental' })),
    ].filter(Boolean);

    const listingIds = _uniqBy(paginationListingIds, 'listingId');

    // NOTE: If in paid landing VDP experience and no search results, then fetch search results
    useEffect(() => {
        const fetchSearchResultsListings = async () => {

            if (utmApplyVDPExperience) {
                const { makeCode, modelCode, year } = listing;
                const { numRecords, searchRadius, sortBy } = query;

                let searchQuery = {
                    makeCodeList: makeCode,
                    modelCodeList: modelCode,
                    startYear: year - 2, // Get two years older than current listing
                    endYear: year + 2, // Two years newer than current listing
                    sortBy: sortBy || 'relevance',
                    numRecords: numRecords || 25,
                    searchRadius: searchRadius || 25,
                };

                const listingsEndpoint = '/rest/lsc/listing';
                searchQuery = translateKeys(searchQuery, { target: 'lsc' });
                const { listings = [], owners = [] } = await fetchJSON(`${listingsEndpoint}?${qs.stringify(searchQuery)}`);

                if (listings?.length) {

                    // NOTE: React v18 will batch state updates automatically, then batch can be removed here
                    batch(() => {
                        dispatch(srpResultsDuck.creators.setActiveResults(listings.map((item) => item.id)));
                        dispatch(srpResultsDuck.creators.setCount(listings.length));
                        dispatch(inventoryDuck.creators.addInventory(listings));
                        dispatch(ownersDuck.creators.addOwners(owners));
                    });

                }
            }
        };

        if (!listingIds?.length) {
            // Do the fetch
            fetchSearchResultsListings();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <ColoredBackground
                className="container-fluid"
                style={wrapStyle}
            >
                <div
                    className={clsx('container', {
                        'margin-top-4': isBrand(brands.KBB_BRAND),
                    })}
                >
                    <div
                        data-cmp="backToResultsCTA"
                        className="align-items-center display-flex justify-content-between"
                    >
                        <BackToResultsLink />
                        {query?.clickType !== 'similar' && resultCount > 0 && listingIds && listingIds.length > 0 && (
                            <VdpPaginationButtons
                                channel={brand}
                                listing={listing}
                                listingIds={listingIds || []}
                                query={query}
                                isVDPExperienceUTM={utmApplyVDPExperience}
                            />
                        )}
                    </div>
                </div>
            </ColoredBackground>
            {compareToggle && (
                <LazyComponent>
                    <CompareListingsDrawer
                        isCollapsible
                        parent="vdp-paginate"
                    />
                </LazyComponent>
            )}
        </>
    );
}

export default VdpPaginationContainer;
