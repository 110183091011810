import React, { useCallback } from 'react';

import PropTypes from 'prop-types';

import { Button } from 'reaxl';

import offsetCalc from '@/utilities/offSetScrollCalculation';
import scrollToElement from '@/utilities/scrollToElement';

/**
 *  Jump Link to Native Deal Calculator Section
 *
 */
function CalculatorJumpLink({
    className,
    bsSize,
    bsStyle = 'primary',
    label = 'Calculate Payment',
    onClick = () => {},
}) {
    const handleClick = useCallback((event) => {
        const offset = offsetCalc(25, 'sticky-header-vdp');
        scrollToElement('nativeDealContainer', offset);
        onClick(event);
    }, [onClick]);

    return (
        <Button
            bsStyle={bsStyle}
            bsSize={bsSize}
            className={className}
            onClick={(event) => handleClick(event)}
        >
            {label}
        </Button>
    );
}

CalculatorJumpLink.propTypes = {
    /**
     * Button className
     */
    className: PropTypes.string,
    /**
     * Button size
     */
    bsSize: PropTypes.oneOf(['large', 'small', 'xsmall']),
    /**
     * Button style
     */
    bsStyle: PropTypes.oneOf(['primary', 'secondary']),
    /**
     * Button label
     */
    label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    /**
     * Callback function fires when the component is clicked
     */
    onClick: PropTypes.func,
};

export default CalculatorJumpLink;
