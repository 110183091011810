import { memo } from 'react';

import { useSelector } from 'react-redux';

import { usePageEvent } from '@bonnet/next/use-page-event';

import { getConsumerMetadata, sendPage } from 'reaxl-analytics';

import { authDuck } from '@/ducks';

import PersonalizationEngineModule from '@/modules/PersonalizationEngineModule';

function VdpPageEventsContainer() {
    // on mount declare a new page
    const { onPageEnter } = usePageEvent('vdpPage', { priority: 1 });

    const peData = useSelector(PersonalizationEngineModule.duck.selectors.selectPersonalization);
    const pePreferences = useSelector(PersonalizationEngineModule.duck.selectors.selectPreferences);
    const isLoggedIn = useSelector(authDuck.selectors.isLoggedIn);

    onPageEnter(async () => {
        try {
            let consumerMetadata;
            if (isLoggedIn) {
                consumerMetadata = await getConsumerMetadata();
            }
            sendPage({
                data: {
                    peData,
                    pePreferences,
                    consumerMetadata,
                },
                isInitialLoad: true,
            });
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Failed to send Page Event', error);
        }
    });

    return null;
}

export default memo(VdpPageEventsContainer);
