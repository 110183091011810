import React, { memo } from 'react';

import PropTypes from 'prop-types';

import { KBBInlineRatings } from 'reaxl-ratings-reviews';

import offsetCalc from '@/utilities/offSetScrollCalculation';
import scrollToElement from '@/utilities/scrollToElement';

const scrollToRatingReviewSection = () => {
    const offset = offsetCalc(20, 'sticky-header-vdp');
    scrollToElement('rating-reviews-section', offset);
};

function KBBConsumerRatings({
    kbbConsumerRatings = 0,
    kbbConsumerReviewCount = 0,
    ...rest
}) {
    return !!kbbConsumerRatings
    && (
        <KBBInlineRatings
            label="Consumer Vehicle"
            rating={kbbConsumerRatings}
            count={kbbConsumerReviewCount}
            handleCountClick={scrollToRatingReviewSection}
            {...rest}
        />
    );
}

KBBConsumerRatings.propTypes = {
    /*
    *   kbbConsumerRatings value
    */
    kbbConsumerRatings: PropTypes.number,
    /*
    *   kbbTotalConsumerReviews value
    */
    kbbTotalConsumerReviews: PropTypes.number,
};

export default memo(KBBConsumerRatings);
