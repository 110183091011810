import React, { memo } from 'react';

import { connect, useSelector } from 'react-redux';

import { mapDispatchToActionProp } from '@atc/modular-redux';

import { ConnectedEmailOwner } from 'reaxl-email';

import {
    authDuck,
    currentPageNameDuck,
    interestRateDuck,
    paymentsDuck,
    preorderDuck,
} from '@/ducks';

import { vdpActiveEmailListingDuck, vdpActiveInteractionDuck, vdpResultsDuck } from '@/ducks/vdp';

import useLeadAdditionalInventory from '@/hooks/useLeadAdditionalInventory';

function VehicleDetailsEmailModalContainer({
    actions: {
        setActiveEmailListingResults,
        setActiveInteraction,
        updateMyWalletInteractions = () => { },
    },
    hideEmailForm,
    preorderListing,
    listingId,
    ownerId,
    userDuck,
    incentivesProps,
    ndsOfferBreakdownProps,
    nextStepsProps,
    ...rest
}) {
    const isLoggedIn = useSelector(authDuck.selectors.isLoggedIn);
    const showEmailModal = useSelector(vdpActiveInteractionDuck.selectors.getShowEmailModal);
    const showEditEmailModal = useSelector(vdpActiveInteractionDuck.selectors.getShowEditEmailModal);
    const emailCTAContext = useSelector(vdpActiveInteractionDuck.selectors.getEmailCTAContext);
    const useFallbackLead = useSelector((state) => paymentsDuck.selectors.getMyWalletInteraction(state, 'useFallbackLead'));
    const showEmailFormModal = !hideEmailForm && (useFallbackLead || showEmailModal);
    const getAdditionalInventory = useLeadAdditionalInventory(true, ownerId);

    const handleOnHide = () => {
        setActiveEmailListingResults([listingId]);
        setActiveInteraction({
            emailCTAContext: '',
            showEmailModal: false,
            showEditEmailModal: false,
        });
        updateMyWalletInteractions({ useFallbackLead: false });
    };

    const formattedFilterValues = [];

    const trimCode = preorderListing?.trim ?? null;
    if (trimCode) {
        formattedFilterValues.push(trimCode);
    }

    const preorderInfo = {
        preorder: {
            ...preorderListing,
        },
    };

    const emailOwnerProps = {
        ...rest,
        userDuck,
        interestRateDuck,
        currentPageNameDuck,
        ctaProps: {
            onHide: handleOnHide,
            show: showEmailFormModal,
            context: emailCTAContext,
        },
        isLoggedIn,
        formattedFilterValues,
        preorderInfo,
        isEditEmailModal: showEditEmailModal,
        uiContext: showEditEmailModal ? 'edit' : 'cta',
        nextStepsProps: {
            ...nextStepsProps,
            getAdditionalInventory,
        },
        ...(emailCTAContext === 'incentives' && { ...incentivesProps }),
        ...([
            'calculatorOfferSummary',
            'calculatorDealSubmitted',
            'calculatorLeaseError',
        ].includes(emailCTAContext) && { ...ndsOfferBreakdownProps }),
    };

    return showEmailFormModal && (
        <ConnectedEmailOwner
            {...emailOwnerProps}
        />
    );
}

const mapStateToProps = (state) => {
    const preorder = preorderDuck.selectors.getDuckState(state);
    const { activeResults = [] } = vdpActiveEmailListingDuck.selectors.getDuckState(state);
    const preorderListing = activeResults.length > 0 ? preorder[activeResults[0]] : {};
    const { id, owner = {} } = vdpResultsDuck.selectors.getVdpActiveInventory(state) || {};
    const { hideEmailForm, id: ownerId } = owner;

    return {
        hideEmailForm,
        preorderListing,
        listingId: id,
        ownerId,
    };
};

const mapDispatchToProps = mapDispatchToActionProp({
    setActiveEmailListingResults: vdpActiveEmailListingDuck.creators.setActiveResults,
    setActiveInteraction: vdpActiveInteractionDuck.creators.setKeys,
    updateMyWalletInteractions: paymentsDuck.creators.updateMyWalletInteractions,
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(VehicleDetailsEmailModalContainer));
