// formats a number using fixed-point notation, ex: 3 -> 3.0
export function fixedNumber(value = 0, digits = 0) {
    if (typeof value === 'number') {
        return value.toFixed(digits);
    }

    return value;
}

// convert review rating score to format [{ name, value }]
export default function formatReviewRatings(ratingData, isAverage) {
    const ratingFields = [
        {
            name: 'Value',
            key: isAverage ? 'averageRatingValue' : 'ratingValue',
        },
        {
            name: 'Performance',
            key: isAverage ? 'averageRatingPerformance' : 'ratingPerformance',
        },
        {
            name: 'Quality',
            key: isAverage ? 'averageRatingQuality' : 'ratingQuality',
        },
        {
            name: 'Comfort',
            key: isAverage ? 'averageRatingComfort' : 'ratingComfort',
        },
        {
            name: 'Reliability',
            key: isAverage ? 'averageRatingReliability' : 'ratingReliability',
        },
        {
            name: 'Styling',
            key: isAverage ? 'averageRatingStyling' : 'ratingStyling',
        },
    ];

    if (ratingData) {
        return ratingFields.map(({ key, name }) => ({ name, value: fixedNumber(ratingData[key], 1) })).filter((item) => item.value);
    }

    return [];
}
