import React from 'react';

import clsx from 'clsx';

import ShowcaseSlot from './ShowcaseSlot';
import { ClearThumbnail, OpaqueThumbnail } from './Thumbnails';

const getThumbNail = (data, column, row) => {
    const elmt = (
        <ShowcaseSlot
            data={data}
            className="secondary-slot"
            key={'elmt-showcase-slot' + column + row}
            ariaLabel="View all media"
        />
    );

    if (data) {
        if (data.badge) {
            return (
                <ClearThumbnail
                    currentDataSlot={data}
                    key={'data-clear-thumbnail' + column + row}
                />
            );
        }
        if (data.allMediaTitle) {
            return (
                <OpaqueThumbnail
                    currentDataSlot={data}
                    allMediaTitle={data.allMediaTitle}
                    onClick={data.onAllMediaClick}
                    key={'data-opaque-thumbnail' + column + row}
                />
            );
        }
        if (data.element) {
            return (
                <ShowcaseSlot
                    onClick={data.onClick}
                    className="secondary-slot"
                    key={'data-showcase-slot' + column + row}
                    ariaLabel="View all media"
                >
                    {data.element}
                </ShowcaseSlot>
            );
        }
    }

    return elmt;
};

function ThumbnailBox({
    currentDataSlot,
    reverseOrder = false,
    isColumn,
    column,
    isMobile,
}) {
    return currentDataSlot && currentDataSlot.length > 0 ? (
        <div
            className={clsx('display-flex', {
                reverse: reverseOrder,
                'flex-column': isMobile || isColumn,
            })}
        >
            {currentDataSlot.map((data, i) => getThumbNail(data, column, i))}
        </div>
    ) : null;
}

export default ThumbnailBox;
