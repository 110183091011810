import { fetchJSON } from '@bonnet/core/fetch';

// eslint-disable-next-line import/prefer-default-export
export const getAdditionalListings = (query = {}) => {
    const options = {
        circuitBreaker: {
            id: 'additionalListings',
            timeout: 3000,
            resetTimeout: 30000,
            fallback: {},
        },
        headers: {
            'X-fwd-svc': 'atc',
        },
        query,
    };

    return fetchJSON('/rest/lsc/listing', options);
};
