import { useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useFeatures } from 'reaxl-features';

import {
    inventoryDuck,
    savedInventoryDuck,
    userDuck,
} from '@/ducks';

import { getAdditionalListings } from '@/fetchers/AdditionalListingsFetcher';

const noop = async () => Promise.resolve();

const getPrioritizedListings = (listings = [], ownerId) => {
    const ownerListings = listings.filter((listing) => listing.ownerId === ownerId);
    const nonOwnerListings = listings.filter((listing) => listing.ownerId !== ownerId);
    const prioritizedListings = ownerListings.concat(nonOwnerListings);
    return prioritizedListings.slice(0, 2);
};

/**
 * This hook creates a fetcher for additional inventory to use
 * in lead generation for either recent or saved listings
 *
 * @param {Boolean} offsetResults - Whether to exclude first result (because is current listing)
 * @returns {(function(): Promise<void>)}
 */
export default function useLeadAdditionalInventory(offsetResults = false, ownerId) {
    const dispatch = useDispatch();
    const {
        brand: [, { channel }],
        lead_gen_additional_inventory: [isLeadAdditionalInv],
    } = useFeatures([
        'brand',
        'lead_gen_additional_inventory',
    ]);

    const recentHistoryIds = useSelector(userDuck.selectors.getRecentHistory) ?? [];
    const savedListingIds = useSelector(savedInventoryDuck.selectors.getSavedInventoryIds) ?? [];

    const listingIds = useRef([]);
    let hasSavedListings = false;

    if (!isLeadAdditionalInv) {
        return noop;
    }

    // precedence: saved, recently viewed
    if (savedListingIds.length) {
        listingIds.current = savedListingIds;
        hasSavedListings = true;
    } else if (recentHistoryIds.length) {
        listingIds.current = recentHistoryIds.map(({ id }) => id);
    }

    const leadGenIndex = !hasSavedListings && !!offsetResults ? 1 : 0;
    return async () => {
        if (listingIds.current.length > leadGenIndex) {
            const ids = listingIds.current?.slice(leadGenIndex);
            const { listings } = await getAdditionalListings({
                listingId: ids,
                // Override Preview channel to behave as ATC for testing L2 Retailing
                channel: channel === 'ATC,PREVIEW' ? 'ATC' : channel,
                collapseFilters: true,
                // only show dealer listings until we can solve for sending private seller leads to PSX for NBA listings
                sellerType: 'd',
            });

            const prioritizedListings = getPrioritizedListings(listings, ownerId);
            if (prioritizedListings) {
                dispatch(inventoryDuck.creators.addInventory(prioritizedListings));
                return { hasSavedListings, listings: prioritizedListings };
            }
        }
        return [];
    };
}
