import React from 'react';

import PropTypes from 'prop-types';

import {
    Glyphicon,
    SubHeading,
} from 'reaxl';

// Individual confidence tiles for primer content
function ConfidenceTile({ glyph, header, description, callToAction }) {
    return (
        <div className="display-flex margin-vertical-4">
            <Glyphicon
                className="margin-right-3 text-size-600 align-self-top"
                glyph={glyph}
                aria-hidden="true"
            />
            <div>
                <SubHeading
                    size="400"
                    className="margin-bottom-1"
                >
                    {header}
                </SubHeading>
                {description}
                {callToAction}
            </div>
        </div>
    );
}

ConfidenceTile.propTypes = {
    /**
     * Glyph to show
     */
    glyph: PropTypes.string.isRequired,
    /**
     * Tile heading
     */
    header: PropTypes.string.isRequired,
    /**
     * Tile description
     */
    description: PropTypes.string.isRequired,
    /**
     * Optional call to action for tile
     */
    callToAction: PropTypes.node,
};

export default ConfidenceTile;
