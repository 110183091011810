// Showcase image sizes
export const thumbnailImageSizes = {
    xs: {
        height: 120,
        width: 160,
    },
    sm: {
        height: 93,
        width: 124,
    },
    md: {
        height: 120,
        width: 160,
    },
    lg: {
        height: 123,
        width: 164,
    },
};

// Showcase image sizes
export const mainImageSizes = {
    xs: {
        height: 243,
        width: 325,
    },
    sm: {
        height: 285,
        width: 381,
    },
    md: {
        height: 366,
        width: 488,
    },
    lg: {
        height: 375,
        width: 500,
    },
    xl: {
        width: 800,
        height: 600,
    },
};

export const videoSizes = {
    xs: {
        height: 246,
        width: 328,
    },
    sm: {
        height: 288,
        width: 384,
    },
    md: {
        height: 366,
        width: 488,
    },
    lg: {
        height: 375,
        width: 500,
    },
};

export default {
    videoSizes,
    mainImageSizes,
    thumbnailImageSizes,
};
