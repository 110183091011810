import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { AlertAutoClose, Text } from 'reaxl';

import { vdpResultsDuck } from '@/ducks/vdp';

function ListingErrorContainer() {
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const listing = useSelector(vdpResultsDuck.selectors.getVdpActiveInventory);

    const { id: listingId } = listing;
    const { fetchError: isFetchError } = useSelector(vdpResultsDuck.selectors.getDuckState);

    const closeErrorMessageHandler = () => {
        setShowErrorMessage(false);
    };

    useEffect(() => {
        if (isFetchError) {
            setShowErrorMessage(true);
            if (window && window.newrelic) {
                window.newrelic.addPageAction('PartialPageLoad', { component: 'ListingErrorContainer', listingId });
            }
        }
    }, [listingId, isFetchError]);

    return isFetchError && (
        <AlertAutoClose
            bsStyle="danger"
            data-cmp="listingErrorAlertContainer"
            className="margin-5"
            timeout={8000}
            sticky={false}
            show={showErrorMessage}
            onHide={closeErrorMessageHandler}
        >
            <Text weight="bold">
                Whoops, we hit a bump and are experiencing technical
                diffculties. Please refresh your browser tab.
            </Text>
        </AlertAutoClose>
    );
}

export default ListingErrorContainer;
