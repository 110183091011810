import { memo, useEffect } from 'react';

import { connect } from 'react-redux';

import { handlePrintEventListener } from 'atc-js';

import { useAnalytics } from 'reaxl-analytics';

import { vdpResultsDuck } from '@/ducks/vdp';

function TrackPrintComponent({
    listing = {},
}) {
    const { sendClick } = useAnalytics();

    useEffect(() => {
        if (Object.keys(listing).length) {
            return handlePrintEventListener((event) => {
                sendClick('printTrack', event);
            });
        }
        return () => { };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
}

const mapStateToProps = (state) => {
    const listing = vdpResultsDuck.selectors.getVdpActiveInventory(state);
    return {
        listing,
    };
};

export const TrackPrintContainer = memo(TrackPrintComponent);

export default connect(mapStateToProps)(TrackPrintContainer);
