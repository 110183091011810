import { sendTabEvent } from 'reaxl-analytics';

import { vdpResultsDuck } from '@/ducks/vdp';

export default {
    sendTabEvent: (metadata, option) => {
        const { data, state } = metadata;
        const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
        return sendTabEvent({
            ...metadata,
            data: {
                ...data,
                inventoryId: activeInventory?.id,
            },
        }, option);
    },
};
