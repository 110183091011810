import { dayOfWeekByLabel, dayOfWeekByValue } from 'atc-js';

// iterate through each hour of operation in half hour increments
const createTimeSlots = (openHour, closeHour, openMinute, closeMinute) => {
    const timeSlots = [];
    for (let i = openHour; i < closeHour; i += 0.5) {
        // round down each hour at half hour mark to represent the current hour mark
        const twentyFourHourString = Math.floor(i);
        let hourString = twentyFourHourString;
        let minuteString = '30';
        let modifier = 'am';

        // case for when time is in 12:00am hour
        if (i < 1) {
            hourString = 12;
        } else {
            // determine if afternoon hour
            if (i >= 12) {
                modifier = 'pm';
            }
            // if time is 13:00 or greater, convert back to 12hr format
            if (i >= 13) {
                hourString -= 12;
            }
        }
        // if current hour is not a half hour, set minute string to 0 minute mark
        if (i % 1 === 0) {
            minuteString = '00';
        }

        // create time slot object to match what EmailFormBody.js expects for dropdown options format
        if ((i === openHour && openMinute > 0) || (i === closeHour && closeMinute > 0)) {
            // if dealer opens or closes at a half hour mark, do not include the time slot before or after
        } else {
            const timeString = `${hourString}:${minuteString}${modifier}`;
            const twentyFourHourTimeString = `${twentyFourHourString}:${minuteString}`;
            const timeSlot = {
                label: timeString,
                value: twentyFourHourTimeString,
            };
            timeSlots.push(timeSlot);
        }
    }
    return timeSlots;
};

// remove non alpha characters to determine if am or pm
const getAmOrPm = (time) => time.replace(/[^a-zA-Z]/g, '').toLowerCase();

// remove all alpha characters to get time string without am or pm
const getTimeValue = (time) => time.replace(/[a-zA-Z]/g, '');

// return true if either open or close values are 'Closed'
const isClosed = ({ open = '', close = '' } = {}) => open.toLowerCase() === 'closed' || close.toLowerCase() === 'closed';

/**
 *
 * @param timeSlotsPerDay - output of getAvailableTimeSlots
 * @returns Date - Date of next day with hours
 */
const getNextOpenDate = (availableTimes) => {
    const date = new Date();
    const startDay = date.getDay();
    const openDays = Object.keys(availableTimes)
        .filter((key) => availableTimes[key].length)
        .map((label) => dayOfWeekByLabel[label]).sort();

    const isTodayValid = () => {
        if (!openDays.includes(startDay)) return false;

        // check if still within scheduable hours
        const lastTimeSlotDate = new Date();
        const [lastSlot] = availableTimes[dayOfWeekByValue[startDay]].slice(-1);
        const lastSlotTime = lastSlot.label.match(/(\d{1,2}):(\d{2})([a-z]{2})/);
        lastTimeSlotDate.setHours(
            (parseInt(lastSlotTime[1], 10) + (lastSlotTime[3] === 'pm' ? 12 : 0)),
            parseInt(lastSlotTime[2], 10)
        );
        return date.getTime() < lastTimeSlotDate.getTime();
    };

    if (!isTodayValid()) {
        const nextOpenDay = openDays.find((day) => day > startDay) || openDays[0];
        if (nextOpenDay !== undefined) {
            const wait = nextOpenDay - startDay;
            date.setDate(date.getDate() + (wait > 0 ? wait : wait + 7));
        }
    }

    return date;
};

/**
 *
 * @param startTime - time string in HH:MM format
 * @param endTime - time string in HH:MM format
 * @returns {[]} - array of time strings
 */
const getAvailableTimeSlots = (startTime, endTime) => {
    let timeSlots = [];
    if (startTime && endTime) {

        const startMeridian = getAmOrPm(startTime);
        const endMeridian = getAmOrPm(endTime);

        const openTime = getTimeValue(startTime);
        const closeTime = getTimeValue(endTime);

        let [openHour, openMinute] = openTime.split(':');
        let [closeHour, closeMinute] = closeTime.split(':');

        openHour = parseInt(openHour, 10);
        openMinute = parseInt(openMinute, 10);
        closeHour = parseInt(closeHour, 10);
        closeMinute = parseInt(closeMinute, 10);

        // convert to 24 hr format to calculate difference from start to end time
        if (openHour === 12) {
            openHour = 0;
        }
        if (startMeridian === 'pm') {
            openHour += 12;
        }
        if (closeHour === 12) {
            closeHour = 0;
        }
        if (endMeridian === 'pm') {
            closeHour += 12;
        }

        timeSlots = createTimeSlots(openHour, closeHour, openMinute, closeMinute);
    }
    return timeSlots;
};

/**
 *
 * @param hours -> owner.hours: hours of operation
 * @returns {object} that CalendarSchedular.js is expecting -> {
 *  availableTimes: {
 *      [day]: array of 30 min time slots between open and close time,
 *      [day]: array of 30 min time slots between open and close time,
 *      ...
 *  }
 *  disabledDaysOfWeek: array of integers #0-6 that represent the day of the week that is closed
 *  nextOpenDate: Date corresponding to next day with hours (inclusive of today)
 * }
 */
export default function getAvailableOwnerTimes(hours) {
    const disabledDaysOfWeek = [];

    const availableTimes = {};

    let nextOpenDate;

    // determine which days of the week are closed, and what the hours are for each day of the week
    if (hours) {
        hours.map((day) => {
            if (isClosed(day)) {
                disabledDaysOfWeek.push(dayOfWeekByLabel[day?.label]);
            }
            // create new field with the day label as the key and the time slots as the value
            availableTimes[day?.label] = getAvailableTimeSlots(day?.open, day?.close);
        });
        nextOpenDate = getNextOpenDate(availableTimes);
    }

    return {
        availableTimes,
        disabledDaysOfWeek,
        nextOpenDate,
    };
}
