import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import clsx from 'clsx';
import _isEmpty from 'lodash/isEmpty';

import {
    Button,
    Glyphicon,
    Text,
} from 'reaxl';
import { sendClick } from 'reaxl-analytics';
import { useBrand } from 'reaxl-brand';
import { useFeatures } from 'reaxl-features';

import {
    listingDataPreprocessing,
} from '@/utilities/dispatchUtils';

import {
    inventoryDuck,
    ownersDuck,
} from '@/ducks';

import {
    srpResultsDuck,
} from '@/ducks/srp';

import fetchListingsAndFilters from '@/fetchers/srp/ListingsAndFiltersFetcher';

import useVdpNavigation from '@/hooks/useVdpNavigation';

const getCurrentQueryParams = () => {
    const currentURL = new URL(window.location.href);
    const searchParams = new URLSearchParams(currentURL.search);

    // remove lnx code for hybrid vdp experience to work properly;
    searchParams.delete('lnx');

    return Object.fromEntries(searchParams);
};

const filterPrivateSeller = ({ owners = [], listings = [] }) => {
    // remove private sellers
    const filteredDealers = owners.filter((owner) => !owner.privateSeller);
    // remove private sellers's listings
    const filteredListings = listings.filter((listing) => filteredDealers.some((owner) => owner.id === listing.owner));
    return { owners: filteredDealers, listings: filteredListings };
};

function VdpPaginationButtons({
    channel = 'ATC',
    listing,
    listingIds,
    query,
    utmApplyVDPExperience = false,
}) {

    const {
        brand: [, { inventory_image_url: inventoryImageUrl }],
        tred_private_seller: [enableTredPrivateSeller, {
            offsiteUrl: privateSellerHostName,
        }],
        standard_listing_phone_display: [displayPhoneNumber],
        standard_listing_owner_name: [hideOwnerName],
    } = useFeatures([
        'brand',
        'tred_private_seller',
        'standard_listing_phone_display',
        'standard_listing_owner_name',
    ]);

    const { brand } = useBrand();

    const initialHybridVdp = useRef(listing.id);

    const initialHybridVdpPage = initialHybridVdp.current === listing?.id;

    const { firstRecord = 0, numRecords = 25 } = query;

    const navigateToVdp = useVdpNavigation();
    const dispatch = useDispatch();

    const [activeIndex, setActiveIndex] = useState(listingIds.findIndex((item) => item?.listingId === listing?.id));

    const [nextPage, setNextPage] = useState({});
    const [prevPage, setPrevPage] = useState({});

    const disableNext = _isEmpty(nextPage) && activeIndex === listingIds.length - 1;
    const disablePrev = _isEmpty(prevPage) && activeIndex === 0;

    // sort listing ids with initial hybrid vdp at top of list
    const sortedListingIds = [{ listingId: initialHybridVdp.current, clickType: 'listing' }, ...listingIds.filter((object) => object.listingId !== initialHybridVdp.current)];
    const sortedList = sortedListingIds.map((object) => object.listingId);

    useEffect(() => {
        if (utmApplyVDPExperience && initialHybridVdpPage) {
            dispatch(srpResultsDuck.creators.setActiveResults(sortedList));
        }

    }, [dispatch, initialHybridVdpPage, sortedList, utmApplyVDPExperience]);

    const handleArrowClickPrevious = async (event) => {
        await sendClick('inventoryVdpPaginationClick', event, { type: 'previous' });
        const previousListing = listingIds[activeIndex - 1];
        let pageCount = firstRecord / numRecords;

        if (activeIndex === 0) {
            pageCount -= 1;
        }

        navigateToVdp({
            ...getCurrentQueryParams(),
            listingId: previousListing?.listingId,
            clickType: previousListing?.clickType || 'listing',
            firstRecord: (numRecords * pageCount),
            numRecords,
        });
    };

    const handleArrowClickNext = async (event) => {
        await sendClick('inventoryVdpPaginationClick', event, { type: 'next' });
        const nextListing = listingIds[activeIndex + 1];

        let pageCount = firstRecord / numRecords;
        if (activeIndex === listingIds.length - 1) {
            pageCount += 1;
        }

        navigateToVdp({
            ...getCurrentQueryParams(),
            listingId: nextListing?.listingId,
            clickType: nextListing?.clickType || 'listing',
            firstRecord: (numRecords * pageCount),
            numRecords,
        });
    };

    const updateListings = useCallback(async ({ type }) => {

        const isNext = type === 'next';

        const newFirstRecord = isNext ? (+firstRecord + +numRecords) : (+firstRecord - +numRecords);

        if (newFirstRecord >= 1000) {
            setNextPage({});
            return;
        }
        if (newFirstRecord <= 0) {
            setPrevPage({});
            return;
        }
        try {

            // Remove any listingId from the query to allow LSC fetcher to work properly
            // eslint-disable-next-line no-unused-vars
            const { listingId, ...fetchListingsQuery } = query;

            const response = await fetchListingsAndFilters({
                ...fetchListingsQuery,
                ...(channel && { channel: channel.toUpperCase() }),
                firstRecord: newFirstRecord,
            });

            const { owners = [], listings = [] } = enableTredPrivateSeller ? filterPrivateSeller(response) : response;

            const data = { brand };
            const vdpUrl = await buildVDPBaseUrl({
                query: fetchListingsQuery,
                data,
                referrer: false,
            });
            if (listings?.length) {
                listings.forEach((fetchedListing) => {
                    listingDataPreprocessing({
                        listing: fetchedListing,
                        owners,
                        displayPhoneNumber,
                        hideOwnerName,
                        enableTredPrivateSeller,
                        privateSellerHostName,
                        inventoryImageUrl,
                        vdpUrl,
                    });
                });
            }

            if (newFirstRecord > firstRecord) {
                setNextPage(!_isEmpty(listings) ? { owners, listings } : {});
            }
            if (newFirstRecord < firstRecord) {
                setPrevPage(!_isEmpty(listings) ? { owners, listings } : {});
            }

            const newListings = isNext ? [
                listing.id,
                ...listings.map((item) => item.id),
            ] : [
                ...listings.map((item) => item.id),
                listing.id,
            ];

            dispatch(ownersDuck.creators.addOwners(owners));
            dispatch(inventoryDuck.creators.addInventory(listings));
            dispatch(srpResultsDuck.creators.setActiveResults(newListings));

        } catch (err) {
            setNextPage({});
            setPrevPage({});
        }
    }, [firstRecord, numRecords, query, channel, enableTredPrivateSeller, brand, listing.id, dispatch, displayPhoneNumber, hideOwnerName, privateSellerHostName, inventoryImageUrl]);

    useEffect(() => {
        setActiveIndex(listingIds.findIndex((item) => item?.listingId === listing?.id));
    }, [listing, listingIds]);

    useEffect(() => {
        if (activeIndex === listingIds.length - 1) {
            updateListings({ type: 'next' });
        }

        if (activeIndex === 0) {
            updateListings({ type: 'previous' });
        }
    }, [activeIndex, updateListings, listingIds.length]);

    const buttonTextArray = [
        'Previous',
        'Next',
    ];

    const vdpNavBtnsComponent = buttonTextArray.map((element) => {
        const isPrev = element.includes('Prev');

        const buttonText = (btnTxt) => (
            <Text
                weight="bold"
            >
                {btnTxt}
            </Text>
        );

        return (
            <Button
                bsStyle="default"
                className={clsx('text-bold margin-left-2', {
                    disabled: isPrev ? disablePrev : disableNext,
                })}
                onClick={isPrev ? handleArrowClickPrevious : handleArrowClickNext}
                key={`${element}-navigation`}
            >
                {!isPrev && buttonText(element)}
                <Glyphicon
                    key={element}
                    glyph={`chevron-${isPrev ? 'left' : 'right'}`}
                    className={clsx(`margin-${isPrev ? 'right' : 'left'}-1`, 'margin-bottom-1')}
                />
                {isPrev && buttonText(element)}
            </Button>
        );
    });

    return (
        <div className="text-nowrap margin-vertical-2">
            {vdpNavBtnsComponent}
        </div>
    );
}

VdpPaginationButtons.propTypes = {
    /**
     *  The channel identify brand to get correct data
     */
    channel: PropTypes.string,
    /**
     *  The data of current listing
     */
    listing: PropTypes.object,
    /**
     *  All listing Ids on a page
     */
    listingIds: PropTypes.array,
    /**
     *  Query data
     */
    query: PropTypes.object,
};

VdpPaginationButtons.defaultProps = {
    channel: 'ATC',
    listing: {},
    listingIds: [],
    query: {},
};

export default VdpPaginationButtons;
