import React, { useCallback, useState } from 'react';

import { useSelector } from 'react-redux';

import clsx from 'clsx';
import _debounce from 'lodash/debounce';
import _get from 'lodash/get';

import { useDevice } from '@bonnet/core/device';

import { getScaledImageSource, waitUntil } from 'atc-js';

import {
    ResponsiveImage,
    SideBarToModal,
} from 'reaxl';

import { vdpResultsDuck } from '@/ducks/vdp';

import { mainImageSizes, thumbnailImageSizes } from './sizes';

export default function MediaGalleryImage({
    type = 'thumbnail',
    mediaType = 'md',
    isPlaceholder,
    hasFyusion,
    src = '',
    onClick = () => {},
    lazyImage = false,
    classNames = '',
    ...rest
}) {
    const imageSize = {};
    const [showFyusionModal, setFyusionShowModal] = useState(false);
    const {
        title,
        vin,
    } = useSelector(vdpResultsDuck.selectors.getVdpActiveInventory);

    const device = useDevice();
    const isXs = _get(device, 'is.xs', false);

    // if type is fullscreen, don't set width/height for image
    if (type === 'video' || type === 'thumbnail') {
        imageSize.width = thumbnailImageSizes[mediaType]?.width;
        imageSize.height = thumbnailImageSizes[mediaType]?.height;
    } else if (type === 'main') {
        imageSize.width = mainImageSizes[mediaType]?.width;
        imageSize.height = mainImageSizes[mediaType]?.height;
    }

    const imgSrc = getScaledImageSource({
        src,
        ...imageSize,
        bgColor: '2d363e',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleResize = useCallback(_debounce(() => {
        CMFyusion?.AppendTo('fyusionPlayer');
    }, 250, { maxWait: 1000 }), []);

    const imageClassNames = clsx('media-gallery-image', classNames);

    const handleShowFyusionModal = () => {
        setFyusionShowModal(true);
        if (document && window) {
            window.addEventListener('resize', handleResize);
        }
        waitUntil(() => !!document?.getElementById('fyusionPlayer'), () => {
            if (typeof CMFyusion !== 'undefined') {
                CMFyusion?.addViewer(vin, 'fyusionPlayer');
            }
        }, 100, 5000);
    };

    const handleCloseModal = () => {
        setFyusionShowModal(false);
        if (document && window) {
            window.removeEventListener('resize', handleResize);
        }
    };

    const renderFyusionModal = () => (
        <SideBarToModal
            data-cmp="modal-fyusion-player"
            key="renderFyusionModal"
            collapsed={!!isXs}
            content={(
                <div
                    id="fyusionPlayer"
                />
            )}
            modalOptions={{
                bsStyle: 'lg',
            }}
            onHide={handleCloseModal}
            sideBarOptions={{
                fullWidth: true,
            }}
            show={showFyusionModal}
            title={title}
        />
    );

    return (
        <div
            style={{ ...imageSize }}
            className="media-image-wrapper bg-gray-darker"
            id="spin360"
        >
            <ResponsiveImage
                src={imgSrc}
                onClick={hasFyusion ? handleShowFyusionModal : onClick}
                lazyload={lazyImage}
                className={imageClassNames}
                {...imageSize}
                {...rest}
            />
            {!!hasFyusion && renderFyusionModal()}
        </div>
    );
}
