import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import clsx from 'clsx';
import _get from 'lodash/get';

// bonnet
import { useDevice } from '@bonnet/next/device';
import { ErrorBoundary } from '@bonnet/next/error-boundary';

import { BrowserStorage } from 'atc-js';

import {
    Glyphicon,
    Heading,
    Text,
} from 'reaxl';
import { useBrand } from 'reaxl-brand';
import { useFeatures } from 'reaxl-features';
import {
    HotCar,
    NewlyListed,
    ReducedPrice,
} from 'reaxl-molecules';

// fetchers
import {
    vdpResultsDuck,
} from '@/ducks/vdp';

import { viewCountFetcher } from '@/fetchers';

import WalletAlertsContainer from '@/containers/WalletAlertsContainer';

import KBBConsumerRatings from './RatingsAndReviews/KBBConsumerRatings';
import SaveAndShareContainer from './SaveAndShareContainer';

export function VehicleDetailsTitleContainer({
    className,
    showRatingsAndReviews,
}) {
    const device = useDevice();
    const isXs = _get(device, 'is.xs', false);
    const { brands, isBrand } = useBrand();
    const isKbbBranded = isBrand(brands.KBB_BRAND);
    const listing = useSelector(vdpResultsDuck.selectors.getVdpActiveInventory);

    const {
        enable_view_count: [isViewCountEnabled, {
            min_days: minDays,
            min_views: minViews,
            date_range: dateRange,
        }],
        show_wallet_alert_notification: [showWalletAlertNotification],
    } = useFeatures([
        'enable_view_count',
        'show_wallet_alert_notification',
    ]);

    const [count, setCount] = useState(null);

    const {
        daysOnSite,
        isHot,
        isNewlyListed,
        isReducedPrice,
        kbbConsumerRatings,
        kbbConsumerReviewCount,
        listingTitlePSX,
        owner: { privateSeller } = {},
        title,
        vin,
    } = listing;

    const listingTitle = (privateSeller && listingTitlePSX) ? listingTitlePSX : title;

    const getViewCount = async () => {
        const theCount = await viewCountFetcher({ vin, dateRange });
        const { KBB = 0, ATC = 0 } = theCount;
        const totalCount = KBB + ATC;
        if (daysOnSite > minDays && totalCount > minViews) setCount(totalCount);
    };

    useEffect(() => {
        if (isViewCountEnabled && !isHot) {
            getViewCount();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vin]);

    const viewCountClassNames = clsx({
        'margin-left-sm-5': (isNewlyListed || isReducedPrice),
        'margin-left-sm-0': !(isNewlyListed || isReducedPrice),
        'margin-left-auto': true,
    });

    const walletNotifCache = new BrowserStorage('WALLET_NOTIF_POPOVER', { persist: true });

    const showWalletAlert = walletNotifCache.getItem()?.show && showWalletAlertNotification;

    const urgencyDriver = (isHot && <HotCar description="Highly likely to sell within a week" />) || (isNewlyListed && <NewlyListed />) || (isReducedPrice && <ReducedPrice />);

    return (
        <div
            data-qaid="cntnr-vehicle-title"
            className={clsx({ 'margin-top-5': isKbbBranded }, className)}
        >
            {(showWalletAlert && !isXs) && (
                <WalletAlertsContainer enableAlert={showWalletAlert} />
            )}
            <div>
                <ErrorBoundary>
                    {(isHot || isNewlyListed || isReducedPrice || count) && (
                        <div
                            className="row display-flex align-items-center justify-content-sm-start justify-content-between"
                        >
                            <div className="col-xs-12 margin-top-3">
                                {urgencyDriver}
                                {!!count && (
                                    <Text
                                        className={viewCountClassNames}
                                        data-cmp="vdp-view-count"
                                        data-testid="vdp-view-count"
                                        color="subdued-lightest"
                                        size={200}
                                        weight="bold"
                                    >
                                        <Glyphicon glyph="eye-outline" />
                                        {` ${count} view${count === 1 ? '' : 's'}`}
                                    </Text>
                                )}
                            </div>
                        </div>
                    )}
                </ErrorBoundary>
                <div className="row display-flex align-items-center margin-bottom-1 margin-bottom-sm-0">

                    <Heading
                        size={400}
                        // force even larger size on sm+ screens
                        className="text-size-sm-700 col-xs-12 col-sm-7 col-md-8"
                        componentClass="h1"
                        id="vehicle-details-heading"
                    >
                        {listingTitle}
                    </Heading>
                    {!isXs && <SaveAndShareContainer />}
                </div>
                {showRatingsAndReviews
                    && (
                        <KBBConsumerRatings
                            className="margin-bottom-4"
                            kbbConsumerRatings={kbbConsumerRatings}
                            kbbConsumerReviewCount={kbbConsumerReviewCount}
                        />
                    )}
            </div>
        </div>
    );
}

export default VehicleDetailsTitleContainer;
