import React from 'react';

import dynamic from 'next/dynamic';

import hasDom from 'has-dom';

import { Glyphicon, LoadingSpinner } from 'reaxl';

import LazyComponent from '@/components/LazyComponent';

import MediaGalleryImage from './MediaGalleryImage';
import MediaWithExpander from './MediaWithExpander';
import { videoSizes } from './sizes';
import SpinShowcase from './SpinShowcase';
import tabTitles from './tabTitles';

const Video = dynamic(() => import(/* webpackChunkName: "VideoPlayers" */ 'reaxl-video-player/es/Video'), { ssr: false });

// Videos for Overlay
const getOverlayVideos = (videos, props) => videos.map((videoData, i) => {
    const { onVideoPlay, onVideoCompleted, onVideoMilestones, ...rest } = props;

    return ({
        element: (
            <LazyComponent fallback={<LoadingSpinner style={{ height: 350 }} />}>
                <Video
                    key={`mv-overlay-videos-${i}`}
                    {...videoData}
                    videoId={`${videoData.videoId}-overlay`}
                    className="display-flex padding-top-2 padding-bottom-2 bg-gray-base justify-content-center"
                    width={500}
                    height={350}
                    show
                    onPlayerReady={() => {}}
                    onPlay={() => onVideoPlay(videoData)}
                    onCompleted={() => onVideoCompleted(videoData)}
                    onMilestones={(milestone) => onVideoMilestones(videoData, milestone)}
                    {...rest}
                />
            </LazyComponent>
        ),
    });
});

const renderSpinViewer = (mediaMaxHeight) => (
    <div
        id="spin"
        className="center-block display-flex justify-content-center align-items-center text-center"
        style={{
            width: '100%',
            height: '100%',
            maxWidth: mediaMaxHeight ? mediaMaxHeight * 9 / 5 : '100vw',
            maxHeight: mediaMaxHeight || '100vh',
        }}
    />
);

export default function getShowcaseOverlayData({
    images = [],
    videos = [],
    showSpin,
    hasFyusion,
    mediaType,
    listingTitle,
    isMobileLandscape,
    isXs,
    onOverlayClick = () => {},
    onShowcaseImgClick = () => {},
    onVideoPlay = () => {},
    onVideoCompleted = () => {},
    onVideoMilestones = () => {},
    paymentSnapshot,
    showOverlay,
}) {
    const data = {
        thumbnails: [],
        overlay: [],
        fullscreen: [],
        viewAllMedia: {},
    };

    // we'll pull higher resolution photo if there is only 1 media to display in showcase
    const isSingleData = videos.length === 0 && images.length === 1;
    const mainMediaType = isSingleData ? 'lg' : mediaType;

    let mediaMaxHeight;
    if (hasDom()) {
        // fullscreen needs more space to display header ctas and bottom pagination,
        // so media cannot take the full height of viewport
        mediaMaxHeight = window.innerHeight - (isMobileLandscape ? 70 : 100);
    }

    if (showSpin) {
        data.thumbnails.push({
            element: (
                <SpinShowcase
                    src={images[0]?.src}
                    alt={listingTitle}
                    mediaType={mainMediaType}
                    hasFyusion={hasFyusion}
                    // onClick={(e) => {
                    //     onShowcaseImgClick(e, tabTitles.SPIN, 0);
                    // }}
                />
            ),
            category: '360 View',
        });
        data.fullscreen.push({
            element: renderSpinViewer(mediaMaxHeight),
        });
    }
    if (images.length > 0) {
        const showcaseImageElements = images.map((imageData, i) => ({
            element: (
                <MediaGalleryImage
                    key={`mv-imgs-${i}`}
                    src={imageData.src}
                    type="thumbnail"
                    alt="Vehicle Image"
                    onClick={(e) => onShowcaseImgClick(e, tabTitles.IMAGES, showSpin ? i + 1 : i)}
                    mediaType={mediaType}
                    lazyImage={i > 2 && isXs}
                />
            ),
            // TODO: Interior, add text and images when we actually can tell the difference
            category: 'Images',
        }));

        data.viewAllMedia = {
            element: (
                <MediaGalleryImage
                    src={images[0]?.src}
                    type="thumbnail"
                    alt="Vehicle Image"
                    mediaType={mediaType}
                />
            ),
            category: 'Images',
        };

        if (data.thumbnails.length === 0) {
            data.thumbnails.push({
                element: (
                    <MediaGalleryImage
                        src={images[0]?.src}
                        type="main"
                        mediaType={mainMediaType}
                        alt={listingTitle}
                        onClick={(e) => onShowcaseImgClick(e, tabTitles.IMAGES, 0)}
                        lazyImage={false}
                        loading="eager"
                    />
                ),
                category: 'Images',
            });
            data.thumbnails.push(...showcaseImageElements.slice(1));
        } else {
            data.thumbnails.push(...showcaseImageElements);
        }
        const mediaGalleryImages = (src, i, onExpand) => (
            <MediaGalleryImage
                src={src}
                key={`mv-overlay-imgs-${i}`}
                mediaType={mediaType}
                type="fullscreen"
                alt="Vehicle Image"
                isClickable
                onClick={onExpand}
                classNames="cursor-pointer"
            />
        );
        const imagesDataset = images.map(({ src } = {}, i) => {
            const onExpand = (e) => onOverlayClick(e, tabTitles.IMAGES, showSpin ? i + 1 : i);
            return {
                element: (
                    <MediaWithExpander
                        media={mediaGalleryImages(src, i, onExpand)}
                        onExpand={onExpand}
                    />
                ),
            };
        });
        if (paymentSnapshot) {
            imagesDataset.push({
                element: paymentSnapshot,
            });
        }
        data.overlay.push({
            tabTitle: tabTitles.IMAGES,
            mediaDataSet: imagesDataset,
            gridTemplateColumns: '1fr',
        });
        data.fullscreen.push(...images.map((imageData = {}, i) => ({
            element: (
                <MediaGalleryImage
                    key={`mv-full-imgs-${i}`}
                    src={imageData.src}
                    sizeScalable={false}
                    widhtCrop
                    type="fullscreen"
                    imgMaxHeight={mediaMaxHeight + 'px'}
                    mediaType={mediaType}
                    alt={listingTitle}
                />
            ),
        })));
        if (paymentSnapshot) {
            data.fullscreen.push({ element: (
                <div className="display-flex justify-content-center align-items-center">
                    {paymentSnapshot}
                </div>
            ),
            });
        }
    }

    if (videos.length > 0) {
        const videoStartIndex = images.length + (showSpin ? 1 : 0);
        videos.forEach((video, i) => {
            data.thumbnails.push({
                category: 'Video',
                element: (
                    <>
                        <MediaGalleryImage
                            key={`mv-videos-${i}`}
                            // Default to first image as thumbnail for video
                            src={video.thumbnail || images[0]?.src}
                            alt="View Video"
                            mediaType={mediaType}
                            onClick={(e) => onShowcaseImgClick(e, tabTitles.VIDEOS, videoStartIndex + i)}
                        />
                        <Glyphicon
                            glyph="play-circle"
                            className="media-play-circle"
                        />
                    </>
                ),
            });
        });
        const videosDataSet = getOverlayVideos(videos, {
            ...videoSizes[mediaType],
            onVideoPlay,
            onVideoCompleted,
            onVideoMilestones,
            showOverlay,
        });
        if (paymentSnapshot) {
            videosDataSet.push({
                element: paymentSnapshot,
            });
        }
        data.overlay.push({
            tabTitle: tabTitles.VIDEOS,
            mediaDataSet: videosDataSet,
            gridTemplateColumns: '1fr',
        });
        data.fullscreen.push(...videos.map((videoData = {}, i) => ({
            element: (
                <LazyComponent fallback={<LoadingSpinner style={{ height: 350 }} />}>
                    <Video
                        key={`mv-full-videos-${i}`}
                        onPlayerReady={() => {
                        }}
                        {...videoData}
                        videoId={`${videoData.videoId}-fullscreen`}
                        width="100%"
                        height={mediaMaxHeight}
                        show
                        className="align-items-center display-flex justify-content-center"
                        onPlay={() => onVideoPlay(videoData)}
                        onCompleted={() => onVideoCompleted(videoData)}
                        onMilestones={(milestone) => onVideoMilestones(videoData, milestone)}
                    />
                </LazyComponent>
            ),
        })));
    }

    return data;
}
