import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';

import _isEqual from 'lodash/isEqual';

import {
    Accordion,
    AccordionPanel,
    Heading,
    LinkTile,
    ListColumns,
    Panel,
    Section,
    SubHeading,
    Tab,
    Tabs,
    Text,
} from 'reaxl';
import { sendClick, sendImpressions } from 'reaxl-analytics';
import { useFeatures } from 'reaxl-features';

import {
    vdpResultsDuck,
} from '@/ducks/vdp';

import ModelInfoContainer from './ModelInfoContainer';
import SpecificationsGridContainer from './SpecificationsGridContainer';

// default state is to render each category in its own AccordionPanel
const renderFeatures = (features, handleToggle, numColumns = 3) => Object.entries(features).map(([key, value]) => (
    <AccordionPanel
        onToggle={handleToggle}
        title={`${key[0].toUpperCase() + key.slice(1)}`}
        eventKey={key}
        key={`${key}-options`}
    >
        <ListColumns
            items={[...value]}
            sm={numColumns}
            expanded
        />
    </AccordionPanel>
));

const renderFeaturesTab = (features, optionsStartingIndex, numColumns = 3) => Object.entries(features).map(([key, value], index) => (
    <Tab
        title={`${key[0].toUpperCase() + key.slice(1)}`}
        eventKey={index + optionsStartingIndex}
        key={key}
    >

        <ListColumns
            className="padding-left-2 padding-top-3"
            items={[...value]}
            lg={3}
            sm={numColumns}
            condensed
        />
    </Tab>
));

const renderPackageDetails = (packages) => packages.map((packageInfo) => {
    let columnizedValues = [];
    let name = packageInfo;
    if (typeof packageInfo !== 'string' && Object.keys(packageInfo).length) {
        const { details } = packageInfo;
        columnizedValues = details ? details.split(', ').reverse() : [];
        name = packageInfo.name;
    }
    return (
        <AccordionPanel
            title={(
                <Text
                    transformCase="capitalize"
                    size={400}
                >
                    {name}
                </Text>
            )}
            eventKey={name}
            key={`${name}-packages`}
        >
            <ListColumns
                items={[...columnizedValues]}
                sm={3}
                expanded
            />
        </AccordionPanel>
    );
});

const renderPackageTab = (packages, startingIndex) => (
    <Tab
        title="Packages"
        eventKey={startingIndex}
        key="packages"
    >
        {packages.map((packageInfo, index) => {
            let columnizedValues = [];
            let name = packageInfo;
            if (typeof packageInfo !== 'string' && Object.keys(packageInfo).length) {
                const { details } = packageInfo;
                columnizedValues = details ? details.split(', ').reverse() : [];
                name = packageInfo.name;
            }
            return (
                <div
                    className="padding-top-5"
                    key={`${name}-packages`}
                >
                    <SubHeading
                        className="padding-left-2 padding-bottom-4"
                        componentClass="div"
                        size="300"
                    >
                        {name}
                    </SubHeading>
                    <ListColumns
                        className="padding-left-2"
                        items={[...columnizedValues]}
                        sm={2}
                        lg={3}
                        expanded
                    />
                </div>
            );
        })}
    </Tab>
);

const renderSpecificationsGridTab = () => (
    <Tab
        className="padding-top-3"
        title="Highlights"
        eventKey={0}
        key="specifications"
    >
        <SpecificationsGridContainer bordered={false} />
    </Tab>
);

const renderVehicleDetailsSection = (content, heading, key = 'vehicle-details-section') => (
    <div key={key}>
        <Heading
            className="padding-bottom-2 padding-top-5"
            size={600}
        >
            {heading}
        </Heading>
        <Panel
            alignment="left"
        >
            <Accordion
                bsStyle="filters"
                className="text-left"
            >
                {content}
            </Accordion>
        </Panel>
    </div>
);

const renderTabbedContent = (tabbedContent, handleTabClick) => (
    <Tabs
        defaultActiveKey={0}
        key="vehicle-features-container"
        className="nav-tabs-light nav-tabs-fixed-height"
        alignment="left"
        onSelect={handleTabClick}
    >
        {tabbedContent.map((item) => item)}
    </Tabs>
);

export function VehicleFeaturesContainer({
    showHybridVdp = false,
}) {
    const {
        combined_specs_features: [isCombinedSpecsEnabled],
    } = useFeatures([
        'combined_specs_features',
    ]);

    const listing = useSelector((state) => vdpResultsDuck.selectors.getVdpActiveInventory(state));
    const { features, productTiles = [], packages = [], make = '', model = '', owner = {} } = listing;

    const { privateSeller: isPrivateSeller } = owner;

    const siriusTile = productTiles.find((tile) => tile?.epn === 'sirius_xm');

    useEffect(() => {
        if (siriusTile?.visible && siriusTile?.clickable) {
            sendImpressions({
                name: 'inventoryProductImpressions',
                data: {
                    epn: siriusTile.epn,
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderSiriusXM = () => {
        const { visible, clickable, epn, ...rest } = siriusTile;
        const displaySiriusXM = visible && clickable;

        if (!displaySiriusXM) {
            return null;
        }
        const handleClick = (event) => {
            sendClick('inventoryProductClick', event, { epn });
        };

        return (
            <div
                className="display-flex justify-content-center justify-content-md-start"
                key="sirius-xm-link"
            >
                <LinkTile
                    key={siriusTile.tileType}
                    onLinkClick={handleClick}
                    onTileClick={handleClick}
                    {...rest}
                />
            </div>
        );
    };

    const handleToggle = (accordionKey, active) => {
        if (!active) {
            sendClick('inventoryDetailAccordionClick', null, { key: accordionKey, isPsxVdp: isPrivateSeller });
        }
    };

    const sectionHeader = `Get to know this ${make?.name ?? make} ${model?.name ?? model}`;

    const vehicleItems = [];
    const tabbedContent = [];
    const enabledTabs = [];

    if (!!features && showHybridVdp) {
        // This is model info component that needs to be moved up
        vehicleItems.push(<ModelInfoContainer key="model-info-container" />);
    }

    if (!showHybridVdp && isCombinedSpecsEnabled) {
        tabbedContent.push(renderSpecificationsGridTab());
        enabledTabs.push('highlights');
    }

    let optionsStartingIndex = 0;

    if (features) {
        if (isCombinedSpecsEnabled) {
            optionsStartingIndex = 1;
        }

        if (!showHybridVdp) {
            tabbedContent.push(renderFeaturesTab(features, optionsStartingIndex));
            Object.keys(features).forEach((key) => enabledTabs.push(key));
        } else {
            vehicleItems.push(renderVehicleDetailsSection((renderFeatures(features, handleToggle)), sectionHeader));
        }
    }

    if (packages.length) {
        if (!showHybridVdp) {
            let packageStartingIndex = 0;

            if (isCombinedSpecsEnabled) {
                packageStartingIndex = 1;
            }

            if (features) {
                packageStartingIndex = features ? Object.entries(features).length : 1;

                if (isCombinedSpecsEnabled) {
                    packageStartingIndex = features ? Object.entries(features).length + 1 : 1;
                }
            }

            tabbedContent.push(renderPackageTab(packages, packageStartingIndex));
            enabledTabs.push('packages');
        } else {
            vehicleItems.push(renderVehicleDetailsSection(renderPackageDetails(packages), 'Included Packages', 'packages-section'));
        }
    }

    if (siriusTile && showHybridVdp) {
        vehicleItems.push(renderSiriusXM());
    }

    const handleTabClick = (activeKey) => {
        sendClick('inventoryDetailAccordionClick', null, { key: enabledTabs[activeKey], isPsxVdp: isPrivateSeller });
    };

    const renderSectionWithTabs = () => (
        <>
            {!!features && <ModelInfoContainer key="model-info-container" />}

            <div>
                <Heading
                    className="padding-bottom-2 padding-top-5"
                    size={600}
                >
                    {sectionHeader}
                </Heading>
                {renderTabbedContent(tabbedContent, handleTabClick)}
            </div>

            {siriusTile && renderSiriusXM()}
        </>
    );

    const renderTabbedSectionWithSpecifications = () => (
        <div className="padding-bottom-4">
            {renderTabbedContent(tabbedContent, handleTabClick)}
            {!!features && (
                <div className="margin-vertical-4">
                    <ModelInfoContainer
                        key="model-info-container"
                    />
                </div>
            )}
        </div>
    );

    return (!!vehicleItems.length || !!tabbedContent.length || siriusTile) && !showHybridVdp && isCombinedSpecsEnabled
        ? renderTabbedSectionWithSpecifications() : (
            <Section
                alignment="left"
                className="padding-vertical-4 padding-vertical-md-5"
                uiContext="section-2"
                key="vehicle-features-container"
            >
                {showHybridVdp && vehicleItems.map((item) => item)}
                {!showHybridVdp && tabbedContent.length >= 1 && renderSectionWithTabs()}
            </Section>
        );
}

export default React.memo(VehicleFeaturesContainer, _isEqual);
