import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { determineLender, formattedNumber } from 'atc-js';

import { Text } from 'reaxl';
import { InfoIcon } from 'reaxl-molecules';
import { OfferBreakdown } from 'reaxl-native-deal-calculator';
import { LenderPayment } from 'reaxl-wallet';

import {
    paymentsDuck,
} from '@/ducks';

function OfferSummary({
    config = {},
    listing,
}) {
    const dispatch = useDispatch();

    const {
        disableTrade = false,
        enableTaxesAndFees = false,
        listingPrice: fallbackListingPrice,
    } = config;

    const {
        id,
        paymentServices: { preferredLenderSpecified, paymentServiceActive } = {},
        pricingDetail: { salePrice, msrp } = {},
        type,
    } = listing;

    const { value: tradeInValue = 0, amountOwed = 0 } = useSelector(paymentsDuck.selectors.getTradeIn) || {};
    const { offerPrice } = useSelector(paymentsDuck.selectors.getPencilingValues) || {};

    // Get payments
    const paymentsInfo = useSelector(paymentsDuck.selectors.getPaymentsInfo);
    const financingInfo = useSelector((state) => paymentsDuck.selectors.getLowestPencilingPaymentByListingId(state, id));
    const leasingInfo = useSelector((state) => paymentsDuck.selectors.getLowestLeasePaymentByListingId(state, id));
    const {
        pricingDetails: cashPricingDetails,
        taxesFees: { totalTaxesFees: cashTaxesFees } = {},
    } = useSelector((state) => paymentsDuck.selectors.getLowestCashPaymentByListingId(state, id));

    const {
        dealType,
        downPayment,
        interestRate,
        isLoading,
        interactions: {
            hasLeaseError = false,
            hasRequestedTaxesFees = false,
            isNationalRates = false,
        } = {},
    } = paymentsInfo;

    const isFinance = dealType === 'finance';
    const isLease = dealType === 'lease';
    const isCash = dealType === 'cash';

    const {
        downPayment: financeDownPayment = 0,
        offeredBy = '',
        payment: financePayment,
        pricingDetails: financePricingDetails,
        taxesFees: { totalTaxesFees: financeTaxesFees } = {},
    } = financingInfo;
    const {
        acquisitionFee,
        capCost: { adjustedCapCost } = {},
        downPayment: dueAtSigningValue = 0,
        payment: leasePayment,
        pricingDetails: leasePricingDetails,
        taxesFees: { totalTaxesFees: leaseTaxesFees } = {},
    } = leasingInfo;

    const getTotalTaxesFees = () => {
        if (!enableTaxesAndFees) return 0;
        return Math.floor(({
            cash: cashTaxesFees,
            finance: financeTaxesFees,
            lease: leaseTaxesFees,
        }[dealType])) || 0;
    };

    const listingType = type && type.toLowerCase();
    const vehicleCondition = listingType === 'certified' ? 'used' : listingType;

    const customRate = interestRate?.custom;
    const rate = customRate || financingInfo?.[vehicleCondition] || interestRate?.[vehicleCondition] || 4.5;

    const price = offerPrice || salePrice || msrp || fallbackListingPrice;

    const totalEquity = tradeInValue - amountOwed;
    const totalTaxesFees = getTotalTaxesFees();
    const totalCashOut = price - totalEquity + totalTaxesFees;
    const totalFinancing = price - totalEquity - (downPayment || financeDownPayment) + totalTaxesFees;

    const getPricingBreakdown = (tabName) => {
        const pricingDetails = {
            cash: cashPricingDetails,
            lease: leasePricingDetails,
            finance: financePricingDetails,
        };

        const pricingBreakdown = { ...pricingDetails[tabName] };

        pricingBreakdown.manufacturerOffer = pricingBreakdown.rebate;
        pricingBreakdown.offersUpgrades = pricingBreakdown.specialOfferValue;

        if (pricingBreakdown.salePrice) {
            pricingBreakdown.listingPrice = pricingBreakdown.salePrice;
        }

        if (offerPrice) {
            pricingBreakdown.offerPrice = offerPrice;
        }

        return pricingBreakdown;
    };

    const handleFallbackPayment = (fallbackPayment) => {
        dispatch(paymentsDuck.creators.updatePaymentPencils({ fallbackPayment }));
    };

    const renderLenderPayment = () => {
        if (isLoading) return null;

        if (isCash || isFinance && totalFinancing < 0) {
            return (
                <LenderPayment
                    key="cashPayment"
                    price={isFinance ? 0 : Math.max(0, totalCashOut)}
                    uiContext="cash"
                />
            );
        }
        return (
            <LenderPayment
                key="lendingPayment"
                paymentsInfo={paymentsInfo}
                price={price}
                uiContext="calculator"
                vehicleCondition={vehicleCondition}
                {...(isFinance && {
                    handleFallbackPayment,
                    interestRate: rate,
                    lender: determineLender(customRate, offeredBy),
                    personalizedPayment: financePayment,
                    showLenderDisclaimer: !(paymentServiceActive && preferredLenderSpecified),
                })}
                {...(isLease && {
                    dealType: 'lease',
                    hasError: hasLeaseError,
                    personalizedPayment: leasePayment,
                    showLenderDisclaimer: false,
                })}
            />
        );
    };

    const renderTaxesAvailable = () => (
        <div className="col-xs-5 col-md-4 padding-0 flex-shrink-0">
            {totalTaxesFees !== 0 ? (
                <Text
                    size={300}
                    componentClass="div"
                    className="text-right"
                    color="subdued"
                >
                    <Text
                        size={300}
                        color="subdued"
                    >
                        + $
                    </Text>
                    {formattedNumber({ value: totalTaxesFees })}
                </Text>
            ) : (
                <Text
                    size={300}
                    componentClass="div"
                    className="text-right"
                    color="subdued"
                >
                    Check with dealer
                </Text>
            )}
        </div>
    );

    const renderTaxesAndFees = () => {
        if (!enableTaxesAndFees) return null;
        return (
            <div
                className="display-flex justify-content-between margin-bottom-4"
                key="taxAndFee"
            >
                <Text
                    className="display-flex flex-wrap gap-1"
                    color="subdued"
                    key="taxesAndFeesText"
                >
                    Taxes & Fees
                    <InfoIcon
                        id="taxes-and-fees-info"
                        infoText="Usually includes sales tax, DMV fees, any lender fees for financing, plus dealer-related fees for doc and prep"
                        placement="top"
                    />
                </Text>

                {(!isNationalRates && hasRequestedTaxesFees) ? renderTaxesAvailable() : (
                    <Text
                        componentClass="div"
                        className="text-overflow"
                        color="subdued"
                    >
                        Not Available
                    </Text>
                )}
            </div>
        );
    };

    return (
        <>
            {renderLenderPayment()}
            <OfferBreakdown
                offerBreakdownProps={{
                    amountOwed,
                    disableTrade,
                    dueAtSigningValue: downPayment || dueAtSigningValue,
                    getPricingBreakdown,
                    tradeInValue,
                }}
                renderTaxesAndFees={renderTaxesAndFees}
                tabName={dealType}
                {...(isFinance && {
                    downPayment: downPayment || financeDownPayment,
                    totalFinancing,
                })}
                {...(isLease && {
                    acquisitionFee,
                    adjustedCapCost,
                })}
                {...(isCash && {
                    totalCashOut,
                })}
            />
        </>
    );
}

export default OfferSummary;
