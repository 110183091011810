import React from 'react';

import PropTypes from 'prop-types';

/**
 * This Anchor component can be used for jumplinks
 * in cases where components cannot have an `id`
 * or will not exist on page load.
 *
 * If component exists on page load, adding `id`
 * directly to the root element of the component
 * is the best practice instead of this standlone anchor.
 *
 * @param {id} id for anchor
 * @returns anchor element
 */
function Anchor({
    id,
}) {
    return (
        // eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid -- not intended to be keyboard navigable
        <a
            id={id}
            tabIndex="-1"
            aria-hidden="true"
        />
    );
}

Anchor.propTypes = {
    id: PropTypes.string.isRequired,
};

export default Anchor;
