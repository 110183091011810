import { formatReviewRatings } from '@/utilities';

import { createSelector, DuckSelector, objectDuckCreator } from '@atc/modular-redux';

import { vdpDuckPreset } from '@/ducks/presets';

import { kbbReviewsFetcher } from '@/fetchers';

export default objectDuckCreator({
    ...vdpDuckPreset,
    store: 'ratingReview',
    initialState: {
        expertReview: null,
        consumerReviews: [],
        consumerRatings: {},
        showReviewsModal: false,
        scrollToTopModal: false,
    },
}).extend({
    creators: (duck) => ({
        hydrateConsumerReviews: (kbbVehicleId) => async (dispatch) => {
            // only get 1 consumer review sorted by 'high to low' to display in VDP
            const response = await kbbReviewsFetcher(kbbVehicleId, { target: 'consumerReview', query: { perPage: 1, sort: 3 } });

            if (response?.results?.length > 0) {
                const consumerReviews = response.results.map((item) => ({ ...item, ratings: formatReviewRatings(item, false) }));
                dispatch(duck.creators.setKeys({ consumerReviews }));
            }

            if (response?.ratings) {
                dispatch(duck.creators.setKeys({ consumerRatings: response.ratings }));
            }

        },
        hydrateExpertReviews: (kbbVehicleId) => async (dispatch) => {
            const expertReview = await kbbReviewsFetcher(kbbVehicleId, { target: 'expertReview' });
            if (expertReview) {
                dispatch(duck.creators.setKeys({
                    expertReview,
                }));
            }
        },
        setShowReviewsModal: (showReviewsModal) => (dispatch) => {
            dispatch(duck.creators.setKeys({
                showReviewsModal,
            }));
        },
        setScrollToTopModal: (scrollToTopModal) => (dispatch) => {
            dispatch(duck.creators.setKeys({
                scrollToTopModal,
            }));
        },
    }),
    selectors: () => ({
        getAverageRatings: new DuckSelector((selector) => createSelector(
            selector.getDuckState,
            ({ consumerRatings }) => formatReviewRatings(consumerRatings, true),
        )),
        getExpertReview: new DuckSelector((selector) => createSelector(
            selector.getDuckState,
            ({ expertReview }) => expertReview,
        )),
    }),
});
