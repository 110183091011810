import React, {
    memo,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';

import { useDispatch, useSelector } from 'react-redux';

import _get from 'lodash/get';

// redux

// bonnet
import { useDevice } from '@bonnet/next/device';

import {
    SideBarToModal,
    Text,
} from 'reaxl';
import { sendClick } from 'reaxl-analytics';
import { useFeatures } from 'reaxl-features';

import { dealsDuck, paymentsDuck, queryDuck } from '@/ducks';

import {
    vdpActiveInteractionDuck,
    vdpResultsDuck,
} from '@/ducks/vdp';

const sixtyDaysInMs = 5184000000; // 60 * 24 * 60 * 60 * 1000

/*
 * render a SideBarToModal of standalone credit app when NDS CTA is clicked, or render a SideBarToModal of the
 * lead form that will then open the standalone credit app
 */
function StandaloneCreditAppContainer({ creditAppHost }) {

    const listenerRef = useRef(false);
    const device = useDevice();
    const isXs = _get(device, 'is.xs', false);

    const dispatch = useDispatch();

    const [showCreditAppModal, setShowCreditAppModal] = useState(false);
    const showFinanceAppModal = useSelector(vdpActiveInteractionDuck.selectors.getShowFinanceAppModal);
    const query = useSelector(queryDuck.selectors.getDuckState);

    const {
        id: listingId,
        owner,
    } = useSelector(vdpResultsDuck.selectors.getVdpActiveInventory);

    const {
        id: ownerId,
        name: ownerName,
        sacaMmdId,
    } = owner;

    const {
        standalone_credit_app: [, {
            sponsor,
        }],
        enable_deals_duck: [isDealsDuckEnabled],
    } = useFeatures([
        'standalone_credit_app',
        'enable_deals_duck',
    ]);

    // handle hiding the credit app side bar/modal
    const handleHideStandaloneCreditAppContainer = useCallback(() => {
        setShowCreditAppModal(false);
        dispatch(vdpActiveInteractionDuck.creators.setKeys({ showFinanceAppModal: false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /*
     * listen for the dealRefId from standalone credit app window message and assign to
     * current user for them to track their deal status
     */
    useEffect(() => {
        if (showCreditAppModal) {

            const creditAppBaseUrl = creditAppHost && creditAppHost.replace('/dealer/', '');

            const storeCreditDealInfo = (dealId) => {
                const now = new Date();
                const deal = {
                    ownerName,
                    dealId,
                    ownerId,
                    listingId,
                    submittedOn: now.toLocaleDateString('en-US'),
                    expiry: now.getTime() + sixtyDaysInMs,
                };
                // merge incoming deal with existing deals
                // dealsDuck: code clean up
                if (isDealsDuckEnabled) {
                    dispatch(dealsDuck.creators.updateCreditAppDeals({
                        creditAppDeals: [deal],
                    }));
                }

                dispatch(paymentsDuck.creators.updateCreditAppDeals({
                    creditAppDeals: [deal],
                }));

            };

            const handler = (event) => {
                /* (expected message object)
                    *
                    * creditAppId: "510000000007654321"
                    * dealRefId: "510000000001234567"
                    * fsDealRefId: undefined
                    * shopper: {
                    *    email: "test@atc.com"
                    *     firstName: "Test"
                    *     lastName: "ATC"
                    *     phone: "5037362985"
                    * }
                    */
                let data;
                if (event?.origin.includes(creditAppBaseUrl)) {
                    try {
                        data = JSON.parse(event.data);
                    } catch {
                        data = event.data;
                    }

                    const dealId = data?.message?.dealRefId;
                    if (dealId && data?.context === 'CONFIRMATION_PAGE') {
                        storeCreditDealInfo(dealId);
                    }
                    if (data?.context === 'EXTERNAL-TRACK') {
                        sendClick('standaloneCreditAppClick', null, { eventData: data, listingId });

                        if (data?.message?.eventName === 'DR_STANDALONE_CREDIT_APP_SUBMITTED') {
                            window.removeEventListener('message', handler);
                        }
                    }
                }
            };
            if (!listenerRef.current) {
                window.addEventListener('message', handler);
                listenerRef.current = true;
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sendClick, creditAppHost, listingId, ownerId, showCreditAppModal, ownerName]);

    // if credit app is invoked from CalculatorContainer, show next modal based on what feature is active
    useEffect(() => {
        if (showFinanceAppModal) {
            setShowCreditAppModal(true);
        }
    }, [setShowCreditAppModal, showFinanceAppModal]);

    useEffect(() => {
        if (query?.launchCreditApp === 'true') {
            setShowCreditAppModal(true);
        }
    }, [setShowCreditAppModal, query]);

    const renderStandaloneCreditAppIframe = () => (
        <>
            <Text className="display-block margin-bottom-2">
                Apply now to compare your available rates and save time at the dealership later.
            </Text>
            <div
                id="standaloneCreditApp"
                style={{
                    position: 'relative',
                    overflow: 'hidden',
                    height: '600px',
                    width: '100%',
                    paddingTop: '75%',
                }}
            >
                <iframe
                    src={`${creditAppHost}${sacaMmdId}?sponsor=${sponsor}`}
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        width: '100%',
                        height: '100%',
                    }}
                    title="Credit Application"
                />
            </div>
        </>
    );

    return (
        <div>
            <SideBarToModal
                title="Get Pre-Qualified"
                collapsed={isXs}
                content={renderStandaloneCreditAppIframe()}
                show={showCreditAppModal}
                sideBarOptions={{
                    fullWidth: true,
                }}
                modalOptions={{
                    scrollPanel: false,
                }}
                onHide={handleHideStandaloneCreditAppContainer}
            />
        </div>
    );
}

export default memo(StandaloneCreditAppContainer);
