import React, { useEffect } from 'react';

import { connect } from 'react-redux';

import { getPixallId } from 'atc-js';

import { useFeatures } from 'reaxl-features';

import StrongAttributesBeacon from '@/fetchers/vdp/StrongAttributesBeacon';

/**
 * Container to call the strong attributes service to trigger calculations for personalization boosting in the
 * relevance model
 */
function CalculateStrongAttributesContainer({
    calculateStrongAttributesHost,
}) {
    const {
        ENABLE_KBB_SA_VEHICLE_HISTORY: [isKbbSAVehicleHistoryEnabled],
        enable_iph_strong_attributes: [isIphEnabled],
    } = useFeatures(['ENABLE_KBB_SA_VEHICLE_HISTORY', 'enable_iph_strong_attributes']);

    const omegaK = isKbbSAVehicleHistoryEnabled ? 1 : 0;
    const source = isIphEnabled ? 'iph' : 'pxa';

    useEffect(() => () => {
        StrongAttributesBeacon(
            calculateStrongAttributesHost,
            {
                visitorid: getPixallId() || 'nl',
                omega_a: 1,
                omega_d: 0,
                omega_k: omegaK,
                source,
            }
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
}

const mapStateToProps = (state) => {
    const {
        configs: {
            calculateStrongAttributesHost,
        },
    } = state;

    return {
        calculateStrongAttributesHost,
    };
};

export default connect(mapStateToProps)(React.memo(CalculateStrongAttributesContainer));
