import genericPostJSON from '@/utilities/genericPostJSON';

export default () => {

    const options = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        circuitBreaker: {
            id: 'inspectionReportToken',
            timeout: 5000,
            resetTimeout: 300000,
            fallback: {},
        },
    };

    const endpoint = '/cars-for-sale/inspectionReportToken/';
    return genericPostJSON(endpoint, options);
};
