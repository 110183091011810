import { fetchJSON } from '@bonnet/next/fetch';

export default (queryString) => {

    const errorObject = {
        error: 'Circuit Breaker for /rest/retailing/incentive is open',
        rates: {},
    };

    const options = {
        circuitBreaker: {
            id: 'paymentIncentives',
            timeout: 7000,
            resetTimeout: 300000,
            fallback: errorObject,
        },
    };

    options.headers = {
        'X-fwd-svc': 'atc',
    };

    const endpoint = '/rest/retailing/incentives?';
    return fetchJSON(endpoint + queryString, options);
};
