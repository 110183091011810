import { fetchJSON } from '@bonnet/next/fetch';

export default (data = {}, id = '') => {

    const errorObject = {
        error: 'Circuit Breaker for /deals is open',
    };

    const options = {
        circuitBreaker: {
            id: 'dealxgSaveDeal',
            timeout: 5000,
            resetTimeout: 30000,
            fallback: errorObject,
            on: {
                timeout: () => {
                    if (window && window.newrelic) {
                        window.newrelic.addPageAction(
                            'ThirdPartyError',
                            {
                                id: data?.carId,
                                component: 'Email',
                                message: 'Circuit Breaker timeout for ExportToDealXgFetcher',
                            }
                        );
                    }
                },
            },
        },
        method: 'POST',
        body: data,
        onError: (error) => {
            if (window && window.newrelic) {
                window.newrelic.addPageAction(
                    'ThirdPartyError',
                    {
                        id: data?.carId,
                        component: 'Email',
                        message: 'Deal callback returned with no deal ID',
                        status: error?.status,
                        statusDescription: error?.statusText,
                    }
                );
            }
        },
    };

    // so we can detect origin from lambda
    options.headers = {
        'X-fwd-svc': 'atc',
        Accept: 'application/json',
    };

    const endpoint = id ? `deals/id/${id}` : 'deals';

    return fetchJSON(`${process.env.BASE_PATH}/${endpoint}`, options);
};
