import React, { memo } from 'react';

import { connect, useSelector } from 'react-redux';

import { useDevice } from '@bonnet/next/device';

import { mapDispatchToActionProp } from '@atc/modular-redux';

import { sendClick } from 'reaxl-analytics';
import { useBrand } from 'reaxl-brand';
import { useFeatures } from 'reaxl-features';
import { KBBDealIndicator } from 'reaxl-molecules';
import { PricingSnapshot } from 'reaxl-organisms';

import getDealDetails from '@/utilities/getDealDetails';
import offsetCalc from '@/utilities/offSetScrollCalculation';
import scrollToElement from '@/utilities/scrollToElement';

import {
    dealsDuck,
    globalConfigsDuck,
    paymentsDuck,
    userDuck,
} from '@/ducks';

import { vdpActiveInteractionDuck,
    vdpResultsDuck,
} from '@/ducks/vdp';

export function PricingSnapshotContainer({
    actions: {
        updateListingPayments = () => { },
        updateMyWallet = () => { },
        setActiveInteractionVdp = () => { },
    },
    emailCTAProps,
}) {

    const {
        accelerate: [, {
            credit_app: enableSDPCreditApp,
        }],
        est_payments: [enablePriceSnapshotRates, {
            makes_excluded_from_show_savings: makesExcludedFromShowSavings,
        }],
        ford_estimated_payment: [enableFordEstimatedPayments],
        standalone_credit_app: [enableStandaloneCreditApp],
        nds_right_rail: [isNDSRightRailEnabled],
        wallet_payment_option: [isWalletPaymentOptionEnabled],
        enable_deals_duck: [isDealsDuckEnabled],
    } = useFeatures([
        'accelerate',
        'est_payments',
        'enable_deals_duck',
        'ford_estimated_payment',
        'standalone_credit_app',
        'nds_right_rail',
        'wallet_payment_option',
    ]);

    const { brands, isBrand } = useBrand();
    const listing = useSelector(vdpResultsDuck.selectors.getVdpActiveInventory) || {};
    const { creditApp, id, owner = {}, pricingDetail = {}, makeCode, paymentServices, vin, zip } = listing;
    const isPrivateSeller = owner?.privateSeller;

    const creditAppHost = useSelector(globalConfigsDuck.selectors.getCreditAppHost);
    const paymentsZip = useSelector((state) => paymentsDuck.selectors.getPencilingPayments(state)?.zip || paymentsDuck.selectors.getPaymentsInfo(state)?.zip);
    //  Override savings display specifically for certain OEMs due to brand requirements
    const showSavings = enablePriceSnapshotRates && !makesExcludedFromShowSavings.includes(makeCode);
    const pencilingPayment = useSelector((state) => paymentsDuck?.selectors?.getLowestPencilingPaymentByListingId(state, id));
    const leasePaymentResponse = useSelector((state) => paymentsDuck?.selectors?.getLowestLeasePaymentByListingId(state, id));
    const paymentEmphasis = useSelector((state) => paymentsDuck?.selectors?.getMyWalletInteraction(state, 'paymentEmphasis'));
    const paymentOption = useSelector(paymentsDuck?.selectors?.getPaymentOption) || 'finance';
    // dealsDuck: code clean up
    const selectorDuck = isDealsDuckEnabled ? dealsDuck : paymentsDuck;
    const existingDeal = useSelector((state) => selectorDuck.selectors.getDealByListingVin(state, vin)) || {};

    // Will cleanup after given the go ahead for full release - Dan Guerra
    const showPaymentsFlag = isBrand(brands.FORD_BRAND) ? enableFordEstimatedPayments && enablePriceSnapshotRates : enablePriceSnapshotRates;

    const dealIndicator = pricingDetail?.dealIndicator ?? false;

    const enableDrDealsPanel = existingDeal?.isAccelerate;
    const device = useDevice();
    const suppressPriceCardCTA = device?.is?.lg && isNDSRightRailEnabled;

    const scrollToPricingSection = () => {
        const offset = offsetCalc(25, 'sticky-header-vdp');
        scrollToElement('pricingSection', offset);
    };

    const handlePriceAdvisorClick = (event) => {
        event.stopPropagation();
        scrollToPricingSection();
    };

    // This is used for both the entire price snapshot (excluding child links)
    // and (if enabled) Build Your Deal links within the payment card
    const handlePriceSnapshotClick = (event, eventData = {}) => {
        event.stopPropagation();
        const {
            listingId: inventoryId,
            isExistingDeal,
        } = eventData;
        const offset = offsetCalc(25, 'sticky-header-vdp');
        scrollToElement('nativeDealContainer', offset);
        sendClick(isExistingDeal ? 'myWalletViewDealStatusCTAClick' : 'pricingOverviewClick', event, { inventoryId });
    };

    const handleKbbFppDetailsClick = (event) => {
        event.stopPropagation();
        scrollToPricingSection();
        sendClick('kbbFppDetailsClick', event);
    };

    // Handles click on monthly payment block, if enabled
    const handleWalletPricingDetailsClick = (event) => {
        event.stopPropagation();

        updateMyWallet({
            showPaymentDetailsModal: true,
            showPencilingPayments: true,
        });
        sendClick('snapshotPaymentDetailsClick', event, {});
    };

    // Handles click on the See Estimated Payment section, if enabled
    const handlePaymentCTAClick = (event) => {
        event.stopPropagation();
        updateMyWallet({
            displayModal: true,
        });
        if (isPrivateSeller) {
            sendClick('psxVDPEstimateYourMonthlyPaymentClick', event, { listingId: id });
        } else {
            sendClick('listingPaymentCTAClick', event, { inventoryId: id });
        }
    };

    const handleThirdPartyCreditAppCTAClick = (event) => {
        event.stopPropagation();
        sendClick('creditAppGetPreQualifiedClick', event, { listingId: id, partner: creditApp?.partner, section: 'price-card' });
    };

    const handleSACACreditAppClick = (event) => {
        event.stopPropagation();
        sendClick('creditAppGetPreQualifiedClick', event, { listingId: id, partner: 'saca', section: 'price-card' });
        setActiveInteractionVdp({ showFinanceAppModal: true });
    };

    const handleViewDealsDashboard = (event) => {
        event.stopPropagation();
        updateMyWallet({
            displayModal: true,
            selectedPanelName: 'activeDeals',
        });
    };

    const hideEmailCTA = !!owner?.privateSeller || owner?.hideEmailForm;
    const isDRAccelerate = paymentServices?.digitalRetailingType === 'accelerate';

    const paymentCardCTAProps = {
        enableSDPCreditApp: enableSDPCreditApp && isDRAccelerate,
        enableSACAPriceCard: enableStandaloneCreditApp && !isDRAccelerate && !!creditAppHost && !!owner?.sacaMmdId,
        handleSACACreditAppClick,
        listing,
        paymentsDuck,
        dealsDuck,
        // Only passthrough to DigitalRetailOverlay
        dealXgReader: getDealDetails,
        hideEmailCTA,
        listedPrice: pricingDetail.salePrice || pricingDetail.msrp,
        user: {
            zip: useSelector(userDuck.selectors.getZip) || zip || paymentsZip || owner?.location?.address?.zip || null,
        },
        pencilingPayment,
        leasePaymentResponse,
        paymentEmphasis,
        existingDeal,
        paymentOption,
    };

    const pricingSnapshotComponent = () => (
        <PricingSnapshot
            creditApp={creditApp ? {
                ...creditApp,
                onCTAClick: handleThirdPartyCreditAppCTAClick,
            } : undefined}
            emailCTAProps={emailCTAProps}
            listing={listing}
            onPriceAdvisorClick={handlePriceAdvisorClick}
            showPayment={showPaymentsFlag}
            showSavings={showSavings}
            onMyWalletClick={enableDrDealsPanel ? handleViewDealsDashboard : handleWalletPricingDetailsClick}
            updateListingPayments={updateListingPayments}
            onPriceSnapshotClick={suppressPriceCardCTA ? undefined : handlePriceSnapshotClick}
            onPaymentCTAClick={handlePaymentCTAClick}
            onKbbFppDetailsClick={handleKbbFppDetailsClick}
            showPaymentCTA={enablePriceSnapshotRates}
            paymentCardCTAProps={paymentCardCTAProps}
            isWalletPaymentOptionEnabled={isWalletPaymentOptionEnabled}
        />
    );

    return (
        <>
            {dealIndicator && (
                <KBBDealIndicator
                    pricingDetail={pricingDetail}
                    flat
                />
            )}
            {pricingSnapshotComponent()}
        </>
    );
}

const mapDispatchToProps = mapDispatchToActionProp({
    updateMyWallet: paymentsDuck.creators.updateMyWallet,
    updateListingPayments: paymentsDuck.creators.updateListingPayments,
    setActiveInteractionVdp: vdpActiveInteractionDuck.creators.setKeys,
});

export default connect(null, mapDispatchToProps)(memo(PricingSnapshotContainer));
