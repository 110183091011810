import React, { memo } from 'react';

import ShowcaseSlot from './ShowcaseSlot';

function AllMediaThumbnailComponent({
    children,
    onClick = () => {},
}) {
    return (
        <div
            onClick={onClick}
            className="thumbnail-overlay"
            role="button"
            tabIndex={0}
        >
            {children}
        </div>
    );
}

export const AllMediaThumbnail = memo(AllMediaThumbnailComponent);

export function ClearThumbnail({
    currentDataSlot,
    isHideable,
}) {
    return (currentDataSlot && currentDataSlot.element) ? (
        <ShowcaseSlot
            hide={isHideable}
            onClick={currentDataSlot.onClick}
            className="secondary-slot"
        >
            {currentDataSlot.element}
            {currentDataSlot.badge
            && (
                <span className="badge">
                    {`${currentDataSlot.badge || ''} (${(currentDataSlot.count)})`}
                </span>
            )}
        </ShowcaseSlot>
    ) : <div />;
}

function OpaqueThumbnailComponent({
    currentDataSlot,
    allMediaTitle,
    onClick = () => {},
    ...rest
}) {
    return currentDataSlot && currentDataSlot.element ? (
        <ShowcaseSlot
            onClick={onClick}
            className="secondary-slot"
            {...rest}
        >
            {currentDataSlot.element}
            <AllMediaThumbnail>
                <p className="margin-0">{`${allMediaTitle}`}</p>
            </AllMediaThumbnail>
        </ShowcaseSlot>
    ) : <div />;
}

export const OpaqueThumbnail = memo(OpaqueThumbnailComponent);
