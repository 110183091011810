import _get from 'lodash/get';

import { BonnetModule } from '@bonnet/next/modules';
import { addBasePath } from '@bonnet/next/pathname';

import { funnels } from '@atc/bonnet-paths';
import { objectDuckCreator } from '@atc/modular-redux';

import { brands } from 'reaxl-brand';

import getPathSRP from '@/utilities/getPathSRP';

import { vdpDuckPreset } from '@/ducks/presets';

// Utilities

const hasPath = (path = '') => path !== '/';

export const buildBackToResultsLink = async (deferredCtx = {}, newSearchParam) => {
    let url;
    try {
        let input = '';

        // referrer url is always relative
        const { referrer } = deferredCtx.query;

        if (referrer) {
            // use the referrer if its present in the query
            input = referrer;

            // as of bonnet/next there are instances where the referrer may have had the basePath removed
            // we must replace it if it has as we are choosing to use the referrer to build the back to results link
            if (!referrer.startsWith(process.env.BASE_PATH)) {
                input = addBasePath(referrer);
            }
        }

        url = new URL(input, 'https://www.autotrader.com/');
    } catch {
        url = new URL('https://www.autotrader.com/');
    }

    // If there is no referrer and the brand is not ford, then we build a link based off of what we think the search may have been
    if (!hasPath(url.pathname) && !(deferredCtx.data.brand === brands.FORD_BRAND)) {
        const query = { ...deferredCtx.query };
        delete query.listingId;
        delete query.lnx;

        const makeCode = query?.makeCode || _get(deferredCtx, 'data.listings[0].makeCode');
        const modelCode = query?.modelCode || _get(deferredCtx, 'data.listings[0].modelCode');

        // srp path has search params
        const srpPath = await getPathSRP({
            makeCode,
            modelCode,
            zip: _get(deferredCtx, 'data.zip'),
            ...query,
        });

        url = new URL(srpPath, 'https://www.autotrader.com/');
    }

    // If the link was built based off of what we think the search may have been, then add the isNewSearch param and listing ID to the query
    if (hasPath(url.pathname)) {
        url.searchParams.set(newSearchParam, false);
        if (deferredCtx.query.listingId) {
            url.hash = deferredCtx.query.listingId;
        }
    }

    // If there was no referrer and the brand is ford, then take the user back to the /cars-for-sale/all path
    if (!hasPath(url.pathname) && deferredCtx.data.brand === brands.FORD_BRAND) {
        url.pathname = '/cars-for-sale/all';
    }

    // remove clickType query parameter
    url.searchParams.delete('clickType');

    return url.pathname + url.search + url.hash;
};

export function backToResultsModuleCtxMiddleware({ duck }) {
    return async (ctx) => {
        const [useLscParams] = ctx.useFeature('use_lsc_params');
        ctx.batchDispatch.createActions(async (deferredCtx) => {

            const { referrer, dealerId } = deferredCtx.query;
            const isBuildAndPriceReferer = (referrer || '')?.startsWith?.(`/${funnels.BUILD_PRICE}`) || false;
            const isFindDealerReferer = (referrer || '')?.startsWith?.(`/${funnels.FIND_DEALER}`) || false;

            let label = 'Back to Results';

            if (dealerId || isFindDealerReferer) {
                label = 'Back To Dealer Results';
            }
            if (isBuildAndPriceReferer) {
                label = 'Back To Custom Order';
            }

            const link = await buildBackToResultsLink(deferredCtx, useLscParams ? 'newSearch' : 'isNewSearch');
            return duck.creators.setKeys({ label, link });
        });
    };
}

const BackToResultsModule = new BonnetModule({
    duck: objectDuckCreator({
        ...vdpDuckPreset,
        store: 'backToResults',
    }),

    ctxMiddleware: backToResultsModuleCtxMiddleware,
});

export default BackToResultsModule;
