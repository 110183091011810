import genericPostJSON from '@/utilities/genericPostJSON';

export default (hostUrl, requestBody) => {
    if (hostUrl) {
        const endpoint = `${hostUrl}`;
        if (navigator && navigator.sendBeacon) {
            navigator.sendBeacon(endpoint, JSON.stringify(requestBody));
        } else {
            genericPostJSON(endpoint, JSON.stringify(requestBody));
        }
    }
};
