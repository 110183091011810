import React, { memo, useMemo } from 'react';

import { useSelector } from 'react-redux';

import _cloneDeep from 'lodash/cloneDeep';

import { LinkIcon, Section } from 'reaxl';
import { sendClick } from 'reaxl-analytics';
import { useFeatures } from 'reaxl-features';

import offsetCalc from '@/utilities/offSetScrollCalculation';
import scrollToElement from '@/utilities/scrollToElement';

import { vdpResultsDuck } from '@/ducks/vdp';

import inspectionReportTokenFetcher from '@/fetchers/InspectionReportTokenFetcher';

import BatteryInfoModal from '@/components/vehicledetails/BatteryInfoModal';
import SpecificationList from '@/components/vehicledetails/SpecificationList';
import WarrantyJumpLink from '@/components/vehicledetails/WarrantyJumpLink/WarrantyJumpLink';

// the purpose of specKeys is to sort the order of specifications
const specKeys = [
    'wellEquipped',
    'mileage',
    'engineDescription',
    'mpg', // only apply to non-electric cars
    'range', // only apply to electric cars
    'evRange', // only apply to hybrid cars
    'evBatteryChargeTime', // only apply to hybrid and electric cars
    'exterior',
    'interiorSeats',
    'transmission',
    'driveType',
    'truckBedLength', // only apply to trucks
    'windowSticker',
    'warranty',
    'inspectionReport', // only for FBA vehicles
];

let gasMileageDisclaimer = 'Miles per gallon (MPG) shown is estimated for city/highway driving and may be combined. Actual mileage will vary.';
const electricDisclaimer = 'Fully electric vehicles rely on battery power and use no gasoline. These require plug-in charging at home or public charging stations.';
const evRangeDisclaimer = 'EV mile range is the distance an electric vehicle can drive on electricity before the battery depletes.';
const hybridDisclaimer = 'Hybrid vehicles rely on both battery power and gasoline. They do not require plug-in charging and will need regular fueling just like a gas engine vehicle.';
const plugInHybridDisclaimer = 'Plug-in hybrid vehicles rely on both battery power and gasoline. They require plug-in charging to use the battery power for driving shorter distances (often around 30 miles), and regular fueling for longer trips. When the battery power depletes, a gas-powered engine takes over.';

const icons = {
    mileage: 'mileage',
    exterior: 'exterior',
    interiorSeats: 'interiorSeats',
    engineDescription: 'engineDescription',
    transmission: 'transmission',
    driveType: 'driveType',
    mpg: 'mpg',
    truckBedLength: 'truckBedLength',
    range: 'range',
    evRange: 'evRange',
    evBatteryChargeTime: 'battery',
    windowSticker: 'windowSticker',
    inspectionReport: 'inspectionChecklist',
    warranty: 'warranty',
    wellEquipped: 'diamond',
};

const addSectionIcon = (specifications, key) => {
    if (specifications?.[key]) {
        specifications[key].icon = icons[key];
    }
};

const handleFuelTypeGroup = (fuelTypeGroup, specifications, engineDescription) => {
    switch (fuelTypeGroup) {
        case 'Electric':
            engineDescription.disclaimer = electricDisclaimer;
            break;
        case 'Hybrid: Gas/Electric':
            engineDescription.disclaimer = hybridDisclaimer;
            engineDescription.value = fuelTypeGroup;
            delete specifications.evRange;
            break;
        case 'Plug-in Hybrid: Gas/Electric':
            engineDescription.disclaimer = plugInHybridDisclaimer;
            engineDescription.value = fuelTypeGroup;
            break;
        default:
            break;
    }
};

function SpecificationsGridComponent({
    bordered = true,
}) {
    const {
        brand: [, { consumer_brand: consumerBrand }],
        inspection_checklist: [isInspectionChecklistEnabled],
        vdp_safeguard: [isSafeguardEnabled],
        custom_mpg_disclaimers: [, { disclaimers }],
    } = useFeatures([
        'brand',
        'inspection_checklist',
        'vdp_safeguard',
        'custom_mpg_disclaimers',
    ]);

    const isKbbBranded = consumerBrand === 'KBB';
    const isAtcBranded = consumerBrand === 'AT';

    const excludesFordBrand = isKbbBranded || isAtcBranded;

    const {
        esntial = {},
        owner: { nameplate = [] } = {},
        mpgCity,
        mpgHighway,
        specifications: initSpecifications = {},
        vin,
        exteriorColorSimple,
        interiorColorSimple,
        windowSticker: { href: windowStickerHref } = {},
        returnPolicy = {},
        moneyBackGuarantee,
        safeGuardProgram,
        fordSalesCode,
        makeCode,
        fuelType,
        fuelTypeGroup = '',
        vinSightData = {},
    } = useSelector(vdpResultsDuck.selectors.getVdpActiveInventory);

    const specifications = useMemo(() => _cloneDeep(initSpecifications), [initSpecifications]);

    let warrantyProducts = {
        returnPolicy: Object.values(returnPolicy).filter(Boolean).length ? returnPolicy : undefined,
        moneyBackGuarantee: moneyBackGuarantee || undefined,
        safeGuardProgram,
    };

    warrantyProducts = Object.values(warrantyProducts).filter(Boolean).length ? warrantyProducts : undefined;

    const updateInspectionChecklistUrl = (async () => {
        const response = await inspectionReportTokenFetcher();
        const token = response?.authorization_token;
        const updatedSalesCode = fordSalesCode ? fordSalesCode.replace(/\D/g, '') : '';
        return `https://dfc.concentrix.com/dfc/inspection-checklist?vin=${vin}&authorization_token=${token}&sales_code=${updatedSalesCode}`;
    });

    // Jump link only enabled if safeguard FF is on (even if other data exist) or listing is Esntial ineligible
    if (!isSafeguardEnabled || Object.values(esntial).filter(Boolean).length) {
        warrantyProducts = null;
    }
    if (warrantyProducts) {
        const handleWarrantyLinkClick = (e) => {
            e.preventDefault();
            e.stopPropagation();
            scrollToElement('purchaseConfidence', offsetCalc(25, 'sticky-header-vdp'));
        };
        specifications.warranty = {
            label: 'Purchase With Confidence',
            value: <WarrantyJumpLink
                {...warrantyProducts}
                onClick={handleWarrantyLinkClick}
            />,
        };
        specifications.warranty.icon = 'warranty';
    }

    // Some dealerships require specific wording for the MPG disclaimer
    if (nameplate.includes(makeCode) && Object.keys(disclaimers).includes(makeCode)) {
        gasMileageDisclaimer = disclaimers[makeCode];
    }
    if (!specifications.range && fuelType !== 'Electric') {
        specifications.mpg = {
            label: 'MPG',
            value: mpgCity && mpgHighway ? mpgCity + ' City / ' + mpgHighway + ' Highway' : '',
            disclaimer: gasMileageDisclaimer,
        };
    }

    if (windowStickerHref) {
        const windowStickerLabel = 'View Original Window Sticker';
        const handleWindowStickerClick = (event) => {
            sendClick('windowStickerClick', event, { co_txt_url: windowStickerLabel });
        };
        specifications.windowSticker = {
            label: 'WINDOW STICKER',
            value: (
                <LinkIcon
                    href={windowStickerHref}
                    glyph="offsite"
                    target="_blank"
                    rel="nofollow noopener"
                    onClick={handleWindowStickerClick}
                >
                    {windowStickerLabel}
                </LinkIcon>
            ),
        };
    }

    // inspection checklist
    if (isInspectionChecklistEnabled) {
        const inspectionReportLabel = 'View Inspection Checklist';

        const handleInspectionReportClick = (event) => {
            sendClick('inspectionChecklistClick', event, { co_txt_url: inspectionReportLabel });

            // This window.open hack is needed because iOS wont allow us to call window.open inside of an async
            // function. So we call the new tab outside of the async function then set the url location once we
            // get the authorization token back from the fetcher.
            const newTab = window.open('about:blank');
            updateInspectionChecklistUrl().then((url) => {
                newTab.location = url;
            });
        };
        specifications.inspectionReport = {
            label: 'INSPECTION REPORT',
            value: (
                <LinkIcon
                    glyph="offsite"
                    onClick={handleInspectionReportClick}
                >
                    {inspectionReportLabel}
                </LinkIcon>
            ),
        };
    }

    if (vinSightData?.equipmentIndex >= 5 && excludesFordBrand) {
        specifications.wellEquipped = {
            label: 'WELL-EQUIPPED VEHICLE',
            value: 'Well-Equipped Vehicle',
            disclaimer: 'This vehicle is better equipped than 50% of  similar vehicles',
        };
    }

    if (vinSightData?.vehicleMileageIndex >= 5 && excludesFordBrand) {
        specifications.mileage = {
            label: 'LOW MILEAGE',
            value: specifications?.mileage?.value,
            icon: 'mileage',
            disclaimer: 'This vehicle has a lower mileage than 50% of similar vehicles',
        };
    }

    if (Object.keys(specifications).length) {
        const {
            engineDescription,
            exterior = {},
            evBatteryChargeTime = {},
            evRange,
            interiorSeats = {},
            range,
            transmission = {},
            truckBedLength = {},
            truckBedSizeDescription = {},
        } = specifications;

        // icon attribute will be used a key to render SpecificationIcon in SpecificationList.js
        Object.keys(specifications).forEach((key) => addSectionIcon(specifications, key));

        handleFuelTypeGroup(fuelTypeGroup, specifications, engineDescription);

        if (evBatteryChargeTime?.value) {
            evBatteryChargeTime.ctaEl = <BatteryInfoModal />;
        }

        // exterior
        if (exterior.value && !exterior.value.includes('Exterior')) {
            exterior.value += ' Exterior';
        }
        // icon and colorValue attributes will render ColorSwatch in SpecificationList.js
        exterior.colorValue = exteriorColorSimple;
        // interiorSeat
        if (interiorSeats.value && !interiorSeats.value.toLowerCase().includes('seat') && !interiorSeats.value.includes('Interior')) {
            interiorSeats.value += ' Interior';
        }
        interiorSeats.colorValue = interiorColorSimple;

        // transmission
        if (transmission.value && !transmission.value.includes('Transmission')) {
            transmission.value += ' Transmission';
        }

        if (evRange?.value) {
            evRange.disclaimer = evRangeDisclaimer;
        }

        if (range?.value) {
            range.disclaimer = evRangeDisclaimer;
        }

        // truckBedLength
        if (Object.keys(truckBedLength).length && Object.keys(truckBedSizeDescription).length) {
            if (truckBedLength.value && !truckBedLength.value.includes('Bed') && truckBedSizeDescription.value) {
                truckBedLength.value += ` Bed Length (${truckBedSizeDescription.value})`;
            }
        }
    }

    return (
        <Section
            alignment="left"
            uiContext="section-2"
        >
            <SpecificationList
                specKeys={specKeys}
                specifications={specifications}
                bordered={bordered}
            />
        </Section>
    );
}

export const SpecificationsGridContainer = memo(SpecificationsGridComponent);

export default SpecificationsGridContainer;
