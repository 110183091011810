import React, { memo } from 'react';

import Sticky from 'react-stickynode';

import PropTypes from 'prop-types';

import { useDevice } from '@bonnet/next/device';

/**
 *  AffixedSidebar creates a scrolling (or fixed, depending on breakpoint)
 *  sidebar on a page
 *
 */
function AffixedSidebar({
    children,
    affixedGreaterThan,
    id,
}) {
    const device = useDevice();
    const shouldAffix = device?.greaterThan?.[affixedGreaterThan] ?? false;

    if (shouldAffix) {
        return (
            <Sticky
                top={24}
                bottomBoundary={`#${id}`}
                innerZ={1}
            >
                {children}
            </Sticky>
        );
    }

    return children;
}

AffixedSidebar.propTypes = {
    /**
     * Largest breakpoint that should not be sticky
     */
    affixedGreaterThan: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
    /**
     * HTML id value for element that is bottom sticky boundary
     */
    id: PropTypes.string.isRequired,
};

export default memo(AffixedSidebar);
