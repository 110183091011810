import { paginationDuckCreator } from 'axl-ducks';

import { vdpDuckPreset } from '@/ducks/presets';

const vdpReviewPaginationDuck = paginationDuckCreator({
    ...vdpDuckPreset,
    store: 'reviewPagination',
    initialState: {
        numRecords: 15,
    },
});

export default vdpReviewPaginationDuck;
