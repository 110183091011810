import React from 'react';

import clsx from 'clsx';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';

import { DisclaimerText } from 'reaxl';
import { CollapsibleListColumns } from 'reaxl-molecules';

import formatBatteryInfo from '@/utilities/formatBatteryInfo';

const valueClassName = (available) => clsx({
    'text-subdued-muted': !available,
    'text-right': true,
    'text-bold': available,
});

const renderValue = (value) => {
    const isAvailable = !_isNil(value);

    return (
        <div className={valueClassName(isAvailable)}>{ isAvailable && value || 'Information Unavailable' }</div>
    );
};

const renderAdditionalInfo = (additionalInfo) => {
    const itemClass = (index) => clsx({ 'padding-bottom-4': index < additionalInfo.length - 1 });

    return (
        <div className="padding-top-3">
            {additionalInfo.map((item, i) => (
                <DisclaimerText
                    className={itemClass(i)}
                    key={item.key}
                >
                    {item.text}
                </DisclaimerText>
            ))}
        </div>
    );
};

function BatterySpecifications({ specs }) {
    const batteryInfo = !_isEmpty(specs) && formatBatteryInfo(specs);

    return batteryInfo && (
        <CollapsibleListColumns
            items={batteryInfo}
            render={({ label, value, additionalInfo }) => (
                <>
                    <div className="display-flex justify-content-between">
                        <div className="padding-right-2">{label}</div>
                        {renderValue(value)}
                    </div>
                    {additionalInfo && renderAdditionalInfo(additionalInfo)}
                </>
            )}
            sm={2}
            md={2}
            lg={2}
            bordered
            condensed
        />
    );
}

export default BatterySpecifications;
