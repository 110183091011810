import _get from 'lodash/get';
/**
 * If current url has Private Seller product, redirect to Marketplace.
 * If that is detected we should redirect on server or client
 * and cancel the rest of the middleware processing
 */
export default function handleMarketPlaceServerRedirect() {
    return async (ctx, next) => {
        const { req, data } = ctx;
        const {
            tred_private_seller: [enableTredPrivateSeller],
        } = ctx.useFeatures([
            'tred_private_seller',
        ]);

        const vin = _get(data, 'base.listings[0].vin');
        const redirectUrl = req?.originalUrl;
        const pathName = redirectUrl?.split('?')[0];
        const isPrivateSeller = _get(data, 'owners[0].privateSeller');

        if (redirectUrl && enableTredPrivateSeller && !!isPrivateSeller) {
            const vdpRedirectUrl = redirectUrl.replace(`${pathName}`, `/marketplace/buy/${vin}`);
            const statusCode = 302;
            if (ctx.res) {

                ctx.res.redirect(statusCode, vdpRedirectUrl);
                ctx.res.end();
            }
            ctx.statusCode = statusCode;
            // fire next to bypass the remaining queue of context middleware
            next('server redirect');
        }
    };
}
