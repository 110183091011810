import React, { useCallback, useState } from 'react';

import { useSelector } from 'react-redux';

import {
    Box,
    BoxGrid,
    Collapsible,
    ExpandTrigger,
    Heading,
    Panel,
    SubHeading,
    Text,
} from 'reaxl';
import { useFeatures } from 'reaxl-features';
import { EsntialBuyNowCTA } from 'reaxl-wallet';

import { fallbackInterestRates } from 'axl-config';

import {
    interestRateDuck,
    paymentsDuck,
} from '@/ducks';

import { vdpResultsDuck } from '@/ducks/vdp';

import dealXgFetcher from '@/fetchers/ExportToDealXgFetcher';

import ConfidenceTile from '@/components/vehicledetails/ConfidenceTile';
import MoneyBackGuaranteeModal from '@/components/vehicledetails/MoneyBackGuaranteeModal';

export function PurchaseWithConfidenceSnapshotContainer() {
    const {
        esntial_confident_purchase: [isEsntialConfidentPurchase],
        esntial_dealxg: [isEsntialDealXgIdEnabled],
    } = useFeatures([
        'esntial_confident_purchase',
        'esntial_dealxg',
    ]);

    const listing = useSelector(vdpResultsDuck.selectors.getVdpActiveInventory) || {};
    const nationalRates = useSelector(interestRateDuck.selectors.getDuckState) || fallbackInterestRates;

    const {
        ecommWebsite,
        esntial,
        returnPolicy: { days: returnDays, miles: returnMiles } = {},
        warranties = {},
    } = listing;

    const [showMoreContent, setShowMoreContent] = useState(false);

    const handleCollapsibleHeaderToggle = useCallback(() => {
        setShowMoreContent((prevState) => !prevState);
    }, []);

    const showMoneyBackGuarantee = returnDays && returnMiles;
    const showLimitedWarranty = !!warranties?.limitedWarranty?.summary;

    if (!isEsntialConfidentPurchase || !esntial || esntial?.deemphasized) {
        return null;
    }

    return (
        <>
            <Panel data-cmp="buyOnlinePanel">
                <Panel.Body>
                    <Heading
                        size={600}
                        color="primary"
                        className="margin-bottom-2 margin-top-1"
                    >
                        Save Time. Buy 100% Online.
                    </Heading>
                    <ConfidenceTile
                        glyph="shopping-cart"
                        header="Seamless Purchase Process"
                        description="Complete in less than 1 hour from the comfort of your home."
                    />
                    <Collapsible
                        onToggle={handleCollapsibleHeaderToggle}
                        toggleFooter={<ExpandTrigger isCollapsed={!showMoreContent} />}
                    >
                        {/* please note a span is being used here intentionally as opposed to a React fragment or div as the two latter produced undesired behavior */}
                        <span>
                            <ConfidenceTile
                                glyph="delivery-truck"
                                header="Convenient Delivery"
                                description="Get your car delivered to you or your nearest dealership."
                            />
                            {showMoneyBackGuarantee && (
                                <ConfidenceTile
                                    glyph="return"
                                    header="Easy Return"
                                    description={`Hassle free return within ${returnDays} days and up to ${returnMiles} miles from the date of delivery. `}
                                    callToAction={<MoneyBackGuaranteeModal />}
                                />
                            )}
                        </span>
                    </Collapsible>
                    <EsntialBuyNowCTA
                        label={ecommWebsite?.label}
                        href={ecommWebsite?.href}
                        analyticsData={{
                            placement: 'dealer-card',
                            listingId: listing?.id,
                        }}
                        bsStyle="primary"
                        className="margin-bottom-2 margin-top-5"
                        paymentsDuck={paymentsDuck}
                        nationalRates={isEsntialDealXgIdEnabled ? nationalRates : undefined}
                        listing={isEsntialDealXgIdEnabled ? listing : undefined}
                        dealXgFetcher={isEsntialDealXgIdEnabled ? dealXgFetcher : undefined}
                        block
                    />
                </Panel.Body>
            </Panel>
            {(showMoneyBackGuarantee || showLimitedWarranty) && (
                <div
                    className="margin-bottom-4"
                    data-cmp="peaceOfMindCards"
                >
                    <SubHeading
                        size={300}
                        componentClass="div"
                    >
                        Enjoy Peace of Mind with Every Online Vehicle Purchase
                    </SubHeading>
                    <BoxGrid
                        className="margin-top-3"
                        includeXs
                    >
                        {showMoneyBackGuarantee && (
                            <Box
                                backgroundColor="gray-lightest"
                                padding="compact"
                                border
                            >
                                <Text
                                    weight="bold"
                                    componentClass="div"
                                >
                                    {`${returnDays}-Day Guarantee`}
                                </Text>
                                <Text
                                    componentClass="div"
                                    className="margin-bottom-1"
                                >
                                    {`Up to ${returnMiles} Miles`}
                                </Text>
                                <MoneyBackGuaranteeModal />
                            </Box>
                        )}
                        {showLimitedWarranty && (
                            <Box
                                backgroundColor="gray-lightest"
                                border
                                padding="compact"
                            >
                                <Text
                                    weight="bold"
                                    componentClass="div"
                                >
                                    {warranties.limitedWarranty?.summary}
                                </Text>
                                <Text>
                                    Limited Warranty
                                </Text>
                            </Box>
                        )}
                    </BoxGrid>
                </div>
            )}
        </>
    );
}

export default PurchaseWithConfidenceSnapshotContainer;
