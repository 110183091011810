import React from 'react';

import { Glyphicon } from 'reaxl';

import MediaGalleryImage from './MediaGalleryImage';

export default function SpinShowcase({
    src = '',
    alt = '',
    mediaType,
    hasFyusion,
    onClick = () => { },
}) {
    //  TODO: can this now use PositionedOverlay, since Argo is no longer being used?
    return (
        <div
            className="positioned-overlay"
            style={{
                width: '100%',
                display: 'flex',
                position: 'relative',
            }}
            onClick={onClick}
            role="button"
            tabIndex={0}
        >
            <MediaGalleryImage
                alt={alt}
                src={src}
                type="main"
                mediaType={mediaType}
                lazyImage={false}
                hasFyusion={hasFyusion}
                loading="eager"
            />
            {/* TODO: what component should this be using? */}
            <div
                className="badge margin-bottom-4 padding-horizontal-3 padding-vertical-2 display-flex positioned-overlay-anchor bottomCenter media-360spin"
                title="Take a Tour"
            >
                <Glyphicon
                    glyph="degree-rotate"
                    className="text-size-400 margin-right-2 cursor-pointer"
                />
                {' '}
                <span
                    className="text-size-300"
                >
                    Take a Tour
                </span>
            </div>
        </div>
    );
}
