import { fetchJSON } from '@bonnet/next/fetch';

export default (
    kbbVehicleId,
    {
        target = 'consumerReview',
        ...rest
    },
) => fetchJSON(`/cars-for-sale/kbbresearch/${target === 'expertReview' ? 'content/expert-review' : 'consumer-reviews'}/vehicleId/${kbbVehicleId}`, {
    ...rest,
    circuitBreaker: {
        id: 'kbbresearch_reviews',
        timeout: 10000,
        resetTimeout: 30000,
        fallback: {
            results: false,
        },
    },
}).catch((error) => {
    // eslint-disable-next-line no-console
    console.log(`Failed to fetch ${target} from KBB Research endpoint.`, error);
});
