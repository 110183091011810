import React, { useCallback, useEffect, useState } from 'react';

import { useInView } from 'react-intersection-observer';
import { useDispatch, useSelector } from 'react-redux';

import { useDevice } from '@bonnet/next/device';

import {
    Button,
    Section,
    Text,
} from 'reaxl';
import { sendClick } from 'reaxl-analytics';
import { useFeatures } from 'reaxl-features';
import {
    ModelInformation,
} from 'reaxl-organisms';

import {
    vdpModelInfoDuck,
    vdpResultsDuck,
} from '@/ducks/vdp';

import BatterySpecifications from '@/components/vehicledetails/BatterySpecifications';

export function ModelInfoContainer() {
    const {
        combined_specs_features: [isCombinedSpecsEnabled],
    } = useFeatures([
        'combined_specs_features',
    ]);
    const device = useDevice();
    const isXS = device?.is?.xs;
    const [showExpansion, setShowExpansion] = useState(false);
    const [ref, inView] = useInView({
        rootMargin: '300px 0px',
        triggerOnce: true,
    });

    const dispatch = useDispatch();
    const listing = useSelector(vdpResultsDuck.selectors.getVdpActiveInventory);
    const modelInfo = useSelector(vdpModelInfoDuck.selectors.getDuckState);
    const { styleId, year, make, model, electricComponentInfo = {} } = listing;

    useEffect(() => {
        dispatch(vdpModelInfoDuck.creators.loadModelInfo({ styleId }));
    }, [styleId, dispatch]);

    const customFeatures = Object.keys(electricComponentInfo).length > 0 ? { Battery: <BatterySpecifications specs={electricComponentInfo} /> } : undefined;

    // sorts the order of features
    const featuresAndSpecsKeys = [
        'Specifications',
        'Battery',
        'Safety Ratings',
        'Accident Prevention',
        'Brakes',
        'Comfort',
        'Convenience',
        'Entertainment',
        'Package',
        'Seat',
        'Towing',
        'Wheel',
    ];

    const toggleExpansion = useCallback((event) => {
        if (!showExpansion) {
            sendClick('inventoryViewSpecificationsClick', event, {});
        }
        setShowExpansion(!showExpansion);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showExpansion]);

    if (modelInfo && Object.keys(modelInfo).length) {
        const { title, features } = modelInfo;
        const heading = `${year} ${make} ${model}`.replace(/\s+/g, ' ').trim();
        const textProps = isCombinedSpecsEnabled ? { className: 'text-bold' } : {};
        return (
            <Section
                data-cmp="modelInfoContainer"
                alignment="left"
                uiContext="section-2"
                className="margin-bottom-4 margin-bottom-md-5"
            >
                <Text {...textProps}>
                    {`Looking for ${heading} model information? `}
                    <Button
                        className="text-normal"
                        onClick={toggleExpansion}
                        bsStyle="text"
                    >
                        View all features and specifications
                    </Button>
                </Text>
                <ModelInformation
                    title={title}
                    features={features}
                    show={showExpansion}
                    onHide={toggleExpansion}
                    collapsed={isXS}
                    customFeatures={customFeatures}
                    customOrder={featuresAndSpecsKeys}
                />
            </Section>
        );
    }

    let height = 216.5;
    if (device?.lessThan?.md) {
        height = 242.5;
    }
    // placeholder for lazyloaded height
    // once ref is detected hide placeholder
    return !inView && (
        <div
            style={{ height }}
            ref={ref}
        />
    );
}

export default ModelInfoContainer;
