import React, { memo } from 'react';

import _isEqual from 'lodash/isEqual';

import {
    Carousel,
} from 'reaxl';

import ShowcaseSlot from './ShowcaseSlot';
import { mainImageSizes } from './sizes';
import ThumbnailBox from './ThumbnailBox';

const getThumbnails = ({
    data = [],
    showAllMedia,
    isMobile,
    maxLimitThree,
}) => {
    const maxThumbnails = () => {
        if (maxLimitThree) {
            return showAllMedia ? 2 : 3;
        }
        return showAllMedia ? 5 : 6;
    };

    const primarySlotCategory = data?.[0]?.category;

    // first image is used as primary, get the rest to build thumbnails
    const thumbnailData = data.slice(1);

    if (thumbnailData.length === 0) {
        return [];
    }

    const dataByCategory = thumbnailData.reduce((acc, item = {}) => {
        const { category } = item;

        acc[category] = [].concat(acc[category] || []).concat({ ...item });
        acc.count = (acc.count || 0) + 1;

        return acc;
    }, {});

    const categoryList = Object.keys(dataByCategory);
    const thumbnailSize = thumbnailData.length <= maxThumbnails() ? thumbnailData.length : maxThumbnails();

    const thumbnailsByCategory = {};
    let categoryInd = 0;
    let count = 0;

    while (count < thumbnailSize && count <= dataByCategory.count) {
        const category = categoryList[categoryInd];
        const categoryLength = dataByCategory[category].length;

        // pull the next available item
        const itemToAdd = Array.isArray(dataByCategory[category]) && dataByCategory[category].splice(0, 1)?.[0];

        if (itemToAdd) {
            if (thumbnailsByCategory[category]) {
                thumbnailsByCategory[category].push({ ...itemToAdd });
            } else {
                thumbnailsByCategory[category] = [{
                    badge: itemToAdd?.isAd ? undefined : category,
                    count: categoryLength + (primarySlotCategory === category ? 1 : 0),
                    ...itemToAdd,
                }];
            }

            count += 1;
        }
        if (categoryInd < categoryList.length - 1) {
            categoryInd += 1;
        } else {
            categoryInd = 0;
        }
    }

    const thumbnails = Object.values(thumbnailsByCategory).reduce((acc, d) => [...acc, ...d], []);

    if (showAllMedia && thumbnails.length > 0) {
        if (!isMobile && thumbnails.length === 3) {
            // add showAllMedia button as a 3rd element so the button shows up in the first column
            return [
                ...thumbnails.slice(0, 2),
                showAllMedia,
                ...thumbnails.slice(2),
            ];
        }
        thumbnails.push(showAllMedia);
    }

    return thumbnails;

};

const getThumbnailTiles = ({
    data = [],
    isMobile,
    isColumn,
    isReversable,
}) => data.reduce((acc, _, i) => {
    /**
     * For mobile view:
     *  add 2 thumbnails to each row
     * For md+ view:
     *  if there are less than 5 (1~4) thumbnails, each chunk will have 3 items and flex-direction will be set to "column"
     *  if there are 5 or 6 thumbnails, each chunk will have 2 items and flex-direction will be "row"
    */
    const numOfItems = !isMobile && isColumn ? 3 : 2;

    if (i % numOfItems === 0) {
        const currentDataSlot = [...data.slice(i, i + numOfItems)];
        const hasAllMedia = currentDataSlot.findIndex(({ allMediaTitle } = {}) => !!allMediaTitle) > -1;

        acc.push(
            <ThumbnailBox
                key={'msThumbnailBox' + acc.length}
                currentDataSlot={currentDataSlot}
                isMobile={isMobile}
                isColumn={isColumn}
                column={acc.length}
                // flip the order of items that have showAllMedia button
                // when the first column shows up
                reverseOrder={hasAllMedia && isReversable}
            />
        );
    }

    return acc;
}, []);

const MediaShowcase = memo(({
    mediaDataSets = [],
    isMobile = true,
    isDesktop = false,
    allMediaTitle = 'View All Media',
    onAllMediaClick = () => {},
    showAllMediaButton = false,
    showAllMediaImage,
    maxLimitThree = false,
    mediaType,
    ...rest
}) => {
    const primarySlot = mediaDataSets?.[0] || {};
    const primarySlotElement = primarySlot?.element;

    const thumbnails = getThumbnails({
        data: mediaDataSets,
        showAllMedia: showAllMediaButton && {
            element: showAllMediaImage || primarySlotElement,
            allMediaTitle,
            onAllMediaClick,
        },
        isMobile,
        maxLimitThree,
    });

    const thumbnailLength = thumbnails.length;
    const isTilesColumn = !isMobile && thumbnailLength < 5;

    const chunks = getThumbnailTiles({
    // fill empty slots for thumbnails
        data: isMobile ? thumbnails : thumbnails.concat(new Array((maxLimitThree ? 3 : 6) - thumbnailLength).fill({})),
        isMobile,
        isColumn: isTilesColumn,
        isReversable: showAllMediaButton && thumbnailLength === 6,
    });

    return mediaDataSets?.length > 0 ? (
        <Carousel
            data-cmp="mediaShowcase"
            style={{ height: mainImageSizes[mediaType]?.height }}
            draggable={!isDesktop && thumbnails.length}
            prevNextButtons={false}
            initialIndex={0}
            fullWidth={false}
            className={!thumbnails.length && 'single-slot'}
        >

            {primarySlotElement && (
                <ShowcaseSlot
                    onClick={primarySlot.onClick}
                    className="main-slot"
                >
                    {primarySlotElement}
                </ShowcaseSlot>
            )}

            {isMobile || isTilesColumn ? chunks : <div>{chunks}</div>}
        </Carousel>
    ) : null;
}, (oldProps, newProps) => _isEqual(newProps.mediaDataSets, oldProps.mediaDataSets)
&& _isEqual(newProps.onAllMediaClick, oldProps.onAllMediaClick)
&& _isEqual(newProps.showAllMediaImage, oldProps.showAllMediaImage));

MediaShowcase.displayName = 'MediaShowcase';

export default MediaShowcase;
