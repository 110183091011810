import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { esntialEligibilityFetcher } from 'reaxl-ecommerce';

import { inventoryDuck, paymentsDuck } from '@/ducks';

import { vdpResultsDuck } from '@/ducks/vdp';

/**
 * Manages check for Esntial eligibility on page load
 * Works with middlware to reinstate ecomm links if a
 * vehicle is configured for Esntial and is eligible,
 * or removes Esntial object if the vehicle isn't eligible.
 *
 * @returns null
 */
function EsntialEligibilityContainer() {
    const listing = useSelector(vdpResultsDuck.selectors.getVdpActiveInventory);

    const dispatch = useDispatch();

    const {
        esntial,
        id,
        vin,
    } = listing;

    const existingDeal = useSelector((state) => paymentsDuck?.selectors?.getDealByListingVin(state, vin)) || {};
    const disableEsntial = () => dispatch(inventoryDuck.creators.updateInventoryItem(id, {
        esntial: undefined,
        ecommWebsite: undefined,
    }));

    useEffect(() => {
        if (existingDeal?.isEcommerce) {
            disableEsntial();
        } else if (esntial?.ecommWebsite) {
            esntialEligibilityFetcher(esntial.eligibilityService, vin).then((response) => {
                if (response?.totalMatched === 0) {
                    // Disable esntial
                    disableEsntial();
                    let message = 'Vehicle ineligible for Esntial';
                    if (response?.circuitBreakerOpen) {
                        message = 'Esntial Eligibility Circuit Breaker Open; displaying as vehicle ineligible for Esntial';
                    }
                    // eslint-disable-next-line no-console
                    console.log(message, vin);
                    if (window && window.newrelic) {
                        window.newrelic.addPageAction('ThirdPartyError', {
                            id,
                            vin,
                            component: 'EsntialEligibiltyContainer',
                            message,
                        });
                    }
                } else {
                    // Enable ecommWebsite and dealerPreferredCTA to show
                    // Esntial entry points
                    dispatch(inventoryDuck.creators.updateInventoryItem(id, {
                        ecommWebsite: esntial.ecommWebsite,
                        owner: {
                            ...listing.owner,
                            dealerPreferredCTA: esntial.deemphasized ? undefined : 'WEBSITE',
                        },
                    }));
                    if (window && window.newrelic) {
                        window.newrelic.addPageAction('ThirdPartyAction', {
                            id,
                            vin,
                            component: 'EsntialEligibiltyContainer',
                            message: 'Vehicle eligible for Esntial',
                        });
                    }
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vin, existingDeal.isEcommerce]);

    return null;
}

export default EsntialEligibilityContainer;
