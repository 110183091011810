// TODO: this can be handled with owner website props and we can remove this after refactor
// return statement tells component to block native link functionality
const OwnerWebsiteHandler = (({ ecommHref, openInSameTab = false }) => () => {
    if (ecommHref) {
        window.open(ecommHref, openInSameTab ? '_self' : '_blank', 'noopener');
        return true;
    }
    return false;
});

export default OwnerWebsiteHandler;
