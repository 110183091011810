export default function getFilteredVHRTiles(productTiles, partner, vin, isOverrideCarfaxTileEnabled) {
    const whiteLabelCarfaxTile = {
        clickable: true,
        epn: 'carfaxVHR',
        link: {
            href: `https://www.carfax.com/VehicleHistory/p/Report.cfx?partner=${partner}&vin=${vin}`,
            offsite: 'true',
            partner: true,
        },
        tileType: 'CARFAX_APPENDED',
        visible: true,
        image: {
            src: '/content/static/img/vhr/carfax/regular-own.svg',
        },
    };
    // VHR Variables
    const filteredVHRTiles = productTiles?.filter((tile) => !isOverrideCarfaxTileEnabled && tile.tileType.startsWith('CARFAX') || tile.tileType.startsWith('EXPERIAN'));
    if (isOverrideCarfaxTileEnabled && filteredVHRTiles) {
        filteredVHRTiles.push(whiteLabelCarfaxTile);
    }

    return filteredVHRTiles;
}
