import React, { memo } from 'react';

import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import _isEqual from 'lodash/isEqual';

import { useDevice } from '@bonnet/next/device';

import { Text } from 'reaxl';
import { EmailCTA } from 'reaxl-email';
import { useFeatures } from 'reaxl-features';
import { OwnerWebsiteCTA } from 'reaxl-molecules';

import OwnerWebsiteHandler from '@/utilities/OwnerWebsiteHandler';

import {
    paymentsDuck,
} from '@/ducks';

import {
    vdpResultsDuck,
} from '@/ducks/vdp';

function PrimaryVdpCallsToAction({
    emailCTAProps,
}) {

    const device = useDevice();
    const isXs = device?.is?.xs ?? false;

    const {
        thirdparty_prequal: [, {
            enable_email_at_xs: isXsEmailCtaEnabled,
        }],
        esntial_confident_purchase: [isEsntialConfidentPurchase],
    } = useFeatures([
        'thirdparty_prequal',
        'esntial_confident_purchase',
    ]);

    const listing = useSelector(vdpResultsDuck.selectors.getVdpActiveInventory);
    const {
        esntial,
        id: listingId,
        owner = {},
        ecommWebsite = {},
        vin,
    } = listing;

    const { hideEmailForm, dealerPreferredCTA, website = {}, id: ownerId, privateSeller: isPrivateSeller } = owner;
    const existingDeal = useSelector((state) => paymentsDuck?.selectors?.getDealByListingVin(state, vin)) || {};

    const showEcommCTA = Object.keys(ecommWebsite).length > 1;
    const { label = 'Visit Dealer Website', href = '' } = showEcommCTA ? ecommWebsite : website;
    const ecommHrefWithWalletData = useSelector((state) => vdpResultsDuck.selectors.getEcommHrefWithWalletData(state), _isEqual);
    const ecommLink = !!ecommWebsite?.href;
    const showWebsiteButton = !(isEsntialConfidentPurchase && esntial) && dealerPreferredCTA === 'WEBSITE' && !!href;
    const analyticsData = {
        inventoryId: listingId,
        ownerId,
        placement: '',
        ecommLink,
        ctaLocation: 'primary-vdp-cta',
        label,
    };

    if (showWebsiteButton && !existingDeal?.isEcommerce) {
        return (
            <>
                {!!esntial && (
                    <Text
                        className="padding-top-1 padding-bottom-3"
                        weight="bold"
                        componentClass="div"
                    >
                        Buy this car from the comfort of your own home including stress-free pick up or delivery!
                    </Text>
                )}
                <OwnerWebsiteCTA
                    href={href}
                    onClick={OwnerWebsiteHandler({ ecommHref: ecommHrefWithWalletData, openInSameTab: !!esntial })}
                    uiContext="block"
                    label={label}
                    hideIcon={esntial && showEcommCTA}
                    analyticsData={analyticsData}
                    className="margin-bottom-3"
                />
            </>
        );
    }

    if (Object.keys(emailCTAProps).length && (isXsEmailCtaEnabled && isXs) && !(isPrivateSeller || hideEmailForm)) {
        return (
            <EmailCTA
                {...emailCTAProps}
                className="margin-bottom-5"
                type="block"
                context="confirmAvailability"
                parentId="price-card"
            />
        );
    }

    return null;
}

PrimaryVdpCallsToAction.propTypes = {
    /**
     * the properties for EmailCTA
     */
    emailCTAProps: PropTypes.object,

};

export const PrimaryVdpCallsToActionContainer = memo(PrimaryVdpCallsToAction);

export default PrimaryVdpCallsToActionContainer;
