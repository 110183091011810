import React, {
    useCallback,
    useEffect,
} from 'react';

import { connect, useSelector } from 'react-redux';

import { mapDispatchToActionProp } from '@atc/modular-redux';

import { sendClick } from 'reaxl-analytics';
import { useFeatures } from 'reaxl-features';
import {
    useOptimizelyTrack,
} from 'reaxl-optimizely';

import {
    query,
    removeParameters,
} from '@/utilities/QueryStringUtil';
import { saveVDPListing as saveVDPListingAction } from '@/utilities/saveListing';
import saveRecentInventory from '@/utilities/saveRecentInventory';
import sendAlertClickHandler from '@/utilities/sendAlertClickHandler';

import {
    paymentsDuck,
    savedInventoryDuck,
} from '@/ducks';

import { vdpResultsDuck } from '@/ducks/vdp';

// Utilities

import HeaderContentContainer from '@/containers/HeaderContentContainer';

let saveListingParam = query().saveListing;
removeParameters('saveListing');

export function VDPHeaderContainer({
    actions,
    isSaved,
    listing = {},
}) {
    const optimizelyTrack = useOptimizelyTrack();
    const hasUpdatedWallet = useSelector((state) => paymentsDuck.selectors.getMyWalletInteraction(state, 'hasUpdatedWallet'));

    const { vehicle_header_content: [enableHeaderContent] } = useFeatures(['vehicle_header_content']);

    useEffect(() => {
        if (listing.id) {
            actions.saveRecentInventory(listing.id);
        }
    }, [actions, listing.id]);

    useEffect(() => {
        if (listing.accelerate && hasUpdatedWallet) {
            optimizelyTrack('total_value', { value: 0.14 });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listing.accelerate, hasUpdatedWallet]);

    const autoSaveListing = () => {
        if (saveListingParam && !isSaved) {
            actions.saveVDPListing({
                event: {},
                listing,
                wasSaved: false,
                sendClick,
            });
            saveListingParam = false;
        }
    };

    return (
        <HeaderContentContainer
            onLoggedInStateReady={autoSaveListing}
            showHeaderContent={enableHeaderContent}
            saveListing={actions.saveVDPListing}
            sendAlertClickHandler={useCallback(sendAlertClickHandler, [])}
        />
    );
}

const mapStateToProps = (state) => {
    const listing = vdpResultsDuck.selectors.getVdpActiveInventory(state);
    const savedListingIds = savedInventoryDuck.selectors.getSavedInventoryIds(state) || [];

    return {
        listing,
        isSaved: savedListingIds.includes(listing.id),
    };
};

const mapDispatchToProps = mapDispatchToActionProp({
    saveVDPListing: saveVDPListingAction,
    saveRecentInventory,
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(VDPHeaderContainer));
