import React from 'react';

import { ClientOnly } from '@atc/react-client-only';

import { useFeatures } from 'reaxl-features';

import BrowserNotificationContainer from '@/containers/BrowserNotificationContainer';

function VdpAlertsContainer() {

    const {
        offsite_browser_notifications: [, { vdp_prompt_all: vdpPromptAll, vdp_prompt_2_views: vdpPrompt2Views }],
    } = useFeatures([
        'offsite_browser_notifications',
    ]);

    return (
        <ClientOnly>
            {(vdpPromptAll || vdpPrompt2Views) && (<BrowserNotificationContainer location="vdp" />)}
        </ClientOnly>
    );
}

export default VdpAlertsContainer;
