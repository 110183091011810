import { fetchJSON } from '@bonnet/next/fetch';

export default (data = {}) => fetchJSON('/cars-for-sale/api/persistDealData', {
    body: data,
    method: 'POST',
    circuitBreaker: {
        id: 'persistdealdata',
        timeout: 2000,
        resetTimeout: 30000,
        fallback: {},
    },
});
