import _get from 'lodash/get';

import { getAvailableOwnerTimes, getOwnerWebsite } from '@/utilities';

import {
    addPsxValuePropImages,
    obfuscateCarfaxHref,
    replaceImageServerInUrl,
    setPhoneVisible,
    translateListingPriority,
} from '@/utilities/dispatchUtils';
import getFilteredVHRTiles from '@/utilities/getFilteredVHRTiles';

import {
    expiredListingDuck,
    globalConfigsDuck,
    inventoryDuck,
    ownersDuck,
} from '@/ducks';

import {
    vdpActiveEmailListingDuck,
    vdpAdsDuck,
    vdpContentDuck,
    vdpResultsDuck,
    vdpVideoDuck,
} from '@/ducks/vdp';

import buildSpecifications from './buildSpecifications';

export default function dispatchVehicleDetailsPageData() {
    return async (ctx) => {

        const state = ctx.store.getState();
        const isKbb = globalConfigsDuck.selectors.isKbb(state);
        const { creditApp = {} } = vdpResultsDuck.selectors.getVdpActiveInventory(state) || {};

        // Create batched action queue
        ctx.batchDispatch.createActions((deferredCtx) => {

            const {
                pageData = {},
            } = deferredCtx.data;

            // account for lsc or cs response
            const listing = deferredCtx?.data?.listings?.[0] || deferredCtx?.data;
            const {
                images,
                paymentServices: {
                    digitalRetailingType,
                } = {},
                vin,
                esntial,
            } = listing;
            const owner = deferredCtx?.data?.owners?.[0] || deferredCtx?.data?.owner || {};
            listing.ownerName = owner?.name;

            const obfuscatedProductTiles = obfuscateCarfaxHref(listing);

            listing.productTiles = obfuscatedProductTiles;

            let { ecommWebsite = {} } = listing;
            const hasEcommLink = Object.keys(ecommWebsite).length > 0;

            // Feature Flags
            const {
                brand: [, { inventory_image_url: inventoryImageUrl, alwaysShowOwnerPhone }],
                dealer_website_link: [transformDealerWebsiteLink, dealerWebsiteConfig],
                override_carfax_tile: [isOverrideCarfaxTileEnabled, { partner }],
                safeguard: [isSafeGuardEnabled],
                standard_listing_phone_display: [displayPhoneNumber],
                vdp_safeguard: [isSafeGuardEnabledOptimizely],
            } = ctx.useFeatures([
                'brand',
                'dealer_website_link',
                'override_carfax_tile',
                'safeguard',
                'standard_listing_phone_display',
                'vdp_safeguard',
            ]);

            listing.vhrTiles = getFilteredVHRTiles(obfuscatedProductTiles, partner, vin, isOverrideCarfaxTileEnabled);

            // Configure PSX listings
            if (owner.privateSeller) {
                listing.dealerConfig = {
                    ...listing.dealerConfig,
                    hideAds: true,
                };
                // PSX TODO: Compare is overriding this by not processing inventory pull through this middleware
                owner.hideEmailForm = true;
                addPsxValuePropImages(images);

                // Add Why to use PSX video
                if (!listing.videos) {
                    listing.videos = [];
                }

                listing.videos.push({
                    image: '/content/static/img/psx/explainer/meant_to_be_hero_v4.3.frame1-q5.jpg',
                    src: '/content/static/video/psx/explainer/meant_to_be_hero_v4.3.mp4',
                    thumbnail: '/content/static/img/psx/psx_why_to_use.thumbnail.jpg',
                    videoId: 'psxHowItWorks',
                });
            }

            // Replace image server in source image URl
            const imageSrc = _get(images, 'sources', null);
            if (inventoryImageUrl && imageSrc) {
                Object.values(imageSrc).map((image) => {
                    replaceImageServerInUrl(image, 'src', inventoryImageUrl);
                });
            }

            // Check for safeguard feature and override if it's an unsupported value
            if (!isSafeGuardEnabledOptimizely || !isSafeGuardEnabled || !['30', '60', '90'].includes(listing?.safeGuardProgram)) {
                delete listing.safeGuardProgram;
            }

            // Bypass phone number rules for standard listings in some experiences
            setPhoneVisible(owner, displayPhoneNumber, alwaysShowOwnerPhone);

            if (transformDealerWebsiteLink && owner?.website) {
                owner.website = getOwnerWebsite(owner.website, dealerWebsiteConfig);
            }

            if (transformDealerWebsiteLink && hasEcommLink) {
                ecommWebsite = getOwnerWebsite(ecommWebsite, dealerWebsiteConfig);
            }

            if (hasEcommLink) {
                owner.dealerPreferredCTA = 'WEBSITE';
            }

            // Override ecommWebsite label and href for eligible Esntial vehicles
            if (hasEcommLink && esntial) {
                const fallBackLabel = digitalRetailingType === 'accelerate' ? 'Start Purchase' : 'Continue Online';
                ecommWebsite.label = ecommWebsite.label || fallBackLabel;
                ecommWebsite.href = `${ecommWebsite.href}&utm_content=${ecommWebsite.label.replace(/\s/g, '_').toLowerCase()}_button`;
                listing.esntial.helperText = 'Buy this car from the comfort of your own home including stress-free pick up or delivery!';
            }

            // Esntial Eligibility
            if (esntial) {
                listing.esntial.ecommWebsite = ecommWebsite;
                listing.ecommWebsite = undefined;
                owner.dealerPreferredCTA = undefined;
            } else {
                listing.ecommWebsite = ecommWebsite;
            }

            if (esntial?.deemphasized && ecommWebsite.href) {
                owner.dealerPreferredCTA = undefined;
            } else if (esntial) {
                delete listing.esntial.deemphasized;
            }

            // Add scheduling properties to owner
            if (owner.hours) {
                try {
                    const ownerTimes = getAvailableOwnerTimes(owner.hours);
                    owner.availableTimes = ownerTimes?.availableTimes;
                    owner.disabledDaysOfWeek = ownerTimes?.disabledDaysOfWeek;
                    owner.nextOpenDate = ownerTimes?.nextOpenDate;

                    // If bad data, disable lead scheduling
                    if (owner.disabledDaysOfWeek.length === 7
                        || Object.keys(owner.availableTimes)
                            .filter((key) => owner.availableTimes[key].length).length === 0) {
                        listing.leadScheduling = false;
                    }
                } catch (e) {
                    listing.leadScheduling = false;
                }
            } else {
                listing.leadScheduling = false;
            }

            const listingId = listing?.id || deferredCtx.query.listingId;

            if (!listing.hasDealerDiff && owner.dealerDiff) {
                listing.hasDealerDiff = true;
            }

            if (!listing.hasPlaylist && listing.videos) {
                listing.hasPlaylist = listing.videos.some((video) => video.src.includes('xspf'));
            }

            if (Number.isInteger(parseInt(listing?.priority, 10))) {
                translateListingPriority(listing);
            }

            listing.similarInventoryConquest = listing.priority === 'FREE' || listing.priority === '5' || listing.owner?.privateSeller;

            if (deferredCtx.query?.clickType && !listing.clickType) {
                listing.clickType = deferredCtx.query.clickType;
            }

            listing.specifications = buildSpecifications(listing);

            const activeInventory = {
                ...listing,
                owner,
            };

            if (Object.keys(creditApp).length) { // adding psx credit app srp to vdp navigation
                activeInventory.creditApp = creditApp;
            }

            return [
                vdpContentDuck.creators.assign({
                    metaDescription: pageData.metaDescription,
                    metaKeywords: pageData.metaKeywords || '',
                    metaRobots: pageData.metaRobots,
                    windowTitle: pageData.windowTitle || pageData.title,
                    openGraphDescription: pageData.openGraphDescription,
                    openGraphImage: pageData.openGraphImage,
                    openGraphSiteName: pageData.openGraphSiteName,
                    openGraphTitle: pageData.windowTitle || pageData.title,
                    openGraphType: pageData.openGraphType,
                    openGraphUrl: pageData.openGraphUrl,
                }),

                // set the vdp ad paramters
                vdpAdsDuck.creators.setKeys(_get(pageData, 'ad', {})),

                // Add owner to listings collection
                ownersDuck.creators.addOwners({ [owner?.id]: owner }),

                // Update the inventory collection
                inventoryDuck.creators.addVdpInventory(activeInventory, isKbb),

                // Update the video collection
                vdpVideoDuck.creators.setActiveResults(_get(listing, 'videos', [])),

                // emailOwner
                vdpActiveEmailListingDuck.creators.setActiveResults([listingId]),

                // Map the order to display results
                vdpResultsDuck.creators.setActiveResults([listingId]),

                // Update listing fetching status if VDP endpoint fails
                vdpResultsDuck.creators.setFetchError(!listing.id),

                expiredListingDuck.creators.setKeys(listing?.expiredListing),
            ];

        });
        // dispatch the batched action queue
        await ctx.batchDispatch.dispatchActions(ctx);
    };
}
