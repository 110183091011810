import _get from 'lodash/get';

import {
    fetchCompositeJSON,
    fetchJSON,
} from '@bonnet/next/fetch';

import cleanServerRequest from '@/utilities/cleanServerRequest';

export default async function fetchVehicleDetailsPage(options = {}) {
    if (typeof window === 'undefined') {
        options = await cleanServerRequest(options);
        options.base = process.env.LSC_HOST;
    }

    const {
        atCarId,
        listingId,
        vin,
    } = options.query;

    let serverData;

    const listingIdQuery = listingId || parseInt(atCarId, 16);
    const fetchUrl = vin ? `/rest/lsc/listing/vin/${vin}` : `/rest/lsc/listing/id/${listingIdQuery}`;
    // We need either a VIN or a valid listingId from either listingId or atCarId
    if (vin || !Number.isNaN(listingIdQuery)) {
        serverData = await fetchCompositeJSON({
            base: fetchJSON(fetchUrl, {
                circuitBreaker: {
                    id: 'vehicleDetails',
                    timeout: 10000,
                    resetTimeout: 30000,
                },
                ...options,
            }),
        });
    } else {
        serverData = {};
    }

    return {
        ...serverData,
        ..._get(serverData, 'base'),
    };

}
