import React from 'react';

import PropTypes from 'prop-types';

import clsx from 'clsx';

import {
    ColorSwatch,
    Icon,
    ListColumns,
} from 'reaxl';
import { InfoIcon } from 'reaxl-molecules';

/**
 *  Creates label layout for a specification with optional tooltip
 */
function RenderDisclaimer(disclaimer) {
    return (
        <InfoIcon
            infoText={disclaimer}
            className="margin-left-1"
            placement="bottom"
        />
    );
}

// key is icon attribute from specifications object
function SpecificationIcon(key, { value = '', label, colorValue }) {
    let icon;
    switch (key) {
        case 'transmission':
            if (value.toLowerCase().includes('manual')) {
                icon = 'transmissionManual';
            } else {
                icon = 'transmissionAuto';
            }
            break;
        case 'driveType':
            if (value.toLowerCase().includes('front')) {
                icon = 'driveTypeFront';
            } else if (value.toLowerCase().includes('rear') && !value.toLowerCase().includes('4 wheel')) {
                icon = 'driveTypeRear';
            } else {
                icon = 'driveTypeAll';
            }
            break;
        case 'mpg':
            if (value.toLowerCase().includes('hybrid')) {
                icon = 'mpgHybrid';
            } else {
                icon = 'mpgFuel';
            }
            break;
        default:
            icon = key;
    }

    if (key === 'exterior' || key === 'interiorSeats') {
        return (
            <ColorSwatch
                className="margin-bottom-1 margin-left-1"
                colorValue={colorValue}
            />
        );
    }

    return (
        <Icon
            icon={icon}
            label={label}
        />
    );
}

/**
 *  Table of Vehicle Specifications
 *
 */
function SpecificationList({
    specKeys,
    specifications,
    className,
    bordered = true,
    ...rest
}) {
    const specificationItems = specKeys.reduce((items, key) => (key in specifications ? items.concat({ key, specData: specifications[key] }) : items), []);

    return (
        <div
            className={className}
            {...rest}
        >
            <ListColumns
                items={specificationItems}
                xs={1}
                sm={1}
                md={2}
                lg={2}
                bordered={bordered}
                condensed
                className="padding-0"
                render={({ key, specData: { label, value, disclaimer, icon, colorValue = '', ctaEl } }) => {
                    const labelClassNames = clsx({
                        'col-xs-4 text-size-200 text-subdued-lightest': !icon,
                        'col-xs-2': !!icon,
                    });
                    const valueClassNames = clsx('display-flex', {
                        'text-subdued-lightest': !value,
                        'col-xs-8': !icon,
                        'col-xs-10 margin-bottom-0': !!icon,
                    });
                    const wrapperClassNames = clsx({
                        [className]: className,
                    }, 'row text-bold');

                    const specIcon = SpecificationIcon(icon, { value, label, colorValue });

                    return (
                        <div
                            className={wrapperClassNames}
                            {...rest}
                        >
                            <div className={labelClassNames}>
                                {specIcon || (
                                    <>
                                        {label}
                                        {!!disclaimer && RenderDisclaimer(disclaimer)}
                                    </>
                                )}
                            </div>
                            <div className={valueClassNames}>
                                {(key === 'mileage' ? value + ' miles' : value) || 'Information Unavailable'}
                                {(label === 'LOW MILEAGE' && <div className="text-subdued-lightest margin-left-1">- Low Mileage</div>)}
                                {ctaEl}
                                {!!specIcon && !!disclaimer && RenderDisclaimer(disclaimer)}
                            </div>
                        </div>
                    );
                }}
            />
        </div>
    );
}

SpecificationList.propTypes = {
    /**
     * The className to apply to the root node
     */
    className: PropTypes.string,
    /**
     * List of matching keys for spec sources
     */
    specKeys: PropTypes.array,
    /**
     * Labels and values for specs
     */
    specifications: PropTypes.object,
    /**
     * Controls grid lines display
    */
    bordered: PropTypes.bool,
};

export default SpecificationList;
