import React from 'react';

import { connect, useDispatch, useSelector } from 'react-redux';

// bonnet
import { useDevice } from '@bonnet/next/device';

import {
    Cookies, formatCurrency,
} from 'atc-js';

import { useAnalytics } from 'reaxl-analytics';
import { useFeatures } from 'reaxl-features';
import {
    ShareContent,
} from 'reaxl-organisms';

import { vdpBaseUrlBuilder } from '@/utilities/vdpUrlBuilder';

import {
    authDuck,
    currentPageNameDuck,
    shareEmailSubmissionDuck,
    shareTextSubmissionDuck,
    userDuck,
} from '@/ducks';

import {
    srpActiveEmailListingDuck,
    srpActiveInteractionDuck,
} from '@/ducks/srp';
import {
    vdpResultsDuck,
} from '@/ducks/vdp';

export const ShareContentContainer = React.memo(({
    glyphColor,
    isLoggedIn,
    isStaticContent = false,
    uiContext = 'default',
    className = '',
    ctaType = '',
}) => {
    const {
        brand: [, {
            leadsPartnerName,
            brandName,
        }],
        share: [, {
            lnxEmail,
            lnxText,
            mobileSmsUrl,
            vdpLink,
        }],
    } = useFeatures(['brand', 'share']);

    const device = useDevice();
    const dispatch = useDispatch();

    const currentPageName = useSelector(currentPageNameDuck.selectors.getDuckState) || '';

    const srpListing = useSelector(srpActiveEmailListingDuck.selectors.getActiveInventory)?.[0];
    const vdpListing = useSelector(vdpResultsDuck.selectors.getVdpActiveInventory);
    const inventoryListing = ((currentPageName && currentPageName.toString() === 'srp') ? srpListing : vdpListing) || {};

    const emailSubmissionStatus = useSelector(shareEmailSubmissionDuck.selectors.getEmailSubmissionStatus);
    const textSubmissionStatus = useSelector(shareTextSubmissionDuck.selectors.getTextSubmissionStatus);

    const userZip = useSelector(userDuck.selectors.getZip);

    const { pricingDetail = {}, year, make, model, listingType, id: listingId, owner: { id: ownerId } = {}, type } = inventoryListing;
    const condition = listingType || type;

    const price = pricingDetail?.incentive || pricingDetail?.salePrice || pricingDetail?.msrp || 0;
    const priceClause = price ? `listed for ${formatCurrency(price)} ` : '';
    const emailFriendVdpUrl = vdpBaseUrlBuilder(vdpLink, {
        query: {
            listingId,
            LNX: lnxEmail,
        },
    });
    const emailFriendMessage = `Check out this ${year} ${make} ${model} ${priceClause}on ${brandName}`;
    const textMessage = ` shared a ${condition} ${year} ${make} ${model} ${priceClause}with you on ${brandName}. ${mobileSmsUrl}`
        + (lnxText ? `${lnxText}/` : '') + (listingId ? listingId.toString(16) : '');
    const disclaimer = `Text alerts or calls about the notifications you have chosen to receive may be sent using an autodialer
            or prerecorded voice, but you can opt out at any time by replying STOP. Of course, consent is not required to receive any services
            from ${brandName}. Message and data rates may apply.`;
    const { sendClick } = useAnalytics();

    const consumerIP = (new Cookies()).get('pxa_ipv4');
    const cookieId = (new Cookies()).get('ATC_ID');

    const isLink = uiContext === 'link';

    const isNba = ctaType === 'nba';

    const parentId = useSelector(srpActiveInteractionDuck.selectors.getParentId) || undefined;

    const handleShareClick = (shareType, event = { type: 'click' }) => {
        if (currentPageName === 'srp') {
            dispatch(srpActiveEmailListingDuck.creators.setActiveResults([inventoryListing?.id]));
        }
        sendClick('shareClick', event, { type: shareType, id: inventoryListing?.id, par: parentId });
    };

    const emailSubmit = (data) => {
        dispatch(shareEmailSubmissionDuck.creators.emailFriend(data));
        handleShareClick('email');
    };
    const messageSubmit = (data) => {
        dispatch(shareTextSubmissionDuck.creators.textFriend(data));
        handleShareClick('text');
    };

    const resetEmail = () => {
        dispatch(shareEmailSubmissionDuck.creators.reset());
    };

    const resetText = () => {
        dispatch(shareTextSubmissionDuck.creators.reset());
    };

    const emailProps = {
        listingId,
        ownerId,
        shareLink: emailFriendVdpUrl,
        message: emailFriendMessage,
        onSubmit: emailSubmit,
        serverError: emailSubmissionStatus?.serverError,
        showConfirmation: emailSubmissionStatus?.showConfirmation,
        resetEmailFriend: resetEmail,
        hideMessage: true,
        ip: consumerIP,
        originPage: 'FYC_VDP_EMAIL_A_FRIEND',
        searchZip: userZip,
        partnerName: leadsPartnerName,
        lnxCode: lnxEmail,
        cookieId,
        myAtcUserLoggedIn: isLoggedIn,
    };

    const textProps = {
        message: textMessage.toString(),
        listingId,
        serverError: textSubmissionStatus?.serverError,
        showConfirmation: textSubmissionStatus?.showConfirmation,
        onSubmit: messageSubmit,
        onTabClick: handleShareClick.bind(null, 'text'),
        resetTextFriend: resetText,
        disclaimer,
    };

    const title = 'Share this car';

    let buttonText;

    if (isLink) {
        buttonText = 'Share this vehicle';
    }

    return (
        <ShareContent
            title={title}
            emailProps={emailProps}
            glyphColor={glyphColor}
            textProps={textProps}
            isMobile={device?.type === 'phone'}
            isStaticContent={isStaticContent}
            resetTextFriend={resetText}
            resetEmailFriend={resetEmail}
            // TODO: fix this lint issue and remove ignore
            // eslint-disable-next-line react/jsx-no-bind
            onClick={handleShareClick.bind(null, isNba ? 'nba' : 'icon', title)}
            uiContext={uiContext}
            buttonText={buttonText}
            className={className}
        />
    );
});

const mapStateToProps = (state) => {
    const { isLoggedIn = false } = authDuck.selectors.getDuckState(state) || {};

    return {
        isLoggedIn,
    };
};

export default connect(mapStateToProps)(ShareContentContainer);
