import React, { useCallback, useEffect } from 'react';

import { connect } from 'react-redux';

import clsx from 'clsx';

import { mapDispatchToActionProp } from '@atc/modular-redux';

import {
    Glyphicon,
    Text,
} from 'reaxl';
import { sendClick } from 'reaxl-analytics';
import { useBrand } from 'reaxl-brand';
import { useFeatures } from 'reaxl-features';
import {
    SaveGlyph,
} from 'reaxl-molecules';

import compareListingsHandler from '@/utilities/compareListingsHandler';
import { saveVDPListing } from '@/utilities/saveListing';

import {
    authDuck,
    compareListingsDuck,
    savedInventoryDuck,
    userDuck,
    userPreferencesDuck,
} from '@/ducks';

import {
    vdpActiveInteractionDuck,
    vdpResultsDuck,
} from '@/ducks/vdp';

import ShareContentContainer from '@/containers/ShareContentContainer';

const noop = () => { };

export function SaveAndShareContainer({
    actions: {
        addListingToCompare = noop,
        fetchCompareListings = noop,
        saveCurrentVdpListing = noop,
        setUserPreferencesDuck = noop,
        setActiveInteraction = noop,
        hydrateUserInventory = noop,
    },
    listing,
    listingIdsToCompare,
    isSaved,
    savedListingIds,
    className,
    isLoggedIn,
}) {

    const {
        compare: [showCompare, { showSpotlight }],
        personalization: [isSaveEnabled, { listings_saved: authenticatedSavesOnly }],
        share: [isShareEnabled],
    } = useFeatures([
        'compare',
        'personalization',
        'share',
    ]);

    // retrieve saved inventory ids and set them in the inventory reducer
    const initializeUserInfo = useCallback(() => {
        hydrateUserInventory({ isLoggedIn, onLoggedInStateReady: () => { } });
    }, [hydrateUserInventory, isLoggedIn]);

    const { brands, isBrand } = useBrand();
    const isAtc = isBrand(brands.ATC_BRAND);
    const isKbbBranded = isBrand(brands.KBB_BRAND);
    const isSaveListingEnabled = isSaveEnabled || authenticatedSavesOnly;
    const isCompared = listingIdsToCompare.includes(listing.id);

    listing.birfParams = {
        co_txt_url: 'save',
        c: listing?.owner?.id,
        v: listing.id,
        par: 'v_lstg',
    };

    useEffect(() => {
        // Only call this function on KBB
        if (isKbbBranded) {
            initializeUserInfo(); // Hydrate saved inventory
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // compare currently saved listings and the current vdp listing if it is not already saved
    const handleCompare = useCallback((event) => {
        setUserPreferencesDuck({
            compareToggle: true,
            showDrawer: true,
        });
        compareListingsHandler({
            addToActiveResults: !isCompared && addListingToCompare,
            event,
            isAddToCompare: true,
            fetchCompareListings,
            listing,
            listingIds: listingIdsToCompare,
            setActiveInteraction,
            showCompareModal: false,
            showSpotlight,
        });
    }, [addListingToCompare, fetchCompareListings, isCompared, listing, listingIdsToCompare, setActiveInteraction, setUserPreferencesDuck, showSpotlight]);

    const handleSave = useCallback((event) => {
        saveCurrentVdpListing({
            event,
            listing,
            wasSaved: isSaved,
            sendClick,
        });
    }, [listing, isSaved, saveCurrentVdpListing]);

    const enableShowCompare = showCompare && (listingIdsToCompare?.length !== 5 || listingIdsToCompare?.includes(listing?.id));

    const compareGlyph = (
        <>
            <Glyphicon
                className={clsx({
                    'text-size-500 margin-right-1': true,
                    'text-accent': isAtc,
                })}
                glyph={isCompared ? 'success' : 'open'}
            />
            <span className="text-size-200">
                {isCompared ? 'Added to Compare' : 'Compare'}
            </span>
        </>
    );

    return (
        <div className={clsx(
            'col-xs-12 col-sm-5 col-md-4 display-flex gap-1 justify-content-end align-items-end margin-vertical-2 margin-vertical-sm-0',
            className
        )}
        >
            {enableShowCompare && (
                <div
                    aria-label="compare this listing"
                    className="display-flex align-items-center text-bold padding-vertical-2 cursor-pointer margin-vertical-auto"
                    id="btn-comp"
                    onClick={handleCompare}
                    role="button"
                    tabIndex="0"
                >
                    {compareGlyph}
                </div>
            )}
            {isShareEnabled && (
                <ShareContentContainer
                    glyphColor={isAtc ? 'accent' : 'default'}
                    uiContext="inline"
                    className="text-bold" // for consistency until compare/save are switched
                />
            )}
            {isSaveListingEnabled && (
                <div
                    aria-label={(isSaved ? 'unsave' : 'save') + ' this listing'}
                    className="display-flex align-items-center text-bold padding-vertical-2 padding-vertical-sm-0 margin-vertical-auto margin-left-3 cursor-pointer"
                    onClick={handleSave}
                    role="button"
                    tabIndex="0"
                >
                    <SaveGlyph
                        className="text-size-600"
                        isSaved={isSaved}
                        uiContext={isAtc ? 'accent' : 'default'}
                    />
                    <Text
                        size={200}
                        className="padding-left-2"
                    >
                        {isSaved ? 'Saved' : 'Save'}
                    </Text>
                </div>
            )}
        </div>
    );
}

const mapStateToProps = (state) => {
    const isLoggedIn = authDuck.selectors.isLoggedIn(state);
    const listing = vdpResultsDuck.selectors.getVdpActiveInventory(state);
    const listingIdsToCompare = compareListingsDuck.selectors.getActiveListingIds(state) || [];
    const savedListingIds = savedInventoryDuck.selectors.getSavedInventoryIds(state) || [];

    return {
        listing,
        listingIdsToCompare,
        isSaved: savedListingIds.includes(listing.id),
        savedListingIds,
        isLoggedIn,
    };
};

const mapDispatchToProps = mapDispatchToActionProp({
    addListingToCompare: compareListingsDuck.creators.addToCompare,
    fetchCompareListings: compareListingsDuck.creators.loadListingsData,
    saveCurrentVdpListing: saveVDPListing,
    setUserPreferencesDuck: userPreferencesDuck.creators.setKeys,
    setActiveInteraction: vdpActiveInteractionDuck.creators.setKeys,
    hydrateUserInventory: userDuck.creators.hydrateUserInventory,
});

export default connect(mapStateToProps, mapDispatchToProps)(SaveAndShareContainer);
