import { resultsDuckCreator } from 'axl-ducks';

import { vdpDuckPreset } from '@/ducks/presets';

const vdpVideoDuck = resultsDuckCreator({
    ...vdpDuckPreset,
    store: 'videos',
    initialState: {},
});

export default vdpVideoDuck;
