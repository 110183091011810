import React, { memo, useEffect } from 'react';

import { connect, useSelector } from 'react-redux';

import dynamic from 'next/dynamic';

import clsx from 'clsx';
import _get from 'lodash/get';

import { pageNames } from '@atc/bonnet-paths';

import { Heading } from 'reaxl';
import { useBrand } from 'reaxl-brand';
import { useFeatures } from 'reaxl-features';

import getPathSRP from '@/utilities/getPathSRP';
import usePaidSearchCookies from '@/utilities/usePaidSearchCookies';

import { vdpActiveInteractionDuck, vdpAdsDuck, vdpResultsDuck } from '@/ducks/vdp';

import AdsProviderContainer from '@/containers/AdsProviderContainer';
import MyWalletContainer from '@/containers/MyWalletContainer';

import LazyComponent from '@/components/LazyComponent';

import CalculateStrongAttributesContainer from './CalculateStrongAttributesContainer';
import EsntialEligibilityContainer from './EsntialEligibilityContainer';
import TrackPrintContainer from './TrackPrintContainer';
import VDPHeaderContainer from './VDPHeaderContainer';
import VehicleDetailsContainer from './VehicleDetailsContainer';

// Global containers

const { VEHICLE_DETAILS } = pageNames;

const CompareListingsContainer = dynamic(() => import(
    /* webpackChunkName: "CompareListingsContainer" */
    '@/containers/CompareListingsContainer'
), { ssr: false });

const ShareListingsContainer = dynamic(() => import(
    /* webpackChunkName: "ShareListingsContainer" */
    '@/containers/ShareListingsContainer'
), { ssr: false });

function VehicleDetailsPageComponent({
    listingId,
    queryList,
}) {
    const { brand, brands, isBrand } = useBrand();
    const isKbbBranded = isBrand(brands.KBB_BRAND);

    const showCompareModal = useSelector(vdpActiveInteractionDuck.selectors.getShowCompareModal);

    const {
        compare: [showCompare],
        my_atc_shareable_link: [enableSharableLink],
        my_wallet: [enableMyWallet],
    } = useFeatures([
        'compare',
        'my_atc_shareable_link',
        'my_wallet',
    ]);

    useEffect(() => {
        if (!listingId) {
            const query = { ...queryList };
            delete query.referrer;
            delete query.clickType;
            delete query.listingId;

            // if no expired param append them
            if (!Object.keys(query).includes('redirectExpiredPage')) {
                query.redirectExpiredPage = 1;
            }

            // TODO: BONNET NEXT - this generates a whole URL so it needs the basePath
            // should this be navigating via the nextjs router
            const goToSRP = async () => getPathSRP(query, { brand, basePath: true });
            goToSRP().then((urlGoTo) => window.location.assign(urlGoTo));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // for kbb srp
    // eslint-disable-next-line react-hooks/rules-of-hooks
    usePaidSearchCookies();

    return (
        <>
            <AdsProviderContainer
                adSelector={vdpAdsDuck.selectors.getDuckState}
                pageName={VEHICLE_DETAILS}
            >
                {listingId ? (
                    <main aria-labelledby="vehicle-details-heading">
                        <VehicleDetailsContainer />
                    </main>
                ) : (
                    <main>
                        <Heading className={clsx({ 'padding-top-7': isKbbBranded }, 'margin-vertical-7 margin-horizontal-7 text-center')}>
                            This car is no longer available. One moment while we take you to the search results page.
                        </Heading>
                    </main>
                )}

                <TrackPrintContainer />

            </AdsProviderContainer>

            <VDPHeaderContainer />

            <EsntialEligibilityContainer />

            {enableMyWallet && <MyWalletContainer />}

            {enableMyWallet && showCompare && showCompareModal && (
                <LazyComponent renderOnScroll={false}>
                    <CompareListingsContainer />
                </LazyComponent>
            )}

            {enableSharableLink && (
                <LazyComponent renderOnScroll={false}>
                    <ShareListingsContainer />
                </LazyComponent>
            )}

            <CalculateStrongAttributesContainer />
        </>
    );
}

const mapStateToProps = (state) => {
    const queryList = _get(state, 'query', {});
    const listing = vdpResultsDuck.selectors.getVdpActiveInventory(state);
    return {
        listingId: listing.id,
        queryList,
    };
};

export const VehicleDetailsPageContainer = memo(VehicleDetailsPageComponent);

export default connect(mapStateToProps)(VehicleDetailsPageContainer);
