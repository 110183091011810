import { fetchJSON } from '@bonnet/next/fetch';

const options = {
    headers: {
        'X-fwd-svc': 'atc',
    },
    credentials: 'same-origin',
    circuitBreaker: {
        id: 'videoPlaylist',
        timeout: 3000,
        resetTimeout: 30000,
        fallback: {},
    },
};
export default (id) => fetchJSON('/rest/lsc/video/' + id, options);
