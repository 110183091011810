import React, {
    memo,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';

import { useDispatch, useSelector } from 'react-redux';

import dynamic from 'next/dynamic';

import clsx from 'clsx';
import hasDom from 'has-dom';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';

import { useDevice } from '@bonnet/next/device';

// AXL
import { eventBus } from '@atc/cai-event-bus';

import { injectScript, waitUntil } from 'atc-js';

import { Text } from 'reaxl';
import { EmailCTA } from 'reaxl-email';
import { useFeatures } from 'reaxl-features';
import { InventoryImagePlaceholder } from 'reaxl-molecules';
import { Payment } from 'reaxl-wallet';

import { getVideoPlayList } from '@/actions/vdp/remoteActions';

import { paymentsDuck } from '@/ducks';

import { vdpResultsDuck, vdpVideoDuck } from '@/ducks/vdp';

import LazyComponent from '@/components/LazyComponent';
import CalculatorJumpLink from '@/components/vehicledetails/CalculatorJumpLink';

import FullscreenViewer from './FullscreenViewer';
import getShowcaseOverlayData from './getShowcaseOverlayData';
import MediaGalleryModal from './MediaGalleryModal';
import MediaShowcase from './MediaShowcase';
import tabTitles from './tabTitles';
import useMediaGalleryAnalytics from './useMediaGalleryAnalytics';

const inject360Spin = async (spin360Script, fyuseId, spin360ScriptRef, handleSnap360Click) => {
    if (!spin360ScriptRef?.current?.src?.includes(spin360Script)) {
        if (spin360ScriptRef.current) {
            spin360ScriptRef.current.remove();
        }
        spin360ScriptRef.current = await injectScript(spin360Script);
    }

    waitUntil(() => !!document?.getElementById('spin360'), () => {
        waitUntil(
            () => typeof LS360 !== 'undefined',
            () => LS360('179917CE-18CF-4CEE-9562-8EF99A01D423', '#spin360', { val: fyuseId }, { button: 1, append: 1 }),
            100,
            5000
        );

        if (handleSnap360Click) {
            const spin360 = document.getElementById('spin360');
            spin360.addEventListener('click', handleSnap360Click);
        }
    }, 100, 5000);
};
const injectFyusion = async (fyusionScript, fusionScriptRef, vin) => {
    if (!fusionScriptRef?.current?.src?.includes(fyusionScript)) {
        if (fusionScriptRef.current) {
            fusionScriptRef.current.remove();
        }
        fusionScriptRef.current = await injectScript(fyusionScript);
    }
};

const PsxCtaContainer = dynamic(() => import(/* webpackChunkName: "PsxContainers" */ '../psx/PsxCtaContainer'), { ssr: false });
function MediaGallery({
    emailCTAProps = {},
}) {
    const dispatch = useDispatch();

    const {
        fyuseId,
        fyusion,
        images = {},
        listingTitle = '',
        listingType,
        mainImageIsStock,
        owner = {},
        hasPlaylist,
        id: inventoryId,
        pricingDetail = {},
        paymentServices: {
            dealerSettings: {
                leaseEnabled,
            } = {},
        } = {},
        vin,
    } = useSelector(vdpResultsDuck.selectors.getVdpActiveInventory);

    const videos = useSelector(vdpVideoDuck.selectors.getActiveResults);

    const imageIndex = images?.primary || 0;
    const firstImage = images?.sources?.[imageIndex];
    const showGhostImage = (listingType === 'Used' && owner?.location?.address?.state === 'WA' && mainImageIsStock) || !firstImage;

    // payment
    const paymentEmphasis = useSelector((state) => paymentsDuck.selectors.getMyWalletInteraction(state, 'paymentEmphasis'));
    const financePayment = useSelector((state) => paymentsDuck.selectors.getLowestPencilingPaymentByListingId(state, inventoryId)?.payment);
    const listingLeasePayments = useSelector((state) => paymentsDuck.selectors.getListingLeasePayments(state)) || {};
    const paymentOption = useSelector((state) => paymentsDuck.selectors.getPaymentOption(state));
    const leasePayment = leaseEnabled && paymentOption === 'lease' && listingLeasePayments[inventoryId]?.payment;
    const {
        nds_calc: [isNDSCalcEnabled],
        spin_360: [isSpinEnabled, {
            script_url: spin360Script,
            show360indicator: showSpin,
        }],
        fyusion: [isFyusionEnabled, {
            script_url: fyusionScript,
            showIndicator: showFyusionSpin,
        }],
        enable_axl_media_gallery: [isAxlMediaGalleryEnabled],
    } = useFeatures([
        'nds_calc',
        'spin_360',
        'fyusion',
        'enable_axl_media_gallery',
    ]);

    const { privateSeller: isPrivateSeller } = owner;

    const sendAnalyticsEvent = useMediaGalleryAnalytics();
    const device = useDevice();
    const { mediaType } = device;

    const isXs = _get(device, 'is.xs', true);
    const isLg = _get(device, 'is.lg', true);

    const selectedTab = useRef(tabTitles.IMAGES);
    const fullScreenIndex = useRef(0);
    const spin360ScriptRef = useRef(null);
    const fusionScriptRef = useRef(null);

    const [showOverlay, setShowOverlay] = useState(false);
    const [showFullScreen, setShowFullScreen] = useState(false);

    useEffect(() => {
        if (hasPlaylist) {
            dispatch(getVideoPlayList(inventoryId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inventoryId]);

    const updateSelectedTab = useCallback((type = tabTitles.IMAGES) => {
        if (selectedTab && selectedTab.current) {
            selectedTab.current = type;
        }
    }, []);

    const hasId = isSpinEnabled && spin360Script && showSpin && !!fyuseId;
    const hasFyusion = fyusion && isFyusionEnabled && showFyusionSpin;

    // load on page load and let fyusion script determine if
    // the main image should display the spin indicator
    const shouldLoadIrrespectiveOfId = isSpinEnabled && spin360Script && !showSpin;

    const handleSnap360Click = useCallback(() => {
        sendAnalyticsEvent('click', 'snap360Click');
    }, [sendAnalyticsEvent]);

    const loadSpin = useCallback((currentIndex) => {
        if (currentIndex === 0 && hasFyusion) {
            injectFyusion(fyusionScript, fusionScriptRef, vin);
        }
        if (currentIndex === 0 && (hasId || shouldLoadIrrespectiveOfId)) {
            const requestParams = fyuseId ? 'fyuseId=' + fyuseId : 'vin=' + vin;
            const scriptUrl = spin360Script + requestParams;
            inject360Spin(scriptUrl, fyuseId, spin360ScriptRef, handleSnap360Click);
        }
    }, [hasFyusion, hasId, shouldLoadIrrespectiveOfId, fyusionScript, vin, fyuseId, spin360Script, handleSnap360Click]);

    useEffect(() => {
        loadSpin(0);

        return () => {
            if (hasDom()) {
                // this is for stitched 360 only (at_ford.js)
                // set global variable back to false so we can reload 360 on another VDP
                window.flickButtonScriptLoaded = false;
                window.is360Available = false;
            }

            const spin360 = document.getElementById('spin360');
            if (spin360) {
                // remove event after component dismount
                spin360.removeEventListener('click', handleSnap360Click);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inventoryId]);

    const updateFullScreenIndex = useCallback((index) => {
        if (fullScreenIndex?.current !== undefined) {
            fullScreenIndex.current = index;
        }
    }, []);

    const openOverlay = useCallback(({ tab = tabTitles.IMAGES } = {}) => {
        updateSelectedTab(tab);
        setShowOverlay(true);
    }, [updateSelectedTab]);

    const closeOverlay = useCallback(() => {
        setShowOverlay(false);
        updateSelectedTab(tabTitles.IMAGES);
    }, [updateSelectedTab]);

    const openFullScreen = useCallback(({ index, triggerSpin } = {}) => {
        if (index >= 0) {
            updateFullScreenIndex(index);
        }
        setShowFullScreen(true);
        if (triggerSpin) {
            loadSpin(index);
        }
    }, [updateFullScreenIndex, loadSpin]);

    const closeFullScreen = useCallback(({ index = 0 } = {}) => {
        if (index >= 0) {
            updateFullScreenIndex(index);
        }
        setShowFullScreen(false);
    }, [updateFullScreenIndex, setShowFullScreen]);

    const sendShowcaseItemClick = useCallback((event = {}, index, title) => {
        sendAnalyticsEvent('click', 'inventoryMediaViewerClick', {
            event,
            index,
            title,
            isPsxVdp: isPrivateSeller,
        });
    }, [sendAnalyticsEvent, isPrivateSeller]);

    const handleNDSCTAClick = useCallback((event, label, location) => {
        closeOverlay();
        closeFullScreen();
        sendAnalyticsEvent('click', 'mediaGalleryNdsCTAClick', { event, label, location });
    }, [closeOverlay, closeFullScreen, sendAnalyticsEvent]);

    const closeMediaGalleryModal = useCallback(() => {
        closeOverlay();
        closeFullScreen();
    }, [closeOverlay, closeFullScreen]);

    useEffect(() => {
        // Used to close the mediaGallery modal when psxCTA is clicked
        eventBus.on('vdp.mediaGallery.close', closeMediaGalleryModal);
        return () => {
            eventBus.off('vdp.mediaGallery.close', closeMediaGalleryModal);
        };
    }, [closeMediaGalleryModal]);

    const isMobileLandscape = device?.type !== 'desktop' && device?.lessThan?.md && device?.orientation === 'landscape';
    const seeDetailsLabel = `See ${leasePayment ? 'Lease' : 'Finance'} Details`;

    const headerCtaContent = useCallback(() => {
        const showPayment = paymentEmphasis && (leasePayment || financePayment);
        return !isMobileLandscape && (
            <div
                className={clsx('display-flex flex-row margin-left-auto media-gallery-heading-ctas gap-1', {
                    'margin-top-2': !isAxlMediaGalleryEnabled,
                    'align-items-center': isAxlMediaGalleryEnabled,
                })}
            >
                {isPrivateSeller && (
                    <LazyComponent>
                        <PsxCtaContainer
                            layout="mediaGallery"
                            className="w-100"
                        />
                    </LazyComponent>
                )}
                {(isNDSCalcEnabled && !isPrivateSeller) && (
                    <CalculatorJumpLink
                        label={showPayment ? (
                            <>
                                <Payment
                                    uiContext="button"
                                    payment={leasePayment || financePayment}
                                />
                                &nbsp;
                                <Text
                                    size={200}
                                    weight="normal"
                                    componentClass="span"
                                >
                                    {seeDetailsLabel}
                                </Text>
                            </>
                        ) : 'Calculate Payment'}
                        bsStyle="secondary"
                        bsSize="xsmall"
                        onClick={(event) => handleNDSCTAClick(event, showPayment ? 'See Details' : 'Calculate Payment', 'top')}
                    />
                )}
                {!(!!owner.privateSeller || owner.hideEmailForm) && (
                    <EmailCTA
                        {...emailCTAProps}
                        showIcon={false}
                        buttonSize="xsmall"
                        buttonStyle="primary"
                        context="mediaGallery"
                        labelKey="checkAvailability"
                    />
                )}
            </div>
        );
    }, [isPrivateSeller, paymentEmphasis, leasePayment, financePayment, isMobileLandscape, isAxlMediaGalleryEnabled, isNDSCalcEnabled, seeDetailsLabel, owner.hideEmailForm, owner.privateSeller, emailCTAProps, handleNDSCTAClick]);

    const { thumbnails, viewAllMedia, overlay: overlayMediaSet, fullscreen: fullScreenMediaSet } = getShowcaseOverlayData({
        images: images?.sources || [],
        videos,
        hasFyusion,
        showSpin: fyusion ? hasFyusion : hasId,
        mediaType,
        listingTitle,
        showOverlay,
        isMobileLandscape,
        isXs,
        onShowcaseImgClick: (e, title, index) => {
            sendShowcaseItemClick(e, index, title);
            closeOverlay();
            openOverlay({ tab: title });
        },
        onOverlayClick: (event, title, index) => {
            openFullScreen({ index, triggerSpin: true });
            sendAnalyticsEvent('click', 'inventoryMediaViewerClick', { event, index, title, isPsxVdp: isPrivateSeller });
            event.stopPropagation();
        },
        onVideoPlay: () => sendAnalyticsEvent('click', 'inventoryVideoPlay'),
        onVideoCompleted: () => sendAnalyticsEvent('impressions', 'inventoryVideoCompleted'),
        onVideoMilestones: (videoData, milestone) => sendAnalyticsEvent('impressions', 'inventoryVideoMilestones', { milestone }),
        paymentSnapshot: isNDSCalcEnabled && (leasePayment || financePayment) && (
            <div className="margin-vertical-4 text-center padding-bottom-6">
                <div className="margin-bottom-3">
                    <Text
                        size={500}
                        weight="bold"
                        componentClass="div"
                    >
                        Estimated
                        {' '}
                        {leasePayment ? 'Lease' : 'Finance'}
                        {' '}
                        Payment
                    </Text>
                    <Payment
                        uiContext="lender"
                        payment={leasePayment || financePayment}
                    />
                </div>
                {isNDSCalcEnabled && (
                    <CalculatorJumpLink
                        label={seeDetailsLabel}
                        bsStyle="primary"
                        bsSize="small"
                        onClick={(event) => handleNDSCTAClick(event, 'See Details', 'bottom')}
                    />
                )}
            </div>
        ),
    });

    return (
        <div
            className="margin-bottom-sm-5"
            suppressHydrationWarning
        >
            {showGhostImage ? (
                <InventoryImagePlaceholder
                    imgClassNames="img-vertically-aligned img-responsive-scale padding-5"
                    height={250}
                />
            ) : (
                <>
                    <MediaShowcase
                        data-cmp="mediaViewer"
                        mediaDataSets={thumbnails}
                        isMobile={isXs}
                        isDesktop={isLg}
                        mediaType={mediaType}
                        onAllMediaClick={(event) => {
                            sendShowcaseItemClick(event, -1);
                            openOverlay();
                        }}
                        showAllMediaButton
                        showAllMediaImage={viewAllMedia?.element}
                    />

                    {showOverlay && (
                        <MediaGalleryModal
                            defaultActiveKey={selectedTab.current === 'Photos' ? 0 : 1}
                            headerCtaContent={headerCtaContent()}
                            hideMediaGaleryModal={closeOverlay}
                            modalTitle={listingTitle}
                            loadSpin={loadSpin}
                            overlayMediaSet={overlayMediaSet}
                            pricingDetail={pricingDetail}
                            show={showOverlay}
                            showFullScreen={showFullScreen}
                            isMobile={isXs}
                            onTabClick={(title = '') => {
                                sendAnalyticsEvent('click', 'mediaTabClick', { title });
                            }}
                        />
                    )}
                    {showFullScreen && (
                        <FullscreenViewer
                            isMobile={isXs}
                            headerCtaContent={headerCtaContent()}
                            show={showFullScreen}
                            closeFullScreen={closeFullScreen}
                            fullscreenDataSet={fullScreenMediaSet}
                            defaultIndex={fullScreenIndex?.current}
                            onStateUpdateCallback={(currentSlide) => {
                                if (currentSlide !== fullScreenIndex?.current) {
                                    sendAnalyticsEvent('click', 'mediaViewerPagination', {
                                        currentSlide,
                                        fullScreenIndex: fullScreenIndex?.current || 0,
                                    });
                                    updateFullScreenIndex(currentSlide);
                                }
                            }}
                            isPsxVdp={isPrivateSeller}
                        />
                    )}
                </>
            )}
        </div>
    );
}

export default memo(MediaGallery, _isEqual);
