export default function sendAlertClickHandler({
    data,
    sendClick,
    peData,
    dataLayer,
    saveType = 'car',
}) {
    const {
        alertByEmail,
        alertByText,
        bookmark = {},
        listing = {},
    } = data;

    const alerts = {
        ...(alertByEmail ? { hasEmailAlerts: true } : {}),
        ...(alertByText ? { hasTextAlerts: true } : {}),
        ...(listing.title !== bookmark.title ? { bookmarkTitle: bookmark.title } : {}),
    };
    if (Object.keys(alerts).length > 0) {

        const emailTag = `email-${saveType}-vin`;
        const textTag = `text-${saveType}-vin`;
        const inventory = saveType === 'search' ? { search: bookmark } : { vehicle: listing };

        if (alerts.hasEmailAlerts || alerts.hasTextAlerts) {

            sendClick('carAlertsClick', undefined, { eventResult: 'cta::alert::'
                    + (alerts.hasEmailAlerts ? emailTag : '')
                    + (alerts.hasEmailAlerts && alerts.hasTextAlerts ? '|' : '')
                    + (alerts.hasTextAlerts ? textTag : ''),
            consumerInsights: peData,
            ...inventory }, dataLayer);
        }
    }
}
