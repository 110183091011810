import React from 'react';

import { PageSection } from 'reaxl';
import { useFeatures } from 'reaxl-features';

import AtcAdSlot from '@/containers/AtcAdSlot';
import DisableAdsFeatureFlag from '@/containers/global/DisableAdsFeatureFlag';

function LeaderBoardAdSlotContainer() {
    const {
        ads: [, { adSlotParams }],
    } = useFeatures([
        'ads',
    ]);

    adSlotParams.lazyLoadingBottomOffset = -20;
    adSlotParams.lazyLoadingTopOffset = -20;
    adSlotParams.showPlaceholder = true;

    return (
        <DisableAdsFeatureFlag>
            <PageSection
                className="col-lg-8"
                uiContext="section-2"
                alignment="left"
            >
                <AtcAdSlot
                    {...adSlotParams}
                />
            </PageSection>
        </DisableAdsFeatureFlag>
    );
}

export default React.memo(LeaderBoardAdSlotContainer);
