import { inventoryDuck } from '@/ducks';

import {
    fraudReportDuck,
    vdpAvailableOffersDuck,
    vdpExtendedHomeDeliveryDuck,
    vdpSimilarListingsDuck,
    vdpVideoDuck,
} from '@/ducks/vdp';

import SimilarListingsFetcher from '@/fetchers/SimilarListingsFetcher';
import AvailableOffersFetcher from '@/fetchers/vdp/AvailableOffersFetcher';
import ExtendedHomeDeliveryFetcher from '@/fetchers/vdp/ExtendedHomeDeliveryFetcher';
import FraudReportFetcher from '@/fetchers/vdp/FraudReportFetcher';
import videoPlaylistFetcher from '@/fetchers/vdp/videoPlaylistFetcher';

export function sendFraud(data) {
    const requestData = {
        listingId: data.id.toString(),
        vin: data.vin,
        ownerState: data?.owner?.location?.address?.state ?? '',
        ownerCity: data?.owner?.location?.address?.city ?? '',
        ownerId: data.owner.id,
        ownerName: data.owner.name,
        firstName: data.firstName,
        lastName: data.lastName,
        fraudType: data.fraudType,
        fraudSubType: data.fraudSubType,
        explanation: data.explanation,
        email: data.email,
        phoneNumber: data.phoneNumber,
    };

    return (dispatch) => FraudReportFetcher(requestData)
        .then((response) => {
            if (response.errorMessage) {
                dispatch(fraudReportDuck.creators.showServerError(response.errorMessage));
            } else {
                dispatch(fraudReportDuck.creators.showSuccess(response));
            }
        }).catch((err) => {
            dispatch(fraudReportDuck.creators.showServerError(err.errorMessage));
        });
}

export function getAvailableOffers(query) {
    return (dispatch) => AvailableOffersFetcher(query)
        .then((data) => {
            dispatch(vdpAvailableOffersDuck.creators.setKey({
                key: 'availableOffers',
                value: data.specialOffers,
            }));
        });
}

function fetchSimilarListings(dispatch, search) {
    SimilarListingsFetcher(search)
        .then((data = {}) => {
            if (data.listings) {
                dispatch(inventoryDuck.creators.addInventory(data.listings));
                const similarListingIdsArray = data.listings.map((listing) => listing.id);
                dispatch(vdpSimilarListingsDuck.creators.setActiveResults(similarListingIdsArray));
            }
        });
}

export function getSimilarListings(listing, channel) {
    const { owner = {}, pricingDetail = {}, isConquestable = false } = listing;
    const zip = owner?.location?.address?.zip ?? '';
    let minPrice;
    let maxPrice;
    if (pricingDetail && (pricingDetail.salePrice || pricingDetail.incentive)) {
        const salePrice = pricingDetail.incentive || pricingDetail.salePrice;
        if (salePrice >= 30000) {
            minPrice = Math.round(salePrice * 0.95);
            maxPrice = Math.round(salePrice * 1.05);
        } else {
            minPrice = Math.round(salePrice * 0.90);
            maxPrice = Math.round(salePrice * 1.10);
        }
    }

    const criteria = {
        dealerId: (isConquestable ? null : owner?.id),
        zip: isConquestable ? zip : null,
        searchRadius: (isConquestable ? 50 : null),
        excludedListingId: listing.id,
        makeCode: listing.makeCode,
        modelCode: listing.modelCode,
        classSeriesCode: listing.classSeriesCode,
        bodyStyleCode: listing.bodyStyleCodes,
        extColorSimple: listing.exteriorColorSimple,
        transmissionCode: listing.transmission?.name,
        driveCode: listing.driveGroup,
        listingType: listing?.type?.toUpperCase() ?? '',
        startYear: listing.year - 1,
        endYear: listing.year + 1,
        numRecords: 20,
        minPrice,
        maxPrice,
        minListingPriority: isConquestable ? 30 : null,
        // Override Preview channel to behave as ATC for testing L2 Retailing
        channel: channel === 'ATC,PREVIEW' ? 'ATC' : channel,
    };

    if (isConquestable) {
        // Do not send null, empty, or undefined value for ownerProduct query param
        // (can't use ternary for this param like we are for other params above)
        criteria.ownerProduct = 'INVENTORY_CONQUEST';
    }

    return (dispatch, getState) => {
        const state = getState();
        const { initialLoad } = vdpSimilarListingsDuck.selectors.getDuckState(state);

        if (initialLoad) {
            fetchSimilarListings(dispatch, criteria);
        }
    };
}

export function getVideoPlayList(query) {
    return (dispatch) => videoPlaylistFetcher(query)
        .then((data) => {
            dispatch(vdpVideoDuck.creators.setActiveResults(data));
        });
}

export function getExtendedHomeDelivery(query) {
    return (dispatch) => {
        try {
            ExtendedHomeDeliveryFetcher(query)
                .then((data) => {
                    dispatch(vdpExtendedHomeDeliveryDuck.creators.setKey({
                        key: 'extendedHomeDelivery',
                        value: data,
                    }));
                });
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Error Fetching Extended Home Delivery API.', error);
        }
    };
}

export default {
    getVideoPlayList,
    sendFraud,
    getAvailableOffers,
    getSimilarListings,
    getExtendedHomeDelivery,
};
