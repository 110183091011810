import React from 'react';

import { connect } from 'react-redux';

import _isEqual from 'lodash/isEqual';

import { PageSection } from 'reaxl';
import { useFeatures } from 'reaxl-features';

import { vdpAdsDuck } from '@/ducks/vdp';

import AtcAdSlot from '@/containers/AtcAdSlot';
import DisableAdsFeatureFlag from '@/containers/global/DisableAdsFeatureFlag';

function ShowcaseAdSlotContainer({
    wrap = false,
}) {
    const { ads: [, { mrecAdSlot } = {}] } = useFeatures(['ads']);

    const adSize = [[300, 250]];
    const adSlotId = mrecAdSlot?.adSlotId;
    const adPos = mrecAdSlot?.adPos;

    const adSlot = (
        <AtcAdSlot
            slotId={adSlotId}
            lazyLoading
            size={adSize}
            targeting={{
                pos: adPos,
                prd: 'rc12',
            }}
            lazyLoadingBottomOffset={-100}
            lazyLoadingTopOffset={-100}
            showPlaceholder
        />
    );

    const wrapAdSlot = () => (
        <PageSection
            className="col-lg-8"
            uiContext="section-2"
            alignment="left"
        >
            {adSlot}
        </PageSection>
    );
    return (
        <DisableAdsFeatureFlag>
            {wrap ? wrapAdSlot() : adSlot}
        </DisableAdsFeatureFlag>
    );
}

function mapStateToProps(state) {
    const { ad = null } = vdpAdsDuck.selectors.getDuckState(state);
    return {
        adUnit: ad && ad.adUnit, // required for SPA memoization
    };
}

export default connect(mapStateToProps)(React.memo(
    ShowcaseAdSlotContainer,
    (newProps, oldProps) => _isEqual(newProps, oldProps)
));
