import React, { useState } from 'react';

import { InView } from 'react-intersection-observer';

import PropTypes from 'prop-types';

import clsx from 'clsx';

import { formatCurrency } from 'atc-js';

import {
    IconButton,
    Modal,
    ModalBody,
    ModalHeader,
    ModalScrollPanel,
    ModalTitle,
    Tab,
    Tabs,
    Text,
} from 'reaxl';
import { useFeatures } from 'reaxl-features';

function ListingDescription({
    modalTitle,
    pricingDetail,
}) {
    const { incentive, salePrice, msrp } = pricingDetail;
    const price = incentive || salePrice || msrp || '';

    return (
        <>
            {modalTitle}
            {!!price && <span className="text-normal">{` - ${formatCurrency(price)}`}</span>}
        </>
    );
}

function MediaGalleryModal({
    defaultActiveKey,
    headerCtaContent,
    hideMediaGaleryModal,
    isMobile,
    modalTitle,
    loadSpin,
    onTabClick,
    overlayMediaSet,
    pricingDetail,
    show,
    showFullScreen,
}) {

    const {
        media_gallery: [, { inverse: isInverse }],
    } = useFeatures([
        'media_gallery',
    ]);

    const [activeKey, setActiveKey] = useState(defaultActiveKey);

    const handleTabSelect = (selectedKey) => {
        setActiveKey(selectedKey);
        loadSpin(selectedKey);
        onTabClick(overlayMediaSet[selectedKey].tabTitle);
    };

    return (
        <Modal
            show={show}
            onHide={() => {
                hideMediaGaleryModal();
            }}
            bsSize="large"
            data-cmp="mediaGalleryModal"
            backdropStyle={{
                zIndex: 10030,
            }}
            keyboard={!showFullScreen}
            enforceFocus={!showFullScreen}
            className={clsx({ inverted: isInverse })}
        >
            <ModalHeader
                closeButton={!isMobile}
                className="border-bottom-0"
            >
                <ModalTitle
                    className={clsx({
                        'display-flex justify-content-between': isMobile,
                    })}
                >
                    {
                        isMobile ? (
                            <>
                                <IconButton
                                    label="Close"
                                    glyph="chevron-left"
                                    monochrome
                                    hideLabel
                                    onClick={hideMediaGaleryModal}
                                />
                                {headerCtaContent}
                            </>
                        ) : (
                            <ListingDescription
                                pricingDetail={pricingDetail}
                                modalTitle={modalTitle}
                            />
                        )
                    }
                </ModalTitle>
            </ModalHeader>
            <ModalBody>
                {isMobile && (
                    <Text
                        weight="bold"
                        className="margin-bottom-1"
                    >
                        <ListingDescription
                            pricingDetail={pricingDetail}
                            modalTitle={modalTitle}
                        />
                    </Text>
                )}
                <Tabs
                    activeKey={activeKey}
                    additionalContentTab={!isMobile ? headerCtaContent : undefined}
                    alignment="left"
                    onSelect={handleTabSelect}
                    uiContext={isInverse ? 'dark' : 'light'}
                    enableSliderAnimation
                >
                    {
                        overlayMediaSet.map(({ tabTitle, mediaDataSet }, mediaDatasetIndex) => {
                            const renderMediaItems = mediaDataSet.map(({ element }, mediaIndex) => (
                                <div
                                    key={`${tabTitle}-${mediaIndex}`}
                                >
                                    {element}
                                </div>
                            ));
                            const renderWrapper = (children) => (tabTitle === 'Video' ? (
                                <InView>
                                    {({ ref, inView }) => (
                                        <div
                                            className="media-content-wrapper"
                                            ref={ref}
                                        >
                                            {inView && children}
                                        </div>
                                    )}
                                </InView>
                            ) : (
                                <div className="media-content-wrapper">
                                    {children}
                                </div>
                            ));
                            return (
                                <Tab
                                    key={`media-tab-${tabTitle}`}
                                    eventKey={mediaDatasetIndex}
                                    title={tabTitle}
                                >
                                    <ModalScrollPanel tabIndex="0">
                                        {renderWrapper(renderMediaItems)}
                                    </ModalScrollPanel>
                                </Tab>
                            );
                        })
                    }

                </Tabs>
            </ModalBody>
        </Modal>
    );
}

MediaGalleryModal.propTypes = {
    activeKey: PropTypes.number,
    defaultActiveKey: PropTypes.number,
    headerCtaContent: PropTypes.element,
    hideMediaGaleryModal: PropTypes.func,
    isMobile: PropTypes.bool,
    modalTitle: PropTypes.string,
    loadSpin: PropTypes.func,
    onTabClick: PropTypes.func,
    overlayMediaSet: PropTypes.array,
    pricingDetail: PropTypes.object,
    show: PropTypes.bool,
    showFullScreen: PropTypes.bool,
};

MediaGalleryModal.defaultProps = {
    activeKey: null,
    defaultActiveKey: 0,
    headerCtaContent: null,
    hideMediaGaleryModal: () => { },
    isMobile: false,
    modalTitle: '',
    loadSpin: () => { },
    onTabClick: () => { },
    overlayMediaSet: [],
    pricingDetail: {},
    show: false,
};

export default MediaGalleryModal;
