import React, { useState } from 'react';

import { useSelector } from 'react-redux';

import { useDevice } from '@bonnet/next/device';

import {
    Button,
    SideBarToModal,
} from 'reaxl';
import { useAnalytics } from 'reaxl-analytics';
import { useFeatures } from 'reaxl-features';
import { homeServicesConfig as homeServices } from 'reaxl-home-services';

import { vdpResultsDuck } from '@/ducks/vdp';

const fillDynamicValues = (content, replacements = {}) => Object.keys(replacements).reduce((string, key) => string.replace(`%${key}%`, replacements[key]), content);

function MoneyBackGuaranteeModal() {

    const {
        vdp_safeguard: [isSafeGuardEnabled],
    } = useFeatures([
        'vdp_safeguard',
    ]);

    const { sendClick } = useAnalytics();

    const [showModal, setShowModal] = useState(false);
    const device = useDevice();
    const isXs = device?.is?.xs ?? false;

    const listing = useSelector(vdpResultsDuck.selectors.getVdpActiveInventory) || {};
    const { returnPolicy = {} } = listing;

    const handleSeeDetailsClick = (event) => {
        sendClick('inventoryWarrantyDetailClick', event, { warrantyType: 'Peace of Mind' });
    };

    const getMoneyBackGuaranteeDisclaimer = ({ days, miles, hasMoneyBackGuarantee, hasVehicleReturnPolicy }) => {
        if (hasMoneyBackGuarantee && hasVehicleReturnPolicy) return fillDynamicValues(homeServices.content.disclaimerMoneyBackAndExchange, { days, miles });
        if (hasMoneyBackGuarantee) return homeServices.content.disclaimerMoneyBack;
        if (hasVehicleReturnPolicy) return homeServices.content.disclaimerExchangePolicy;
        return homeServices.content.disclaimerMoneyBackDefault;
    };

    return (
        <>
            <Button
                bsStyle="text"
                onClick={(event) => {
                    setShowModal(true);
                    handleSeeDetailsClick(event);
                }}
            >
                {isSafeGuardEnabled ? 'See ' : 'View '}
                Details
            </Button>

            <SideBarToModal
                collapsed={isXs}
                title="Peace of Mind Details"
                content={getMoneyBackGuaranteeDisclaimer(returnPolicy)}
                sideBarOptions={{
                    fullWidth: true,
                }}
                modalOptions={{
                    scrollPanel: false,
                    bsStyle: 'sm',
                }}
                show={showModal}
                onHide={() => setShowModal(false)}
            />
        </>
    );

}

export default MoneyBackGuaranteeModal;
