import React, { useCallback, useState } from 'react';

import { useSelector } from 'react-redux';

import _get from 'lodash/get';

import { useDevice } from '@bonnet/next/device';

import {
    Button,
    SideBarToModal,
} from 'reaxl';
import { sendClick } from 'reaxl-analytics';

import { vdpResultsDuck } from '@/ducks/vdp';

import BatterySpecifications from '@/components/vehicledetails/BatterySpecifications';

function BatteryInfoModal({ ctaCopy = 'See Details' }) {
    const [showExpansion, setShowExpansion] = useState(false);

    const { electricComponentInfo = {} } = useSelector(vdpResultsDuck.selectors.getVdpActiveInventory);

    const device = useDevice();
    const isXS = _get(device, 'is.xs', false);

    const toggleExpansion = useCallback((event) => {
        if (!showExpansion) {
            sendClick('electricVehicleChargeTimeCTAClick', event, {});
        }

        setShowExpansion(!showExpansion);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showExpansion]);

    const batterySpecifications = Object.keys(electricComponentInfo).length && (
        <div className="padding-horizontal-4">
            <BatterySpecifications specs={electricComponentInfo} />
        </div>
    );

    return batterySpecifications && (
        <Button
            bsStyle="text"
            data-cmp="batteryInfoModal"
            className="text-normal margin-left-2"
            onClick={toggleExpansion}
        >
            {ctaCopy}
            <SideBarToModal
                title="Battery Specifications"
                content={batterySpecifications}
                collapsed={isXS}
                show={showExpansion}
                onHide={toggleExpansion}
                sideBarOptions={{ fullWidth: true }}
                modalOptions={{ bsStyle: 'lg' }}
            />
        </Button>
    );
}

export default BatteryInfoModal;
