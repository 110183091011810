const EFFIECIENCY_UNIT = 'kilowatt hours/100 miles';

const chargingLevelGuide = [
    { key: 'disc-clg-1', text: 'Level 1 charging is the slowest and uses a standard household 120-volt charger.' },
    { key: 'disc-clg-2', text: 'Level 2 charging uses a 220-volt charger, commonly installed in garages and available at public charging stations.' },
    { key: 'disc-clg-3',
        text: ('Level 3 charging uses a 480-volt direct current (DC) charger, perfect for when you need to recharge quickly. '
        + 'Not every EV can charge at level 3 chargers, the high voltage can stress certain batteries and degrade their battery capacity over time') },
];

const chargingTimeDisclaimer = [{
    key: 'disc-ctd-1: ',
    text: ('Charging time is an estimate based on Level 2 Charging if you are charging from empty to 100% battery capacity. '
                                + 'Exact charging times will vary depending on the battery\'s age, degradation level, and other factors.'),
}];

const formatBatteryInfo = (electricComponentInfo = {}) => {
    const {
        batteryRange,
        batteryType,
        batteryCapacity,
        batteryEfficiencyCombined,
        batteryEfficiencyCity,
        batteryEfficiencyHighway,
        batteryEnergyCapacity,
        batteryMaximumChargeRate,
        chargingLevelMax,
        chargingLevelsAll = [],
        chargingPortSide,
        connectorTypes,
        electricMotorCount,
        electricMotorDescription,
        epaChargeTimeAt240V,
    } = electricComponentInfo;

    return ([
        {
            label: 'Battery Range',
            value: batteryRange + ' miles',
        },
        {
            label: 'Battery Type',
            value: batteryType,
        },
        {
            label: 'Battery Capacity',
            value: batteryCapacity + ' kilowatt hours',
        },
        {
            label: 'Battery Efficiency - Combined',
            value: batteryEfficiencyCombined + ' ' + EFFIECIENCY_UNIT,
        },
        {
            label: 'Battery Efficiency - City',
            value: batteryEfficiencyCity + ' ' + EFFIECIENCY_UNIT,
        },
        {
            label: 'Battery Efficiency - Hwy',
            value: batteryEfficiencyHighway + ' ' + EFFIECIENCY_UNIT,
        },
        {
            label: 'Motor',
            value: electricMotorDescription,
        },
        {
            label: 'Battery Energy Capacity',
            value: batteryEnergyCapacity + ' ampere-hours',
        },
        {
            label: 'Battery Maximum Charge Rate',
            value: batteryMaximumChargeRate + 'kW',
        },
        {
            label: 'Max Charging Level',
            value: chargingLevelMax,
        },
        {
            label: 'All Charging Levels',
            value: chargingLevelsAll.join(', '),
            additionalInfo: chargingLevelGuide,
        },
        {
            label: 'Charging Port Side',
            value: chargingPortSide,
        },
        {
            label: 'Connector Types',
            value: connectorTypes,
        },
        {
            label: 'Electric Motor Count',
            value: electricMotorCount,
        },
        {
            label: 'EPA Charging Time @ 240V (0-100%)',
            value: epaChargeTimeAt240V + ' hours',
            additionalInfo: chargingTimeDisclaimer,
        },

    ]);
};

export default formatBatteryInfo;
