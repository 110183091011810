import React, { createElement, Fragment } from 'react';

import { connect, useDispatch, useSelector } from 'react-redux';

import Link from 'next/link';

import clsx from 'clsx';
import _get from 'lodash/get';

import { removeBasePath } from '@bonnet/next/base-path';

// modules

import {
    Button,
    Glyphicon,
    Text,
} from 'reaxl';
import { sendClick } from 'reaxl-analytics';

import { isVDPExperienceUTM } from '@/analytics/analyticsUtils';

import { queryDuck } from '@/ducks';

import { srpResultsDuck, srpReuseActiveStateDuck } from '@/ducks/srp';
import { vdpActiveInteractionDuck } from '@/ducks/vdp';

import BackToResultsModule from '@/modules/BackToResultsModule';

// analytics

/*
 * Back to Results component to be used on VDP & B&P Pages
 * will determine what copy the link will have, and how the click action will function based
 * on the referrer, what page is it going to, and if it is server rendered
 */
export const BackToResultsLink = ({
    className,
    backToResults,
    hasSrpResults,
    resultCount,
    uiContext = 'default',
}) => {
    // TODO: BONNET NEXT - followup: whys is there so much logic in here it should be in the BackToResults Module with the rest of the logic

    const isEmail = uiContext === 'email';

    const query = useSelector(queryDuck.selectors.getDuckState);

    const backToResultsDestination = _get(backToResults, 'link', '');
    const spaLink = backToResultsDestination.startsWith('/cars-for-sale');
    const BackToResultsElement = spaLink ? Link : Button;

    const isBuildPriceDestination = backToResultsDestination.includes('/cars-for-sale/build');

    const dispatch = useDispatch();
    const displayHybridVdp = useSelector(vdpActiveInteractionDuck.selectors.getDisplayHybridVdp);

    let backToResultsCmp = '';

    const backToResultsClick = async (event) => {
        if (backToResultsCmp || isEmail) {
            // nba = Next Best Actions, tells the clickEvent is coming from the EmailFormNextStepsContainer
            sendClick('viewMoreVehiclesClick', event, { context: isEmail ? 'nba' : '' });
        }
        if (hasSrpResults) {
            await dispatch(srpReuseActiveStateDuck.creators.setTrue());
        }

        if (!spaLink) {
            const { origin = '' } = window?.location || {};
            if (origin && backToResultsDestination) {
                window.open(origin + backToResultsDestination, '_self');
            }
        }
    };

    let backToResultsText;

    if (displayHybridVdp || isVDPExperienceUTM(query)) {
        backToResultsText = 'See more like this';
        backToResultsCmp = 'see_more_v';
    } else if (resultCount > 0 && query?.clickType !== 'similar' && !isEmail) {
        backToResultsText = (
            <Text weight="bold">
                <span className="sr-only">
                    Back to
                    {' '}
                </span>
                Results
            </Text>
        );
    } else if (backToResultsDestination === '/cars-for-sale/all') {
        backToResultsText = 'View more vehicles';
    } else if (isBuildPriceDestination) {
        backToResultsText = 'Back to Custom Order';
    } else {
        backToResultsText = 'View more vehicles like this one';
    }

    const backToResultsLabel = (
        <Fragment
            key={backToResultsText}
        >
            <Glyphicon
                glyph={isEmail ? 'similar-vehicles' : 'menu-left'}
                className={isEmail ? 'margin-right-3 text-accent' : 'margin-right-1'}
                aria-hidden="true"
            />
            {backToResultsText}
        </Fragment>
    );

    // TODO: BONNET NEXT - use of removeBasePath is because we re-enabled all
    // internal URLS to still be created with basePath but next/link will re-append
    const href = spaLink ? removeBasePath(backToResultsDestination) : backToResultsDestination;
    const backToResultsLink = createElement(BackToResultsElement, {
        ...(spaLink && { href }),
        ...(isEmail && { bsStyle: 'text' }),
        ...(!spaLink && !isEmail && { bsStyle: 'link' }),
        className: clsx('padding-left-0', {
            'text-nowrap margin-vertical-2': !isEmail,
            'btn btn-link': spaLink && !isEmail,
        }, className),
        onClick: backToResultsClick,
    }, [backToResultsLabel]);

    return backToResultsLink;
};

const mapStateToProps = (state) => {
    const backToResults = BackToResultsModule.duck.selectors.getDuckState(state);

    const { count = 0 } = srpResultsDuck.selectors.getDuckState(state);

    const hasSrpResults = srpResultsDuck.selectors.hasActiveResults(state);
    return {
        backToResults,
        hasSrpResults,
        resultCount: count,
    };
};

export default connect(mapStateToProps)(BackToResultsLink);
