import React from 'react';

import { useSelector } from 'react-redux';

import Error from 'next/error';

import _get from 'lodash/get';

import { withCtxMiddleware } from '@bonnet/next/ctx-middleware';
import { withCtxAbortOnStatusCode } from '@bonnet/next/ctx-middleware/with-ctx-abort-on-status-code';
import { withBonnetPage } from '@bonnet/next/page';
import { useRouterScroll } from '@bonnet/next/use-router-scroll';

import { pageNames } from '@atc/bonnet-paths';

import { getScaledImageSource } from 'atc-js';

import { AnalyticsProvider } from 'reaxl-analytics';
import { atcAuthModule } from 'reaxl-auth';
import { useFeatures } from 'reaxl-features';

import { imageSizes } from 'axl-config';

import offsetCalc from '@/utilities/offSetScrollCalculation';
import useHashScroll from '@/utilities/useHashScroll';
import usePauseAudioEye from '@/utilities/usePauseAudioEye';

import contentAnalytics from '@/analytics/contentAnalyticsHandlers';
import creditAppAnalytics from '@/analytics/creditAppAnalyticsHandlers';
import globalAnalyticsHandlers from '@/analytics/globalAnalyticsHandlers';
import inventoryAnalytics from '@/analytics/inventoryAnalyticsHandlers';
import nativeDealAnalytics from '@/analytics/nativeDealAnalyticsHandlers';
import ownerAnalytics from '@/analytics/ownerAnalyticsHandlers';
import paymentCalculatorAnalytics from '@/analytics/paymentCalculatorAnalyticsHandlers';
import psxAnalyticsHandlers from '@/analytics/psxAnalyticsHandlers';
import useAnalyticsPageEventListener from '@/analytics/useAnalyticsPageEventListener';
import useDataLayer from '@/analytics/useDataLayer';
import vdpAnalyticsHandlers from '@/analytics/vdpAnalyticsHandlers';
import vdpRatingAnalytics from '@/analytics/vdpRatingAnalyticsHandlers';

import {
    ctxPresetFindCar,
    handleMarketPlaceServerRedirect,
    withCustomLeadRouting,
    withDataIslandDispatcher,
    withInterestRates,
    withPaymentServices,
    withPreloadHeader,
    withWalletConfigs,
} from '@/context-middleware';

import {
    addVehicleDetailsPageData,
    dispatchVehicleDetailsPageData,
} from '@/context-middleware/vdp';

import {
    birfDuck,
    expiredListingDuck,
    globalConfigsDuck,
} from '@/ducks';

import { srpResultsDuck, srpReuseActiveStateDuck } from '@/ducks/srp';
import * as vdpDucks from '@/ducks/vdp';

import {
    BackToResultsModule,
    CanonicalUrlModule,
    CookieConsentModule,
    ParsedQueryModule,
    PersonalizationEngineModule,
} from '@/modules';

import { CommonHeadContainer, PersonalizationEngineContainer } from '@/containers';

import VdpAnalyticsWrapper from '@/containers/vdp/VdpAnalyticsWrapper';
import VdpPageEventsContainer from '@/containers/vdp/VdpPageEventsContainer';
import VehicleDetailsPageContainer from '@/containers/vdp/VehicleDetailsPageContainer';

import FordVehicleDetailsSchema from '@/components/FordVehicleDetailsSchema/index.js';

const { VEHICLE_DETAILS } = pageNames;

function VehicleDetailsPage({
    currentUrl,
    images,
    statusCode,
    query,
    addPrefetchedBundleScriptTag = false,
}) {
    const {
        disable_personalization_engine: [isPersonalizationEngineDisabled],
        disable_thirdparty: [disableThirdParty],
        google_analytics: [, { disableGtm, disableGtmEvents, delay }],
        oneTrust: [isOneTrustEnabled],
    } = useFeatures([
        'disable_personalization_engine',
        'disable_thirdparty',
        'google_analytics',
        'oneTrust',
    ]);

    // TL;DR this prefetches srp bundles from vdp so that it will be cached (w/o processing yet) and improve SPA transition
    // const SrpPreload = lazy(() => new Promise(() => {
    //     setTimeout(() => import(
    //         /* webpackChunkName: "srp" */
    //         './SearchResultsPage'
    //     ), 20000);
    // }));

    usePauseAudioEye();

    useRouterScroll();

    // TODO: BONNET - Prefetch VDP from SRP and vice versa

    const scrollOffset = offsetCalc(20, 'sticky-header-vdp');
    useHashScroll({ enableSmoothScroll: true, offset: scrollOffset, delay: 750 });

    const hasSuppressedCookies = useSelector(CookieConsentModule.duck.selectors.selectHasSuppressedGroup) && isOneTrustEnabled;
    const analyticsPageEventListener = useAnalyticsPageEventListener();
    const dataLayer = useDataLayer();
    const dataIsland = useSelector(birfDuck.selectors.getPageData);
    const telmetricsKey = useSelector(globalConfigsDuck.selectors.getDuckState)?.telmetricsKey;

    const analyticsDataIsland = {
        ...dataIsland,
    };

    const analyticsProps = {
        disabled: hasSuppressedCookies,
        dataLayer: () => ({ ...dataLayer }),
        getDataIsland: () => analyticsDataIsland,
        instanceId: dataLayer?.page?.pixallPageInstanceId,
        eventListener: analyticsPageEventListener,
        google: {
            delay,
        },
        option: {
            disableGTM: disableGtm || disableThirdParty,
            disablePixall: disableThirdParty,
            disableGTMEvents: disableGtmEvents,
            telmetricsKey,
        },
        value: {
            ...globalAnalyticsHandlers,
            ...inventoryAnalytics,
            ...ownerAnalytics,
            ...contentAnalytics,
            ...vdpAnalyticsHandlers,
            ...nativeDealAnalytics,
            ...paymentCalculatorAnalytics,
            ...creditAppAnalytics,
            ...vdpRatingAnalytics,
            ...psxAnalyticsHandlers,
        },
    };

    const primary = _get(images, 'primary', 0);
    const primaryImgSrc = _get(images, `sources[${primary}].src`, '');
    const openGraphImage = primaryImgSrc && getScaledImageSource({
        src: primaryImgSrc,
        ...imageSizes.openGraphImg,
    });

    if (statusCode) {
        return <Error statusCode={statusCode} />;
    }

    return (
        <>
            {!isPersonalizationEngineDisabled && <PersonalizationEngineContainer pageName={VEHICLE_DETAILS} />}
            <CommonHeadContainer
                currentUrl={currentUrl}
                openGraphImage={openGraphImage}
                pageName={VEHICLE_DETAILS}
                query={query}
            />
            <FordVehicleDetailsSchema />
            <AnalyticsProvider {...analyticsProps}>
                <VdpAnalyticsWrapper />
                <VdpPageEventsContainer />
                <VehicleDetailsPageContainer />
            </AnalyticsProvider>
        </>
    );
}

VehicleDetailsPage.getInitialProps = async (ctx) => {

    if (srpResultsDuck.selectors.hasActiveResults(ctx.store.getState())) {
        ctx.store.dispatch(srpReuseActiveStateDuck.creators.setTrue());
    }

    await withCtxMiddleware([
        ctxPresetFindCar({
            pageName: VEHICLE_DETAILS,
            preFetchCtxMiddleware: [
                ctx.withModuleCtxMiddleware({
                    batchDispatch: false,
                    fetchCompositeJSON: false,
                }),
            ],
            fetcher: addVehicleDetailsPageData,
            dispatcher: dispatchVehicleDetailsPageData,
        }),
        withCtxAbortOnStatusCode(),
        withPreloadHeader(),
        withDataIslandDispatcher(),
        withInterestRates(),
        withWalletConfigs(),
        withPaymentServices(),
        withCustomLeadRouting(),
        handleMarketPlaceServerRedirect(),
    ], ctx);

    // if the current ctx contains a status code then we need to abort the process and return it
    // to allow the page to render an error
    if (ctx.statusCode) {
        return {
            statusCode: ctx.statusCode,
        };
    }
    return {
        currentUrl: ctx.data.currentUrl,
        images: _get(ctx, 'data.listings[0].images'),
        query: ctx.query,
        dataIsland: ctx.data.dataIsland,
    };
};

const { BonnetPageComponent } = withBonnetPage(VehicleDetailsPage, {
    reducers: [
        ...Object.values(vdpDucks),
        srpResultsDuck,
        srpReuseActiveStateDuck,
        expiredListingDuck, // TODO: BONNET - should this be added in _app as it used on both?
    ],
    modules: [
        BackToResultsModule,
        CookieConsentModule,
        CanonicalUrlModule,
        ParsedQueryModule,
        PersonalizationEngineModule,
        atcAuthModule,
    ],
});

export default BonnetPageComponent;
