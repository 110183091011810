import _get from 'lodash/get';

import {
    chatbotCTAClick,
    chatbotImpression,
    dealerSegmentationImpression,
    dhsImpressions,
    electricVehicleChargeTimeCTAClick,
    inventoryAdditionalProtectionsImpression,
    inventoryDetailAccordionClick,
    inventoryHistoryReportImpressions,
    inventoryProtectionReadMoreClick,
    inventoryProtectionViewMoreClick,
    inventoryRecallDetailsClick,
    inventoryRecallImpression,
    inventoryVdpPaginationClick,
    inventoryVehicleProtectionImpression,
    inventoryViewAvailableOffersClick,
    inventoryViewSpecificationsClick,
    inventoryWarrantyDetailClick,
    inventoryWarrantyDetailImpression,
    kbbFppDetailsClick,
    listingPaymentCTAClick,
    myWalletDealBannerImpression,
    myWalletViewDealStatusCTAClick,
    ownerNextCTAClick,
    ownerPhoneNumberImpressions,
    page,
    pricingOverviewClick,
    snapshotPaymentDetailsClick,
    viewMoreVehiclesClick,
    viewMoreVehiclesImpression,
} from 'reaxl-analytics';

import { queryDuck, userDuck } from '@/ducks';

import { vdpResultsDuck } from '@/ducks/vdp';

export default {
    page: (metadata, option) => {
        const { data, state } = metadata;
        const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
        const { id, owner = {} } = activeInventory;
        const pageEvents = [];
        const homeServices = _get(owner, 'homeServices');

        if (homeServices && Object.keys(homeServices).length > 0) {
            // sets event81
            pageEvents.push('dhsVDP');
        }

        return page({
            ...metadata,
            data: {
                ...data,
                pageType: id ? 'vdp' : 'other',
                pageEvents,
                domainKey: 'vdp',
                inventoryId: activeInventory.id,
            },
            userDuck,
            queryDuck,
        }, option);
    },
    chatbotCTAClick: (metadata, option) => {
        const { data, state } = metadata;
        const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);

        return chatbotCTAClick({
            ...metadata,
            data: {
                ...data,
                inventoryId: activeInventory?.id,
            },
        }, option);
    },
    chatbotImpression: (metadata, option) => {
        const { data, state } = metadata;
        const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
        return chatbotImpression({
            ...metadata,
            data: {
                ...data,
                inventoryId: activeInventory?.id,
            },
        }, option);
    },
    dhsImpressions: (metadata, option) => dhsImpressions({
        ...metadata,
    }, option),
    dealerSegmentationImpression: (metadata, option) => {
        const { data, state } = metadata;
        const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
        return dealerSegmentationImpression({
            ...metadata,
            data: {
                inventoryId: activeInventory?.id,
                ...data,
            },
        }, option);
    },
    electricVehicleChargeTimeCTAClick: (metadata, option) => {
        const { data, state } = metadata;
        const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
        return electricVehicleChargeTimeCTAClick({
            ...metadata,
            data: {
                inventoryId: activeInventory?.id,
                ...data,
            },
        }, option);
    },
    inventoryAdditionalProtectionsImpression: (metadata, option) => {
        const { data, state } = metadata;
        const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
        return inventoryAdditionalProtectionsImpression({
            ...metadata,
            data: {
                inventoryId: activeInventory?.id,
                ...data,
            },
        }, option);
    },
    inventoryDetailAccordionClick: (metaData, option) => {
        const { data, state } = metaData;
        const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);

        const detailSection = _get(metaData, 'data.key', 'vehicle-detail');

        return inventoryDetailAccordionClick({
            ...metaData,
            data: {
                inventoryId: activeInventory?.id,
                detailSection,
                isPsxVdp: data?.isPsxVdp,
            },
        }, option);
    },
    inventoryProtectionViewMoreClick: (metadata, option) => {
        const { data, state } = metadata;
        const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
        return inventoryProtectionViewMoreClick({
            ...metadata,
            data: {
                inventoryId: activeInventory?.id,
                ...data,
            },
        }, option);
    },
    inventoryProtectionReadMoreClick: (metadata, option) => {
        const { data, state } = metadata;
        const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
        return inventoryProtectionReadMoreClick({
            ...metadata,
            data: {
                inventoryId: activeInventory?.id,
                ...data,
            },
        }, option);
    },
    inventoryRecallDetailsClick: (metadata, option) => {
        const { data, state } = metadata;
        const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
        return inventoryRecallDetailsClick({
            ...metadata,
            data: {
                inventoryId: activeInventory?.id,
                ...data,
                pixallData: {
                    listingType: 'lstg',
                },
            },
        }, option);
    },
    inventoryRecallImpression: (metadata, option) => {
        const { data, state } = metadata;
        const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
        return inventoryRecallImpression({
            ...metadata,
            data: {
                inventoryId: activeInventory?.id,
                ...data,
            },
        }, option);
    },
    inventoryVdpPaginationClick: (metadata, option) => {
        const { data, state } = metadata;
        const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
        return inventoryVdpPaginationClick({
            ...metadata,
            data: {
                inventoryId: activeInventory?.id,
                ...data,
            },
        }, option);
    },
    inventoryVehicleProtectionImpression: (metadata, option) => {
        const { data, state } = metadata;
        const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
        return inventoryVehicleProtectionImpression({
            ...metadata,
            data: {
                ...data,
                inventoryId: activeInventory?.id,
            },
        }, option);
    },
    inventoryViewSpecificationsClick: (metadata, option) => {
        const { data, state } = metadata;
        const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
        return inventoryViewSpecificationsClick({
            ...metadata,
            data: {
                ...data,
                inventoryId: activeInventory?.id,
            },
        }, option);
    },
    inventoryViewAvailableOffersClick: (metadata, option) => {
        const { state, data } = metadata;
        const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
        return inventoryViewAvailableOffersClick({
            ...metadata,
            data: {
                ...data,
                inventoryId: activeInventory?.id,
            },
        }, option);
    },
    inventoryWarrantyDetailClick: (metadata, option) => {
        const { data, state } = metadata;
        const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
        return inventoryWarrantyDetailClick({
            ...metadata,
            data: {
                inventoryId: activeInventory?.id,
                ...data,
            },
        }, option);
    },
    inventoryWarrantyDetailImpression: (metadata, option) => {
        const { data, state } = metadata;
        const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
        return inventoryWarrantyDetailImpression({
            ...metadata,
            data: {
                inventoryId: activeInventory?.id,
                ...data,
            },
        }, option);
    },
    inventoryHistoryReportImpressions: (metadata, option) => {
        const { data, state } = metadata;
        const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
        return inventoryHistoryReportImpressions({
            ...metadata,
            data: {
                inventoryId: activeInventory?.id,
                ...data,
            },
        }, option);
    },
    snapshotPaymentDetailsClick: (metadata, option) => {
        const { data, state } = metadata;
        const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);

        return snapshotPaymentDetailsClick({
            ...metadata,
            data: {
                ...data,
                inventoryId: data.inventoryId || activeInventory.id,
            },
        }, option);
    },
    listingPaymentCTAClick: (metadata, option) => listingPaymentCTAClick({
        ...metadata,
    }, option),
    pricingOverviewClick: (metadata, option) => {
        const { data, state } = metadata;
        const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);

        return pricingOverviewClick({
            ...metadata,
            data: {
                ...data,
                inventoryId: activeInventory.id,
            },
        }, option);
    },
    kbbFppDetailsClick: (metadata, option) => {
        const { data, state } = metadata;
        const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);

        return kbbFppDetailsClick({
            ...metadata,
            data: {
                ...data,
                inventoryId: activeInventory.id,
            },
        }, option);
    },
    viewMoreVehiclesClick: (metadata, option) => {
        const { data, state } = metadata;
        const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);

        return viewMoreVehiclesClick({
            ...metadata,
            data: {
                ...data,
                pixallData: {
                    listingType: 'lstg',
                },
                inventoryId: activeInventory?.id,
            },
        }, option);
    },
    viewMoreVehiclesImpression: (metadata, option) => {
        const { data, state } = metadata;
        const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
        return viewMoreVehiclesImpression({
            ...metadata,
            data: {
                ...data,
                label: 'View More Vehicles Like This',
                inventoryId: activeInventory?.id,
            },
        }, option);
    },
    ownerNextCTAClick: (metadata, option) => {
        const { data, state } = metadata;
        const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
        return ownerNextCTAClick({
            ...metadata,
            data: {
                inventoryId: activeInventory?.id,
                ...data,
            },
            userDuck,
        }, option);
    },

    ownerPhoneNumberImpressions: (metadata, option) => {
        const { data, state } = metadata;
        const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
        return ownerPhoneNumberImpressions({
            ...metadata,
            data: {
                inventoryId: activeInventory?.id,
                ...data,
            },
            userDuck,
            filterDuck: vdpResultsDuck,
        }, option);
    },

    myWalletDealBannerImpression: (metadata, option) => {
        const { data, state } = metadata;
        const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
        return myWalletDealBannerImpression({
            ...metadata,
            data: {
                ...data,
                inventoryId: activeInventory?.id,
            },
        }, option);
    },

    myWalletViewDealStatusCTAClick: (metadata, option) => {
        const { data, state } = metadata;
        const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
        return myWalletViewDealStatusCTAClick({
            ...metadata,
            data: {
                ...data,
                inventoryId: activeInventory?.id,
            },
        }, option);
    },
};
