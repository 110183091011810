import { objectDuckCreator } from '@atc/modular-redux';

import { vdpDuckPreset } from '@/ducks/presets';

export default objectDuckCreator({
    ...vdpDuckPreset,
    store: 'dr',
    initialState: {
        hasDR: false,
    },
});
