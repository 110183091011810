import React, { useCallback } from 'react';

import { connect, useDispatch, useSelector } from 'react-redux';

import dynamic from 'next/dynamic';

import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';

import { mapDispatchToActionProp } from '@atc/modular-redux';

import { calculateDownPayment } from 'atc-js';

import { Button, Link } from 'reaxl';
import { sendClick } from 'reaxl-analytics';
import { EmailFormNextSteps } from 'reaxl-email';
import { useFeatures } from 'reaxl-features';
import { SaveGlyph } from 'reaxl-molecules';
import {
    DigitalRetailOverlayCTA,
    getAppliedDealerSettings,
} from 'reaxl-wallet';

import { fallbackInterestRates } from 'axl-config';

import { saveVDPListing } from '@/utilities/saveListing';

import {
    authDuck,
    currentPageNameDuck,
    dealsDuck,
    globalConfigsDuck,
    interestRateDuck,
    paymentsDuck,
    savedInventoryDuck,
} from '@/ducks';

import {
    srpActiveEmailListingDuck,
    srpActiveInteractionDuck,
} from '@/ducks/srp';
import {
    vdpActiveInteractionDuck,
    vdpResultsDuck,
} from '@/ducks/vdp';

import useVdpNavigation from '@/hooks/useVdpNavigation';

import LazyComponent from '@/components/LazyComponent';
import BackToResultsLink from '@/components/vehicledetails/BackToResultsLink';

import ShareContentContainer from './ShareContentContainer';

const Westlake = dynamic(() => import('reaxl-lenders/es/Westlake'), { ssr: false });

/**
 *  Displays next steps post email sent confirmation on the VDP right rail email form
 *
 */
function EmailFormNextStepsComponent({
    actions: {
        saveCurrentVdpListing = () => { },
        setActiveVdpInteraction = () => { },
        setActiveSrpInteraction = () => { },
        updateMyWallet = () => { },
        addHideReceiptListing = () => { },
    },
}) {
    const dispatch = useDispatch();
    const pageId = useSelector(currentPageNameDuck.selectors.getDuckState);
    const isSrp = pageId === 'srp';

    const srpListing = useSelector(srpActiveEmailListingDuck.selectors.getActiveInventory)?.[0];
    const vdpListing = useSelector(vdpResultsDuck.selectors.getVdpActiveInventory);
    const listing = isSrp ? srpListing : vdpListing;

    const {
        personalization: [isSaveEnabled, { listings_saved: authenticatedSavesOnly }],
        share: [isShareEnabled],
        thirdparty_prequal: [, {
            westlake: isWestLakePreQualEnabled,
        }],
        lead_gen_additional_inventory: [isLeadAdditionalInv],
        enable_deals_duck: [isDealsDuckEnabled],
    } = useFeatures([
        'personalization',
        'share',
        'thirdparty_prequal',
        'lead_gen_additional_inventory',
        'enable_deals_duck',
    ]);

    const { id, vin, paymentServices = {}, pricingDetail: { salePrice, msrp } = {}, owner: { sacaMmdId = '', id: ownerId = '' } = {}, isWestlakePrequal } = listing;
    const { dealerSettings = {}, digitalRetailingType = '' } = paymentServices;
    const isLoggedIn = useSelector(authDuck.selectors.isLoggedIn);
    const savedListingIds = useSelector(savedInventoryDuck.selectors.getSavedInventoryIds) || [];
    const paymentsInfo = useSelector(paymentsDuck?.selectors.getDuckState);

    // dealsDuck: code clean up
    const selectorDuck = isDealsDuckEnabled ? dealsDuck : paymentsDuck;
    const existingDeal = useSelector((state) => selectorDuck.selectors.getDealByListingVin(state, vin)) || {};
    const dealDetails = useSelector((state) => selectorDuck?.selectors?.getTransformedDealDetails(state));
    const ownerCreditApp = useSelector((state) => selectorDuck?.selectors?.getCreditAppDealsByOwnerId?.(state, ownerId));
    const westlakePreQualUrl = useSelector(globalConfigsDuck.selectors.getWestlakePreQualUrl);
    // tradeIn from nds/wallet
    const tradeIn = useSelector((state) => paymentsDuck?.selectors?.getTradeIn(state)) || {};

    const navigateToVdp = useVdpNavigation();

    const isCreditAppSubmittedForOwner = !_isEmpty(ownerCreditApp); // for SACA credit apps
    let isCreditAppSubmittedForListing = false;

    const isTradeExistInWallet = !!tradeIn?.value;
    let isTradeExistInDeal = false;

    const { id: dealXgId } = existingDeal;
    const isExistingDeal = !!dealXgId;
    if (isExistingDeal) {
        isCreditAppSubmittedForListing = dealDetails?.[dealXgId]?.creditApp?.creditAppSubmittedFlag;
        isTradeExistInDeal = dealDetails?.[dealXgId]?.tradeIn?.tradeSubmitFlag;
    }

    const walletInteractions = useSelector(paymentsDuck.selectors.getMyWalletInteractions) || {};
    const nationalRates = useSelector(interestRateDuck.selectors.getDuckState) || fallbackInterestRates;
    const isSaved = savedListingIds.includes(id);
    const isDRAccelerate = digitalRetailingType === 'accelerate';
    const price = salePrice || msrp;

    const isSaveListingEnabled = isSaveEnabled || (authenticatedSavesOnly && isLoggedIn);
    const isWestlakeEnabled = isWestLakePreQualEnabled && isWestlakePrequal;

    const handleConfirmationModalClose = () => {
        if (!isSrp) {
            setActiveVdpInteraction({
                showEmailModal: false,
                showEmailConfirmationModal: false,
            });
        } else if (isSrp) {
            setActiveSrpInteraction({
                showEmailModal: false,
                showEmailConfirmationModal: false,
            });
        }
    };

    const { interactions: { paymentEmphasis } = {} } = paymentsInfo;

    const {
        creditType,
        defaultLeaseDownPayment,
        downPayment,
        downPaymentPercent,
    } = getAppliedDealerSettings({
        dealerSettings,
        walletInteractions,
        price,
        payments: paymentsInfo,
        nationalRates,
    });

    const handleSave = useCallback((event) => {
        saveCurrentVdpListing({
            event,
            listing,
            wasSaved: isSaved,
            sendClick,
            context: 'nba',
        });
        if (isLoggedIn && !isSaved) {
            handleConfirmationModalClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listing, isSaved, saveCurrentVdpListing]);

    const handleSACACreditAppClick = (event) => {
        event.stopPropagation();

        sendClick('startCreditApplication', event, { listingId: id, partner: 'saca' });
        if (isSrp) {
            navigateToVdp({
                listingId: listing.id,
            });
            setActiveSrpInteraction({
                showEmailConfirmationModal: false,
            });
        }
        setActiveVdpInteraction({
            showEmailConfirmationModal: false,
            showFinanceAppModal: true,
        });
    };

    const renderShare = (
        <ShareContentContainer
            uiContext="link"
            ctaType="nba"
        />
    );

    const renderSave = (
        <div
            aria-label={(isSaved ? 'unsave' : 'save') + ' this listing'}
            onClick={handleSave}
            role="button"
            tabIndex="0"
        >
            <SaveGlyph
                className="margin-right-3 text-size-400"
                isSaved={isSaved}
                uiContext="accent"
            />
            <Button
                className="display-bock margin-left-1"
                bsStyle="text"
            >
                {isSaved ? 'This vehicle is already saved' : 'Save this vehicle to your favorites'}
            </Button>
        </div>
    );

    const renderDigitalRetailOverlayCTA = (props) => (
        <DigitalRetailOverlayCTA
            listedPrice={price}
            listing={listing}
            paymentsInfo={{
                ...paymentsInfo,
                creditType,
                downPayment: downPayment || calculateDownPayment(price, { downPayment, downPaymentPercent }),
                dueAtSigning: defaultLeaseDownPayment || calculateDownPayment(price, {
                    downPayment: paymentEmphasis ? downPayment : defaultLeaseDownPayment,
                    downPaymentPercent: paymentEmphasis ? downPaymentPercent : 0.10,
                }),
            }}
            paymentsDuck={paymentsDuck}
            dealsDuck={isDealsDuckEnabled ? dealsDuck : null}
            {...props}
        />
    );

    const renderStandAloneCreditAppCTA = (
        <Button
            bsStyle="text"
            className="text-nowrap margin-top-2"
            data-cmp="saca-credit-app"
            onClick={handleSACACreditAppClick}
        >
            Apply for Financing
        </Button>
    );

    const getVDPUrl = (hash, query = '') => {
        const { href = '', origin = '' } = window?.location || {};
        const vdpUrl = new URL(!isSrp ? href : origin + (listing?.vdpBaseUrl || ''));
        if (query) {
            vdpUrl.searchParams.append('inquiry', query);
        }
        vdpUrl.hash = hash;
        return vdpUrl.href;
    };

    const handleTestDriveCTAClick = (event) => {
        handleConfirmationModalClose();
        sendClick('testDriveScheduleClick', event, { listingId: id, placement: 'nba' });
    };

    const renderTestDriveCTA = () => {
        if (isDRAccelerate) {
            return renderDigitalRetailOverlayCTA({
                uiContext: 'nbaTestDrive',
                buttonText: 'Schedule a Test Drive',
                onClick: handleTestDriveCTAClick,
            });
        }
        return (
            <Link
                href={getVDPUrl('inquirySelection', 'scheduleTestDrive')}
                onClick={handleTestDriveCTAClick}
                label="Schedule a Test Drive"
            />
        );
    };

    const renderWestlakeCTA = (
        <LazyComponent>
            <Westlake
                westlakePreQualUrl={westlakePreQualUrl}
                listing={listing}
                uiContext="link"
                onWestlakeButtonClick={handleConfirmationModalClose}
                placement="nba"
            />
        </LazyComponent>
    );

    const handleValueYourVehicleTradeInCtaClick = (event) => {
        sendClick('valueYourVehicleClick', event, { listingId: id, placement: 'nba' });
        updateMyWallet({
            displayModal: true,
            hideBackButton: true,
            selectedPanelName: 'kbbValuation',
        });
    };

    const renderWalletTradeInCTA = (
        <Button
            bsStyle="text"
            className="text-nowrap margin-top-2"
            data-cmp="value-your-vehicle"
            onClick={handleValueYourVehicleTradeInCtaClick}
        >
            Value Your Vehicle
        </Button>
    );

    // Jump to NDS section in VDP
    // Also unlock if in locked state
    const handleVdpNdsNavigationOnClick = (event) => {
        sendClick('valueYourVehicleClick', event, { listingId: id, placement: 'nba' });
        handleConfirmationModalClose();
        dispatch(selectorDuck.creators.addHideReceiptListing({ listingId: id }));
    };

    const renderVdpNdsNavigationCTA = (
        <Link
            bsStyle="text"
            className="text-nowrap margin-top-2"
            data-cmp="value-your-vehicle"
            href={getVDPUrl('nativeDealContainer')}
            onClick={handleVdpNdsNavigationOnClick}
        >
            Value Your Vehicle
        </Link>
    );

    const renderViewMore = (
        <div data-cmp="backToResultsCTA">
            <BackToResultsLink uiContext="email" />
        </div>
    );

    const options = [
        {
            label: 'View more vehicles like this one',
            render: renderViewMore,
        },
    ];

    if (isShareEnabled) {
        options.unshift({
            label: 'Share this vehicle',
            render: renderShare,
        });
    }

    if (isSaveListingEnabled) {
        options.splice(1, 0, {
            label: 'Save this vehicle to your favorites',
            render: renderSave,
        });
    }

    const timeSavingCTAs = [];

    // start a credit application should show with the following conditions
    // 1. a deal is not created
    // 2. a deal was created BUT the shopper has not submitted a credit app

    if (isDRAccelerate) {
        if (!isExistingDeal || (isExistingDeal && !isCreditAppSubmittedForListing)) {
            timeSavingCTAs.push({
                key: 'amd-credit-app',
                secondaryText: 'Complete online in 30 min.',
                labelText: 'Saves 45 min.',
                render: renderDigitalRetailOverlayCTA({
                    uiContext: 'nextSteps',
                    buttonText: 'Apply for Financing',
                    onClick: handleConfirmationModalClose,
                }),
            });
        }
    }

    if (sacaMmdId) {
        if ((!isExistingDeal) || (isExistingDeal && !isCreditAppSubmittedForOwner)) {
            timeSavingCTAs.push({
                key: 'saca-credit-app',
                secondaryText: 'Complete online in 30 min.',
                labelText: 'Saves 45 min.',
                render: renderStandAloneCreditAppCTA,
            });
        }
    }

    if (!isDRAccelerate && !sacaMmdId && isWestlakeEnabled) {
        timeSavingCTAs.push({
            key: 'westlake-credit-app',
            secondaryText: 'Complete online in 30 min.',
            labelText: 'Saves 45 min.',
            render: renderWestlakeCTA,
        });
    }

    let tradeOptionCtaToRender;
    if (isExistingDeal && !isTradeExistInDeal) {
        if (isDRAccelerate) {
            tradeOptionCtaToRender = renderDigitalRetailOverlayCTA({
                uiContext: 'nbaValueYourVehicle',
                buttonText: 'Value Your Vehicle',
                onClick: handleConfirmationModalClose,
                route: 'tradeInManualEntry',
            });
        } else {
            tradeOptionCtaToRender = renderVdpNdsNavigationCTA;
        }
    }

    if (!isExistingDeal && !isTradeExistInWallet) {
        tradeOptionCtaToRender = renderWalletTradeInCTA;
    }
    // show trade option CTA only when satisfy any condition above
    if (tradeOptionCtaToRender) {
        timeSavingCTAs.push({
            key: 'value-your-vehicle',
            secondaryText: 'Complete online in 10 min.',
            labelText: 'Saves 26 min.',
            render: tradeOptionCtaToRender,
        });
    }

    // show test drive CTA when there's no deal or or there's existing deal but it is not scheduled
    if (!dealDetails?.[dealXgId]?.testDrive?.scheduledDate) {
        timeSavingCTAs.push({
            key: 'test-drive-cta',
            secondaryText: 'Complete online in 5 min.',
            labelText: 'Saves 16 min.',
            render: renderTestDriveCTA(),
        });
    }

    return (
        <EmailFormNextSteps
            options={!isLeadAdditionalInv ? options : undefined}
            timeSavingCTAs={timeSavingCTAs}
        />
    );
}

const mapDispatchToProps = mapDispatchToActionProp({
    saveCurrentVdpListing: saveVDPListing,
    setActiveVdpInteraction: vdpActiveInteractionDuck.creators.setKeys,
    setActiveSrpInteraction: srpActiveInteractionDuck.creators.setKeys,
    updateMyWallet: paymentsDuck.creators.updateMyWallet,
    addHideReceiptListing: paymentsDuck.creators.addHideReceiptListing,
});

export const EmailFormNextStepsContainer = React.memo(
    EmailFormNextStepsComponent,
    (newProps, oldProps) => _isEqual(newProps, oldProps)
);

export default connect(null, mapDispatchToProps)(EmailFormNextStepsContainer);
