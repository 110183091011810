import React, {
    memo,
    useCallback,
    useEffect,
    useState,
} from 'react';

import ReactDOM from 'react-dom';
import {
    useDispatch,
    useSelector,
} from 'react-redux';

// bonnet
import dynamic from 'next/dynamic';

import { ErrorBoundary } from '@bonnet/next/error-boundary';

import { sendClick } from 'reaxl-analytics';
import { useFeatures } from 'reaxl-features';

// analytics
import timingUtil from '@/utilities/timingUtil';

import useDataLayer from '@/analytics/useDataLayer';

// modules
import {
    authDuck,
    dealsDuck,
    paymentsDuck,
    recentSearchDuck,
    savedInventoryDuck,
    userDuck,
    userPreferencesDuck,
} from '@/ducks';

import useVdpNavigation from '@/hooks/useVdpNavigation';

import PersonalizationEngineModule from '@/modules/PersonalizationEngineModule';

// Utilities

import LazyComponent from '@/components/LazyComponent';

const AuthenticationHeader = dynamic(() => import('reaxl-organisms/es/AuthenticationHeader'), { ssr: false });

function HeaderContentContainer({
    listing = {},
    onLoggedInStateReady = () => { },
    saveListing,
    sendAlertClickHandler,
    showHeaderContent = true,
    showEmailSubscribe = false,
    onLogInSuccess = () => { },
    onSignOutClick = () => { },
    onEmailSubscribe = () => { },
    ...rest
}) {
    const [headerContentRef, changeHeaderContentRef] = useState(null);
    const dataLayer = useDataLayer();
    const dispatch = useDispatch();

    const isLoggedIn = useSelector(authDuck.selectors.isLoggedIn);
    const peData = useSelector(PersonalizationEngineModule.duck.selectors.selectPersonalization);
    const {
        authentication_header: [enableAuthenticationHeader],
        my_atc_shareable_link: [enableShareListings],
        my_wallet: [enableMyWallet],
        enable_deals_duck: [isDealsDuckEnabled],
    } = useFeatures(['authentication_header', 'my_atc_shareable_link', 'my_wallet', 'enable_deals_duck']);

    const isClientRendered = typeof window !== 'undefined';

    const navigateToVdp = useVdpNavigation();

    useEffect(() => {
        if (isClientRendered && headerContentRef === null && enableAuthenticationHeader) {
            changeHeaderContentRef(document.getElementsByClassName('header-content'));
            timingUtil.markUserTime('header-rendered');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isClientRendered]);

    const handleQuickSaveItemClick = async (event, quickSaveListing) => {
        dispatch(userDuck.creators.hideQuickSaveList());
        await navigateToVdp({ listingId: quickSaveListing.id });
    };

    const handleQuickSaveItemToggle = useCallback((event, savedListing, wasSaved) => {
        saveListing({
            event,
            listing: savedListing,
            wasSaved,
            sendClick,
        });
    }, [
        saveListing,
        sendClick,
    ]);

    const handleSaveInventoryUpdate = useCallback((data) => {
        sendAlertClickHandler({
            data,
            sendClick,
            peData,
            dataLayer,
            saveType: 'car',
        });
    }, [sendAlertClickHandler, sendClick, peData, dataLayer]);

    const handleSaveSearchUpdate = useCallback((data) => {
        sendAlertClickHandler({
            data,
            sendClick,
            peData,
            dataLayer,
            saveType: 'search',
        });
    }, [sendAlertClickHandler, sendClick, peData, dataLayer]);

    const handleClick = (e, data) => {
        sendClick('headerGeneralEventHandler', e, { ...data });
    };

    const handleShareListingsClick = useCallback(async (/* listings */) => {
        await dispatch(userPreferencesDuck.creators.setKeys({ showShareListingsModal: true }));
    }, [dispatch]);

    const renderPersonalizationHeader = useCallback(() => (
        <LazyComponent fallback={null}>
            <ErrorBoundary>
                <AuthenticationHeader
                    onClick={handleClick}
                    enableMyWallet={enableMyWallet}
                    onLoggedInStateReady={onLoggedInStateReady}
                    onLogInSuccess={onLogInSuccess}
                    onSignOutClick={onSignOutClick}
                    onShareListingsClick={enableShareListings && handleShareListingsClick}
                    onQuickSaveItemClick={handleQuickSaveItemClick}
                    onQuickSaveItemToggle={handleQuickSaveItemToggle}
                    onEmailSubscribe={onEmailSubscribe}
                    onSaveInventoryUpdate={handleSaveInventoryUpdate}
                    onSaveSearchUpdate={handleSaveSearchUpdate}
                    paymentsDuck={paymentsDuck}
                    dealsDuck={isDealsDuckEnabled ? dealsDuck : null}
                    showEmailSubscribe={showEmailSubscribe}
                    userDuck={userDuck}
                    recentSearchDuck={recentSearchDuck}
                    savedInventoryDuck={savedInventoryDuck}
                    isLoggedIn={isLoggedIn}
                    showPsxNotificationsCount
                    {...rest}
                />
            </ErrorBoundary>
        </LazyComponent>
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [
        onLoggedInStateReady,
        onLogInSuccess,
        onSignOutClick,
        handleQuickSaveItemToggle,
        handleSaveInventoryUpdate,
        handleSaveSearchUpdate,
        onEmailSubscribe,
    ]);

    if (typeof window !== 'undefined' && headerContentRef && showHeaderContent && enableAuthenticationHeader) {
        return ReactDOM.createPortal(
            renderPersonalizationHeader(),
            headerContentRef[0]
        );
    }

    return null;

}

export default memo(HeaderContentContainer);
