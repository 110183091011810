import {
    creditAppFollowupClick,
    creditAppGetPreQualifiedClick,
    creditAppSubmit,
    standaloneCreditAppClick,
    vehicleProtectionClick,
} from 'reaxl-analytics';

export default {
    creditAppGetPreQualifiedClick,
    creditAppSubmit,
    standaloneCreditAppClick,
    creditAppFollowupClick,
    vehicleProtectionClick,
};
