import qs from 'querystring';

import { fetchJSON } from '@bonnet/next/fetch';

const fetchListingsAndFilters = (selections) => fetchJSON('/rest/lsc/listing?' + qs.stringify(selections), {
    headers: {
        'Cache-Control': 'no-cache',
        'X-fwd-svc': 'atc',
    },
    credentials: 'same-origin',
    circuitBreaker: {
        id: 'listingsAndFilters',
        timeout: 3000,
        resetTimeout: 30000,
        fallback: {},
    },
});

export default fetchListingsAndFilters;
