import React from 'react';

import PropTypes from 'prop-types';

import { Link } from 'reaxl';

const getConfidenceCopy = ({ returnPolicy, moneyBackGuarantee, safeGuardProgram, limitedWarranty }) => {
    if (returnPolicy?.hasVehicleReturnPolicy
        && (returnPolicy?.hasMoneyBackGuarantee || moneyBackGuarantee)
        && safeGuardProgram) {
        return 'Protection, Return & Exchange Programs';
    }

    if (safeGuardProgram) {
        if (returnPolicy) {
            return `${safeGuardProgram} Day Protection | ${returnPolicy.days} Day ${returnPolicy.hasMoneyBackGuarantee ? 'Returns' : 'Exchanges'}`;
        }
        if (moneyBackGuarantee) {
            return `${safeGuardProgram} Day Protection | 3 Day Returns`;
        }
        if (limitedWarranty) {
            return `${safeGuardProgram} Day Protection | ${limitedWarranty.days} Day Limited Warranty`;
        }
        // Only SafeGuard
        return `Included ${safeGuardProgram} Day Protection`;
    }

    // Only Dynamic return/exchange
    if (returnPolicy) {
        const { days, miles, hasMoneyBackGuarantee, hasVehicleReturnPolicy } = returnPolicy;
        if (hasMoneyBackGuarantee && hasVehicleReturnPolicy) {
            return `${days} Days / ${miles} Miles Returns or Exchanges`;
        }
        if (hasMoneyBackGuarantee) {
            return `${days} Days / ${miles} Miles Return Program`;
        }
        return `${days} Days / ${miles} Miles Exchange Program`;
    }

    // Only MBG
    if (moneyBackGuarantee) {
        return '3 Days / 250 Miles Return Program';
    }

    // Only Limited Warranty
    if (limitedWarranty) {
        const { days, miles } = limitedWarranty;
        return `${days} Days / ${miles} Miles Limited Warranty`;
    }

    return '';
};

/**
 *  Jump Link to Warranty Section
 *
 */
function WarrantyJumpLink({
    limitedWarranty,
    moneyBackGuarantee,
    returnPolicy,
    safeGuardProgram,
    onClick,
}) {
    return (
        <Link
            href="#purchaseConfidence"
            onClick={onClick}
        >
            {getConfidenceCopy({ returnPolicy, moneyBackGuarantee, safeGuardProgram, limitedWarranty })}
        </Link>
    );
}

WarrantyJumpLink.propTypes = {
    /**
     * Legacy MBG product
     */
    moneyBackGuarantee: PropTypes.bool,
    /**
     * Return policy object (days, miles, hasMoneyBackGuarantee, hasVehicleReturnPolicy)
     */
    returnPolicy: PropTypes.object,
    /**
     * Limited warranty merchandising object (days, miles)
     */
    limitedWarranty: PropTypes.object,
    /**
     * SafeGuard Cover My Car program length
     */
    safeGuardProgram: PropTypes.string,
    /**
     * function for onClick
     */
    onClick: PropTypes.func,
};

export default WarrantyJumpLink;
