import fetch from 'isomorphic-fetch';

const fetchBaseOptions = {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
    },
};

export default async (endpoint, data, options = {}) => {

    options.headers = {
        ...fetchBaseOptions.headers,
        ...options.headers,
    };

    const response = await fetch(endpoint, { body: JSON.stringify(data), ...fetchBaseOptions, ...options });
    const json = await response.json();

    return json;
};
