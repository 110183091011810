import { useAnalytics } from 'reaxl-analytics';

import tabTitles from './tabTitles';

const getAnalyticsData = {
    inventoryMediaViewerClick: ({
        index = 0,
        title,
        isPsxVdp,
    } = {}) => {
        let placement = 'thumbnail';
        let pixallTagLabel = 'pht_gallery';
        if (index === 0 && title !== tabTitles.SPIN) {
            placement = 'primary';
        } else if (title === tabTitles.VIDEOS) {
            pixallTagLabel = 'vid_pht_gallery';
            placement = 'video';
        } else if (index === -1) {
            placement = 'view-all-media';
            pixallTagLabel = 'vw_all_media';
        } else if (title === tabTitles.SPIN) {
            placement = '360-spin';
            pixallTagLabel = '360_pht_gallery';
        }

        return { placement, pixallTagLabel, isPsxVdp };
    },

    mediaTabClick: ({
        title = '',
    } = {}) => {
        const type = title.replace(' ', '-').toLowerCase();
        let pixallTagLabel = '';
        if (type === 'photos') {
            pixallTagLabel = 'pht';
        } else if (type === 'video') {
            pixallTagLabel = 'vid';
        } else if (type === '360-view') {
            pixallTagLabel = '360';
        }
        return { type, pixallTagLabel };
    },

    mediaViewerPagination: ({
        currentSlide,
        fullScreenIndex,
    } = {}) => ({ direction: currentSlide - fullScreenIndex > 0 ? 'next' : 'previous' }),
};

export default function useMediaGalleryAnalytics() {
    const { sendClick, sendImpressions } = useAnalytics();

    return (type = 'click', name, data = {}) => {
        const analyticsData = getAnalyticsData[name]?.(data) || data;
        const { event = {} } = data;

        if (type === 'click') {
            sendClick(name, event, analyticsData);
        } else if (type === 'impressions') {
            sendImpressions({ name, data: { ...analyticsData } });
        }
    };
}
