import { fetchJSON } from '@bonnet/next/fetch';

export default (query = {}) => fetchJSON(`/rest/lsc/vehicledetails/${query.id}/specials`, {
    circuitBreaker: {
        id: 'specials',
        timeout: 5000,
        resetTimeout: 30000,
        fallback: { specialOffers: [{ hasError: true }] },
    },
});
