import {
    capOneResourceClick,
    creditReportClick,
    featuredPartnerImpression,
    financeWithUsLink,
    inspectionChecklistClick,
    kbbInstantCashClick,
    preQualCTAClick,
    printTrack,
    rootInsuranceClick,
    rootInsuranceTextLinkClick,
    sellCarClick,
    warrantyResourceClick,
    westlakeCTAImpression,
    windowStickerClick,
} from 'reaxl-analytics';

import { userDuck } from '@/ducks';

import { vdpResultsDuck } from '@/ducks/vdp';

export default {
    printTrack: (metadata, option) => {
        const { data, state } = metadata;
        const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
        return printTrack({
            ...metadata,
            data: {
                ...data,
                inventoryId: activeInventory.id,
            },
            userDuck,
        }, option);
    },
    financeWithUsLink: (metadata, option) => {
        const { data, state } = metadata;
        const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
        return financeWithUsLink({
            ...metadata,
            data: {
                ...data,
                inventoryId: activeInventory.id,
            },
            userDuck,
        }, option);
    },
    kbbInstantCashClick: (metadata, option) => {
        const { data, state } = metadata;
        const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
        return kbbInstantCashClick({
            ...metadata,
            data: {
                ...data,
                inventoryId: activeInventory.id,
                par: 'v_lstg',
            },
        }, option);
    },
    sellCarClick: (metadata, option) => {
        const { data, state } = metadata;
        const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
        return sellCarClick({
            ...metadata,
            data: {
                ...data,
                inventoryId: activeInventory.id,
                par: 'v_lstg',
            },
        }, option);
    },
    capOneResourceClick: (metadata, option) => {
        const { data, state } = metadata;
        const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
        return capOneResourceClick({
            ...metadata,
            data: {
                ...data,
                inventoryId: activeInventory.id,
                par: 'v_lstg',
            },
        }, option);
    },
    creditReportClick: (metadata, option) => {
        const { data, state } = metadata;
        const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
        return creditReportClick({
            ...metadata,
            data: {
                ...data,
                inventoryId: activeInventory.id,
                par: 'v_lstg',
            },
        }, option);
    },
    warrantyResourceClick: (metadata, option) => {
        const { data, state } = metadata;
        const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
        return warrantyResourceClick({
            ...metadata,
            data: {
                ...data,
                inventoryId: activeInventory.id,
                par: 'v_lstg',
            },
        }, option);
    },
    rootInsuranceClick: (metadata, option) => {
        const { data, state } = metadata;
        const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
        return rootInsuranceClick({
            ...metadata,
            data: {
                ...data,
                inventoryId: activeInventory?.id,
                par: 'v_lstg',
            },
        }, option);
    },
    rootInsuranceTextLinkClick: (metadata, option) => {
        const { data, state } = metadata;
        const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
        return rootInsuranceTextLinkClick({
            ...metadata,
            data: {
                ...data,
                inventoryId: activeInventory?.id,
            },
        }, option);
    },
    featuredPartnerImpression: (metadata, option) => {
        const { state, data } = metadata;
        const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
        return featuredPartnerImpression({
            ...metadata,
            data: {
                ...data,
                inventoryId: activeInventory?.id,
            },
        }, option);
    },
    preQualCTAClick: (metadata, option) => {
        const { data, state } = metadata;
        const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
        return preQualCTAClick({
            ...metadata,
            data: {
                ...data,
                inventoryId: activeInventory.id,
                par: 'v_lstg',
            },
        }, option);
    },
    westlakeCTAImpression: (metadata, option) => {
        const { data, state } = metadata;
        const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
        return westlakeCTAImpression({
            ...metadata,
            data: {
                ...data,
                inventoryId: activeInventory.id,
                par: 'v_lstg',
            },
        }, option);
    },
    windowStickerClick: (metadata, option) => {
        const { data, state } = metadata;
        const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
        return windowStickerClick({
            ...metadata,
            data: {
                ...data,
                inventoryId: activeInventory.id,
            },
        }, option);
    },
    inspectionChecklistClick: (metadata, option) => {
        const { data, state } = metadata;
        const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
        return inspectionChecklistClick({
            ...metadata,
            data: {
                ...data,
                inventoryId: activeInventory.id,
            },
        }, option);
    },
};
