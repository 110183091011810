import React, { memo } from 'react';

import _isEqual from 'lodash/isEqual';

function ShowcaseSlot({
    ariaLabel,
    children,
    className,
    onClick = () => {},
}) {
    return (
        <div
            onClick={onClick}
            className={className}
            role="button"
            tabIndex={0}
            aria-label={ariaLabel}
        >
            {children}
        </div>
    );
}

export default memo(ShowcaseSlot, (oldProps, newProps) => _isEqual(newProps, oldProps));
