import { smoothScroll } from 'atc-js';

const scrollToElement = (destination = '', offset = 0, options = { smoothScrollOptions: {}, afterScrollSmoothScrollOptions: {} }) => {
    smoothScroll(destination, {
        offset,
        onAfterScroll: () => {
            // try again, in case the page height changed from lazy load.
            smoothScroll(destination, { offset, duration: 0, ...options.afterScrollSmoothScrollOptions });
        },
        ...options.smoothScrollOptions,
    });
};

export default scrollToElement;
